import SupportModal from "../PopUps/supportPopup/SupportModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import ButtonSubmit from "../buttons/ButtonSubmit";
import { Checkbox } from "@mui/material";
import Turnstile from 'react-turnstile';


export default function Form(props) {
  const [isTypePassword, setIsTypePassword] = useState(true);

  const showPassword = (e) => {
    const password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
      setIsTypePassword(false);
    } else {
      password.type = "password";
      setIsTypePassword(true);
    }
  };

  const resetForm = () => {
    const form = document.querySelector("form");
    form.reset();
  };

  useEffect(() => {
    if (props.resetForm) resetForm();
  }, [props.resetForm]);

  return (
    <form
      className="w-full flex flex-col items-center border-none"
      method="post"
      onSubmit={props.onSubmit}>
      <div className="w-full flex flex-col space-y-4">
        {props.fields.map((field, index) => (
          <div key={index} className="w-full flex flex-col space-y-2">
            {field.isPassword ? (
              <div className="w-full">
                <label
                  htmlFor={field.name}
                  className="font-semibold text-sm text-slate-700">
                  {field.label}
                </label>
                <div className="relative">
                  <input
                    key={index}
                    type={field.type}
                    name={field.name}
                    id="password"
                    placeholder={field.placeholder}
                    className="w-full px-4 py-2 rounded-lg text-black placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200"
                    required
                    autoComplete={field.autocomplete}
                  />
                  <button
                    type="button"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 transition duration-200 ease-in-out text-slate-500 hover:text-blue-600 hover:bg-blue-100 rounded-full p-2"
                    onClick={(e) => showPassword(e)}>
                    {isTypePassword === true ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full">
                <label
                  htmlFor={field.name}
                  className="font-semibold text-sm text-slate-700">
                  {field.label}
                </label>
                {field.type === "Custom" &&
                  field.content(field.defaultValue || "")}
                {field.type !== "Custom" && (
                  <input
                    key={index}
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    defaultValue={field.defaultValue || ""}
                    className="w-full px-4 py-2 rounded-lg text-black placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200"
                    required={field.required}
                    autoComplete={field.autocomplete}
                  />
                )}
              </div>
            )}
          </div>
        ))}
        {props.forget && (
          <p className="cursor-pointer hover:text-blue-600" onClick={props.forget.onclick}>{props.forget.text}</p>
        )}
        {props.textarea && (
          <div className="w-full">
            <label
              htmlFor={props.textarea.name}
              className="font-semibold text-sm text-slate-700">
              {props.textarea.placeholder}
            </label>
            <textarea
              type="text"
              name={props.textarea.name}
              placeholder={props.textarea.placeholder}
              defaultValue={props.textarea.defaultValue || ""}
              className="mb-8 w-full min-h-[150px] max-h-[150px] px-4 py-3 rounded-lg text-black font-medium placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200 transition duration-200 ease-in-out"
              autoComplete="new-password"
            />
          </div>
        )}
      </div>

      {props.checkbox && (
        <div className="w-full flex items-center justify-between mt-10">
          <div className="flex items-center self-start">
            <input type="checkbox" className="size-4 mr-2" name={props.checkbox.name}
              id={props.checkbox.id}
              defaultChecked={props.checkbox.checked}
            />
            <span className="font-medium text-lg text-slate-700">
              {props.checkbox.text}
            </span>
          </div>
          {props.type === "login" && (
            <div className="font-medium text-lg text-blue-600 self-end">
              <span onClick={<SupportModal />} className="hover:underline">
                Forgot password?
              </span>
            </div>
          )}
        </div>
      )}
      {props.captcha && ( props.captcha.element)}
      <div className="mt-8 ">
        <ButtonSubmit loading={props.loading} value={props.submitButtonValue} />
      </div>

      {props.type === "login" && (
        <div className="mt-4 flex flex-col items-center">
          <span className="font-medium text-lg text-slate-700">
            Don&apos;t have an account?
          </span>
          <span className="font-medium text-lg text-blue-600">
            <span
              onClick={props.signUp}
              className="hover:underline cursor-pointer">
              Sign up
            </span>
          </span>
        </div>
      )}
    </form>
  );
}
