import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageTable from "../../components/tables/PageTable";
import globalRequest from "../../functions/backendRequests";
import { useUser } from "../../users/UserContext";
import { calcPageSizeInitialState, formatSortedObjectForQuery } from "../../functions/helper";

export default function AdminActions() {
  const { setUser } = useUser();
  const location = useLocation();

  const [actions, setActions] = useState(null);

  const [filters, setFilters] = useState(location.state || []);

  const [sorted, setSorted] = useState({
    enabled: false,
    ascending: true,
    field: "",
  });
  const [fetchState, setFetchState] = useState("Loading...");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  const [pageSize, setPageSize] = useState(calcPageSizeInitialState);

  useEffect(() => {
    updateItems();
  }, [page, pageSize, filters, searchValue, sorted]);

  const updateItems = () => {
    fetchData(
      page,
      pageSize,
      setTotalPages,
      setFetchState,
      searchValue,
      sorted.enabled && formatSortedObjectForQuery(sorted)
    );
  };


  const pageHeader = {
    title: "Actions",
    options: [],
    selectedPage: "See all",
  };

  const formatFiltersForQuery = (filters) => {
    return filters.map((filter) => `${filter.key}=${filter.itemId}`).join("%");
  };

  const fetchData = (
    page,
    pageSize,
    setTotalPages,
    setFetchState,
    searchValue,
    sorted
  ) => {
    globalRequest(
      "actions/adminTableActions",
      `?page=${page}&pageSize=${pageSize}&filters=${formatFiltersForQuery(
        filters
      )}&search=${searchValue || ""}&sort=${sorted || ""}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        res.data.forEach((action) => {
          action.createdAt = new Date(action.createdAt).toLocaleString(
            "en-US",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }
          );
        });

        setActions(res.data);
        setTotalPages(res.totalPages);
        if (res.status === 404) setFetchState("No data found");
        else setFetchState(res.state);
      })
      .catch((err) => console.warn(err));
  };

  const handlePageChange = (e, pageNumber) => {
    if (page === pageNumber) return;
    setPage(pageNumber);
  };

  const tablePagination = {
    page: page,
    pageSize: pageSize,
    totalPages: totalPages,
    data: actions,
    fetchState: fetchState,
    setPage: setPage,
    setPageSize: setPageSize,
    setTotalPages: setTotalPages,
    handlePageChange: handlePageChange,
  };


  const table = {
    cols: [
      { name: "Action Id", key: "id" },
      { name: "Action", key: "action" },
      { name: "User Email", key: "userEmail" },
      { name: "Description", key: "description" },
      { name: "Date", key: "createdAt" },
    ],
    rows: actions && actions,
    fetchData: fetchData,
    filters: filters,
    pagination: tablePagination,
  };

  const addOrRemoveFilter = (key, value, itemId) => {
    if (
      filters.some(
        (filter) =>
          filter.key === key &&
          filter.value === value &&
          filter.itemId === itemId
      )
    ) {
      setFilters(
        filters.filter(
          (filter) =>
            !(
              filter.key === key &&
              filter.value === value &&
              filter.itemId === itemId
            )
        )
      );
    } else {
      setFilters([...filters, { key: key, value: value, itemId: itemId }]);
    }
  };

  return (
    <div className="h-fit w-full flex flex-col py-6 px-8 items-center bg-[#f8f8ff] font-semibold gap-6 relative">
      <PageTable
        title={"Actions"}
        table={table}
        adminPage={true}
        pageHeader={pageHeader}
        fields={actions}
        addOrRemoveFilter={addOrRemoveFilter}
        filters={filters}
        setFilters={setFilters}
        sorted={sorted}
        setSorted={setSorted}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </div>
  );
}
