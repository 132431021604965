import { Alert } from '@mui/material';
import { useEffect } from 'react';


const Alerts = ({ message = "",
  severity = "info",
  timeout = 0,
  onClose = null,
  position = "top-right" }) => {

  useEffect(() => {
    if (timeout > 0 && onClose) {
      const timer = setTimeout(() => {
        onClose();
      }, timeout * 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      {position === "top-right" ?
        <Alert severity={severity} onClose={onClose} sx={{ mb: 2, zIndex: 9999 }} >{message}</Alert>
        :
        <div className='p-4 z-[9999] max-w-sm min-w-fit w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Alert severity={severity} onClose={onClose} sx={{ mb: 2, zIndex: 9999 }}>{message}</Alert>
        </div>
      }
    </>
  )
}

const AlertsWrapper = ({ children }) => {
  return (
    <div className={`${children && children.length > 0 ? "" : "hidden"} fixed top-10 right-0 p-4 z-[9999] max-w-sm min-w-fit w-full`}>
      {children}
    </div>
  );
};

export { Alerts, AlertsWrapper };