import CloseIcon from '@mui/icons-material/Close';

export default function CardPageHeader({children, closeButton, closeButtonOnClick, ...props}) {
  return (
    <div className={`w-full flex ${props.alignHorizontal ? "flex-row justify-center" : "flex-col"} items-center gap-4 py-6 relative`}>
      {closeButton && 
        <div className='absolute left-0 top-0 cursor-pointer p-2 rounded-full hover:bg-slate-200 transition ease-in-out duration-200' onClick={() =>  closeButtonOnClick ? closeButtonOnClick() : {}}>
          <CloseIcon className=''/>
        </div>
      }
      {children}
    </div>
  );
}
