import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";

export default function Sidebar(props) {

  let location = useLocation();

  const pages = [
    {
      label: "Dashboard", to: "/admin/dashboard", activeclassname: "dashboard", icon: <HomeOutlinedIcon />
    },
    {
      label: "Companies", to: "/admin/companies", activeclassname: "companies", icon: <ApartmentOutlinedIcon />
    },
    {
      label: "Products", to: "/admin/products", activeclassname: "products", icon: <FolderOpenOutlinedIcon />
    },
    {
      label: "Files", to: "/admin/files", activeclassname: "files", icon: <InsertDriveFileOutlinedIcon />
    },
    {
      label: "Users", to: "/admin/users", activeclassname: "users", icon: <GroupOutlinedIcon />
    },
    {
      label: "Actions", to: "/admin/actions", activeclassname: "actions", icon: <AdminPanelSettingsOutlinedIcon />
    },
  ];

  const [currentPage, setCurrentPage] = useState(
    location.pathname.split("/")[1]
  );

  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    if (location.pathname.split("/")[2]) {
      setCurrentPage(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  return (
    <>
      <nav
        id="verticalNavbar"
        className={`transition-[max-width] duration-250 ease-in-out ${visibility ? "max-w-48" : "max-w-20"
          } flex-1 bg-[#f1f3fa] justify-start z-20 block sticky pb-20`}>
        <button
          type="button"
          className="w-6 h-12 absolute left-full transform -translate-x-1/2 bg-slate-200 hover:bg-slate-300 text-blue-900 rounded-r-lg text-sm inline-flex items-center"
          onClick={() => setVisibility(!visibility)}
          style={{ top: "24px", transform: "translateY(-50%)" }}>
          {visibility ? (
            <KeyboardArrowLeftIcon
              className="cursor-pointer hover:scale-110 transition duration-200 ease-in-out"
              fontSize="medium"
            />
          ) : (
            <KeyboardArrowRightIcon
              className="cursor-pointer hover:scale-110 transition duration-200 ease-in-out"
              fontSize="medium"
            />
          )}
        </button>
        <ul className="h-full w-full flex flex-col font-semibold text-lg">
          {pages.map((page, index) => (
            <li key={index}>
              <Link
                exact="true"
                to={page.to}
                className={`w-full h-[70px] flex items-center px-4 ${!visibility && "justify-center"
                  } hover:text-blue-600 font-semibold gap-x-2`}
              >
                <div
                  className={`flex items-center ${currentPage === "Admin" + page.label ||
                    currentPage === page.activeclassname
                    ? "text-blue-600"
                    : "text-slate-700"
                    } ${!visibility && "justify-center"
                    } hover:text-blue-600 font-semibold gap-x-2`}
                  sx={{
                    color: currentPage === page.activeclassname ? "#4b6ac5" : "#334155",
                  }}>
                  {visibility ? (
                    <>
                      {page.icon}
                      <span className="font-semibold text-lg">
                        {page.label}
                      </span>
                    </>
                  ) : (
                    <Tooltip title={page.label} arrow placement="right">
                      {page.icon}
                    </Tooltip>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}