import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BarChartIcon from "@mui/icons-material/BarChart";

export default function SelectedItems({
  selectedItems,
  clearSelectedItems,
  fileActions,
  download,
  setLoading,
  user,
}) {
  return (
    <div
      className={`${selectedItems.length > 0
        ? "opacity-100"
        : "opacity-0 pointer-events-none"
        } min-w-fit transition-[opacity] ease-in-out duration-100 block h-auto w-full py-4 px-28 absolute z-30 left-1/2 transform -translate-x-1/2`}>
      <div className="h-16 w-full flex items-center justify-between gap-x-5 px-14 rounded-full bg-mischka-100">
        <div className="flex items-center gap-x-1">
          <div
            className="flex items-center gap-x-3 hover:bg-mischka-300 hover:text-mischka-800 hover:shadow-md p-2 rounded-full cursor-pointer transition ease-in-out duration-150"
            onClick={() => clearSelectedItems()}>
            <CloseIcon />
          </div>
          <span className="border-r-2 border-slate-600 pr-4 text-nowrap md:hidden block">
            {selectedItems.length} files
          </span>
          <span className="border-r-2 border-slate-600 pr-4 text-nowrap md:block hidden">
            {selectedItems.length} files selected
          </span>

          {user.admin && (
            <div
              className="flex items-center pl-3 gap-x-3 hover:bg-mischka-300 hover:text-mischka-800 hover:shadow-md p-2 rounded-full cursor-pointer transition ease-in-out duration-150 text-base font-semibold"
              onClick={() => fileActions(selectedItems)}>
              <BarChartIcon />
              <span className="md:block hidden">Actions</span>
            </div>
          )}
        </div>

        <div id="moreOptions" className="w-auto flex items-center gap-x-10">
          <div
            className="flex items-center gap-x-3 hover:bg-mischka-300 hover:text-mischka-800 hover:shadow-md p-2 rounded-full cursor-pointer transition ease-in-out duration-150"
            onClick={() => download(selectedItems, setLoading)}>
            <FileDownloadIcon />
          </div>
        </div>
      </div>
    </div>
  );
}