import { useUser } from "../users/UserContext";
import Layout from "./Layout";
import { Navigate } from "react-router-dom";
import Main from "../pages/Main";
import Login from "../pages/Login";
import Products from "../pages/User/Products";
import Files from "../pages/User/Files";
import AdminFiles from "../pages/Admin/AdminFiles";
import AdminProducts from "../pages/Admin/AdminProducts";
import AdminCompanies from "../pages/Admin/AdminCompanies";
import AdminDashboard from "../pages/Admin/AdminDashboard";
import AdminUsers from "../pages/Admin/AdminUsers";
import AdminActions from "../pages/Admin/AdminActions";
import FileDownload from "../pages/User/FileDownload";
import SelfRegister from "../pages/SelfRegister";
export default function ProtectedLayout({ children }) {
  const { user } = useUser();

  const pagesForAdmin = [AdminFiles, AdminProducts, AdminCompanies, AdminDashboard, AdminUsers, AdminActions];
  const pagesForUsers = [Products, Files, FileDownload];
  const pagesForAnyone = [Main, Login, SelfRegister];
  
  if (localStorage.getItem('lastVisitedPage') !== null && localStorage.getItem('lastVisitedPage') !== sessionStorage.getItem('lastVisitedPage') && window.location.pathname === '/') {
    window.location.replace(localStorage.getItem('lastVisitedPage'));
  }

  if (user && user.admin) {
    if (pagesForAdmin.includes(children.type)) {
      localStorage.setItem('lastVisitedPage', window.location.href);
      sessionStorage.setItem('lastVisitedPage', window.location.href);
      return (
        <Layout isAdminPage={true} key={children.key}>
          {children}
        </Layout>
      );
    }
    else {
      localStorage.setItem('lastVisitedPage', window.location.href);
      sessionStorage.setItem('lastVisitedPage', window.location.href);
      return (
        <Layout isAdminPage={false} key={children.key}>
          {children}
        </Layout>
      );
    }
  }

  if (user && user.name) {
    if (pagesForUsers.includes(children.type) || pagesForAnyone.includes(children.type)) {
      localStorage.setItem('lastVisitedPage', window.location.href);
      sessionStorage.setItem('lastVisitedPage', window.location.href);
      return <Layout key={children}>{children}</Layout>;
    } else {
      return <Navigate to="/login" />;
    }
  }

  if (!user) {
    if (pagesForAnyone.includes(children.type)) {
      localStorage.setItem('lastVisitedPage', window.location.href);
      sessionStorage.setItem('lastVisitedPage', window.location.href);
      return <Layout key={children}>{children}</Layout>;
    } else {
      return <Navigate to="/login" />;
    }
  }
}

