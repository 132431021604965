import React, { useEffect, useState } from "react";
import { compareVersions } from "compare-versions";
import globalRequest from "../../functions/backendRequests";
import { useAlerts } from "../../alerts/AlertContext";
import { useUser } from "../../users/UserContext";
import CompanyDropdown from "../../components/dropdown/CompanyDropdown";
import PageHeader from "../../components/header/PageHeader";
import CompanyRow from "../../components/tables/companyTable/CompanyRow";
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import FormDialog from "../../components/dialogs/FormDialog";
import { Checkbox } from "@mui/material";
import TableFooterPagination from "../../components/tables/TableFooterPagination";
import { calcPageSizeInitialState } from "../../functions/helper";
import CardPageHeader from "../../components/card/cardPage/CardPageHeader";
import CardPageBody from "../../components/card/cardPage/CardPageBody";
import CardLabelItem from "../../components/card/cardPage/CardLabelItem";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import ModalPage from "../../components/modal/ModalPage";

export default function AdminCompanies() {
  const { addAlert } = useAlerts();
  const { setUser } = useUser();

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState();
  const [permissions, setPermissions] = useState();

  const [publicKey, setPublicKey] = useState("");
  const [pgpEnabled, setPgpEnabled] = useState(false);
  const [domains, setDomains] = useState([]);
  const [productPermissions, setProductPermissions] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [PopUp, setPopUp] = useState(false);
  const [parameters, setParameters] = useState(null);
  const [company, setCompany] = useState(null);
  const [companyList, setCompanyList] = useState(null);
  const [product, setProduct] = useState(null);
  const [productList, setProductList] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [platformList, setPlatformList] = useState(null);
  const [version, setVersion] = useState(null);
  const [versionList, setVersionList] = useState(null);

  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(calcPageSizeInitialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState([]);

  const [subPage, setSubPage] = useState("See all");

  const setCompanyToFilter = (item) => {
    if (item) addOrRemoveFilter("company", item);
    else removeFilter("company", company);
    setCompany(item);
  };

  const setProductToFilter = (item) => {
    if (item) addOrRemoveFilter("product", item);
    else removeFilter("product", product);
    setProduct(item);
  };
  const setPlatformToFilter = (item) => {
    if (item) addOrRemoveFilter("platform", item);
    else removeFilter("platform", platform);
    setPlatform(item);
  };
  const setVersionToFilter = (item) => {
    if (item) addOrRemoveFilter("version", item);
    else removeFilter("version", version);
    setVersion(item);
  };

  const addOrRemoveFilter = (key, value) => {
    if (
      filters.some((filter) => filter.key === key && filter.value === value)
    ) {
      setFilters(
        filters.filter(
          (filter) => !(filter.key === key && filter.value === value)
        )
      );
    } else {
      setFilters([...filters, { key: key, value: value }]);
    }
  };

  const removeFilter = (key, value) => {
    setFilters(
      filters.filter(
        (filter) => !(filter.key === key && filter.value === value)
      )
    );
  };

  useEffect(() => {
    updateCompanies();
  }, [currentPage, pageSize, filters]);

  useEffect(() => {
    if (subPage === "See all") resetSelectedStates();
    if (subPage === "Create")
      updateProducts();
  }, [subPage]);

  useEffect(() => {
    if (companies) {
      setCompanyList(companies.map((company) => company.name));
      setProductList([
        ...new Set(
          companies
            .flatMap((company) =>
              company.products.map((product) => product.name)
            )
            .filter((product) => product !== null)
            .sort()
        ),
      ]);
      setPlatformList([
        ...new Set(
          companies
            .flatMap((company) =>
              company.products
                .flatMap((product) => product.platform)
                .map((platform) => platform.name)
            )
            .filter((platform) => platform !== undefined)
        ),
      ]);
      const versions = [
        ...new Set(
          companies
            .flatMap((company) =>
              company.products
                .flatMap((product) => product.platform)
                .flatMap((platform) => platform.versions)
                .map((version) => version)
            )
            .filter((version) => version !== undefined)
        ),
      ];
      setVersionList(versions.sort((a, b) => compareVersions(a, b)));
    }
  }, [companies]);

  const updateProducts = async () => {
    let res = await globalRequest(
      "companies/getProducts",
      "",
      "GET",
      { "Content-Type": "application/json" },
      null,
      "res",
      setUser
    )
    if (res.status !== 200) {
      res = await res.json();
      addAlert({ message: res.message, severity: "warning", timeout: 5 });
      return;
    }
    res = await res.json();
    setProductPermissions(res);
  };

  const fetchEdit = (item) => {
    const parms = { companyName: item };
    globalRequest(
      "companies/getPermissions",
      "",
      "POST",
      { "Content-Type": "application/json" },
      JSON.stringify(parms),
      "",
      setUser
    ).then((data) => {
      setDomains(JSON.parse(data.company.domains) || null);
      setPublicKey(data.company.publicKey);
      setPgpEnabled(data.company.pgpEnabled);
      setProductPermissions(data.productPermissions);
    });
  };

  useEffect(() => {
    if (subPage === "Edit" && company) {
      fetchEdit(company);
    }
  }, [company]);

  const openPopUp = () => {
    if (selectedCompanies.length === 0) {
      addAlert({
        message: "Please select a company!",
        severity: "warning",
        timeout: 5,
      });
      return;
    }
    const companyFormatted = selectedCompanies.map((Company) => ({
      name: Company,
    }));
    setParameters({
      dialogTitle: dialogParameters.dialogTitle,
      dialogContentText: dialogParameters.dialogContentText,
      textField: dialogParameters.textField,
      checkBox: dialogParameters.checkBox,
      action: dialogParameters.name,
      onSubmit: dialogParameters.func,
      resetItems: resetSelectedStates,
      items: companyFormatted,
    });
    setPopUp(true);
  };

  function removeDomain(domain) {
    setDomains(domains.filter((d) => d !== domain));
  }

  const resetSelectedStates = () => {
    setCompany(null);
    setProduct(null);
    setPlatform(null);
    setVersion(null);
    setPgpEnabled(false);
    setPublicKey("");

    setSelectedCompanies([]);
  };

  const updateCompanies = () => {
    globalRequest(
      "companies/checkFiles",
      `?page=${currentPage}&pageSize=${pageSize}&filters=${formatFiltersForQuery(
        filters
      )}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        if (res.status === 400) {
          addAlert({ message: res.message, severity: "warning", timeout: 5 });
          return;
        }
        setCompanies(res.data);
        setTotalPages(res.totalPages);
        setPermissions(res.permissions);
      })
      .catch((err) => console.warn(err));
  };

  const formatFiltersForQuery = (filters) => {
    return filters.map((filter) => `${filter.key}=${filter.value}`).join("_");
  };

  const handlePageChange = (e, pageNumber) => {
    if (currentPage === pageNumber) return;
    setCurrentPage(pageNumber);
  };

  const applyPermissions = async (
    companyName,
    productName,
    platform,
    version,
    toInput
  ) => {
    const parms = {
      companyName,
      productName,
      platform,
      version,
      toInput,
    };

    try {
      const res = await globalRequest(
        "companies/setFilePermission",
        "",
        "POST",
        { "Content-Type": "application/json" },
        JSON.stringify(parms),
        "",
        setUser
      );

      if (res.status === 200) {
        addAlert({ message: "Permissions updated successfully", severity: "success", timeout: 5 });
        updateCompanies();
      } else {
        addAlert({ message: res.message || "Failed to update permissions", severity: "warning", timeout: 5 });
      }
    } catch (error) {
      addAlert({ message: "An unexpected error occurred", severity: "error", timeout: 5 });
    }
  };

  const removeCompany = async (e, companies, resetItems, handleClose) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const deleteConfirmation = formJson.deleteConfirmation;
    const deleteOnCascade = formJson.deleteOnCascade;

    if (deleteConfirmation !== "delete") {
      addAlert({
        message: 'Please type "delete" to confirm action!',
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    try {
      const res = await globalRequest(
        "companies/removeCompany",
        "",
        "DELETE",
        { "Content-Type": "application/json" },
        JSON.stringify({
          companies: selectedCompanies,
          deleteOnCascade: deleteOnCascade === "on",
        }),
        "res",
        setUser
      );

      let resJson = {};
      if (res) {
        try {
          resJson = await res.json();
        } catch (jsonError) {
          console.error("Failed to parse response JSON:", jsonError);
          resJson.message = "Failed to parse response. Please try again later.";
        }
      }

      if (res?.status === 200) {
        addAlert({ message: resJson.message || "Company removed successfully!", severity: "success", timeout: 5 });
        updateCompanies();
        resetItems();
        handleClose();
      } else {
        addAlert({ message: resJson.message || "Failed to remove company", severity: "error", timeout: 5 });
      }
    } catch (error) {
      console.error("Error removing company:", error);
      addAlert({ message: "A network error occurred", severity: "error", timeout: 5 });
    }
  };

  const dialogParameters = {
    name: "Delete Company",
    func: removeCompany,
    dialogTitle: "Delete Company",
    dialogContentText: "Are you sure you want to delete this company?",
    textField: {
      name: "deleteConfirmation",
      label: 'Confirm delete by typing "delete"',
    },
    checkBox: {
      name: "deleteOnCascade",
      label: "Delete all data associated with company",
    },
  };

  const selectPageOption = (option) => {
    if (option === "Create") {
      setSubPage("Create");
    } else if (option === "See all") setSubPage("See all");

    resetSelectedStates();
  };

  const pageHeader = {
    title: "Companies",
    options: ["See all", "Create"],
    selectPageOption: selectPageOption,
    subPage: subPage,
  };

  const selectItems = (item) => {
    setSelectedCompanies((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((e) => e !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  const EnableChange = async (type) => {
    if (selectedCompanies.length === 0) {
      addAlert({
        message: "Please select a company!",
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    try {
      const res = await globalRequest(
        "companies/enableChanges",
        "",
        "POST",
        { "Content-Type": "application/json" },
        JSON.stringify({ selectedCompanies: selectedCompanies, type: type }),
        "res",
        setUser
      );

      const resJson = res && await res.json();
      if (res.status === 200) {
        setSelectedCompanies([]);
        updateCompanies();
        addAlert({ message: resJson.message, severity: "success", timeout: 5 });
      } else {
        addAlert({ message: resJson.message || "Something went wrong!", severity: "error", timeout: 5 });
      }
    } catch (error) {
      console.error("EnableChange Error:", error);
      addAlert({ message: "Error enabling/disabling company!", severity: "error", timeout: 5 });
    }
  };

  const changeToEdit = async (item) => {
    fetchEdit(item);
    setSubPage("Edit");
    setCompany(item);
  };

  const applyChanges = async () => {
    if ((!publicKey || publicKey === "") && pgpEnabled) {
      addAlert({
        message: "Please enter a public key!",
        severity: "warning",
        timeout: 5,
      });
      return;
    }
    


    if (subPage === "Create") {
      const companyName = document.getElementById("companyName").value;
      if (companyName === "") {
        addAlert({
          message: "Please enter a company name!",
          severity: "warning",
          timeout: 5,
        });
        return;
      } else if (!domains || domains.length === 0) {
        addAlert({
          message: "Please enter at least one domain!",
          severity: "warning",
          timeout: 5,
        })
        return;
      } else {
        setLoading(true);
        const parms = {
          type: "create",
          domains: domains,
          companyName: companyName,
          publicKey: publicKey,
          pgpEnabled: pgpEnabled,
          toInput:
            Object.keys(productPermissions).length > 0
              ? Object.entries(productPermissions)
                .map(([key, value]) => (value ? key : null))
                .filter((item) => item !== null)
              : [],
        };
        setProductPermission(parms);
      }
    }
    if (subPage === "Edit") {
      if (!company) {
        addAlert({
          message: "Please select a company!",
          severity: "warning",
          timeout: 5,
        });
        return;
      }
      setLoading(true);
      const companyName = document.getElementById("companyName").value;
      const parms = {
        type: "edit",
        domains: domains,
        companyName: company,
        newName: companyName,
        publicKey: publicKey,
        pgpEnabled: pgpEnabled,
        toInput:
          Object.keys(productPermissions).length > 0
            ? Object.entries(productPermissions)
              .map(([key, value]) => (value ? key : null))
              .filter((item) => item !== null)
            : [],
        toRemove:
          Object.keys(productPermissions).length > 0
            ? Object.entries(productPermissions)
              .map(([key, value]) => (!value ? key : null))
              .filter((item) => item !== null)
            : [],
      };
      setProductPermission(parms);
    }

  };

  const setProductPermission = async (parms) => {
    try {
      const res = await globalRequest(
        "companies/setProductPermission",
        "",
        "POST",
        { "Content-Type": "application/json" },
        JSON.stringify(parms),
        "res",
        setUser
      );

      setLoading(false);

      if (!res) {
        addAlert({ message: "No response from server", severity: "error", timeout: 5 });
        return;
      }
      const resJson = res && await res.json();
      if (res.status === 200) {
        addAlert({ message: resJson.message, severity: "success", timeout: 5 });

        if (subPage === "Create") {
          setPgpEnabled(false);
          setProductPermissions({});
          setDomains([]);
          setCompany(null);
          setPublicKey("");
          updateCompanies();
          if (subPage === "Create") document.getElementById("companyName").value = "";
          setSubPage("See all");
        }
      } else {
        addAlert({ message: resJson.message, severity: "warning", timeout: 5 });
        return;
      }
    } catch (error) {
      console.error("Error setting product permissions:", error);
      addAlert({ message: "An unexpected error occurred", severity: "error", timeout: 5 });
    }
  };

  const dropdownMenus = [
    {
      spanTitle: "Select a company:",
      title: "Company",
      items: companyList,
      setSelected: setCompany,
      selected: company,
      background: true,
    },
  ];
  //className="w-full px-4 py-2 rounded-lg text-black placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200"
  const customElement = [
    {
      title: "Company Name:",
      element: (
        <input
          type="text"
          id="companyName"
          placeholder="Company Name"
          className="h-[45px] max-w-[250px] w-full min-w-[150px] rounded-lg text-black text-center placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-gray-200"></input>
      ),
    },
    {
      title: "Insert Domains:",
      element: (
        <CompanyDropdown
          items={domains}
          addNew={setDomains}
          remove={removeDomain}
          background={true}
        />
      ),
    },
    {
      element: (
        <div className="w-full flex flex-col gap-y-2">
          <div className="flex items-center justify-between mb-2 mr-2">
            <h1 className="font-medium text-slate-600 text-lg">Public Key</h1>
            <div className="flex items-center">
              <p className="font-medium text-slate-600 text-lg mr-2">
                PGP Enabled:
              </p>
              <Checkbox
                type="checkbox"
                checked={pgpEnabled}
                onChange={(e) => setPgpEnabled(e.target.checked)}
              />
            </div>
          </div>
          <textarea
            type="text"
            value={publicKey}
            placeholder="Public Key
            (only base64 content)"
            className="w-[100%] h-24 min-h-24 px-4 rounded-lg py-4 break-all text-md text-black placeholder placeholder-center placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-gray-200 placeholder:text-center"
            onChange={(e) => setPublicKey(e.target.value.trim())}
            required={pgpEnabled || false}
          />
        </div>
      ),
    },
    {
      element: (
        <>
          {productPermissions ?
            <div className="w-full h-auto max-h-[260px] overflow-y-auto border-2 mt-2 border-slate-300 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-[4rem] rounded-xl">
              {Object.entries(productPermissions).map(([key, value]) => (
                <label
                  key={key}
                  className="w-full h-16 px-2 flex items-center justify-start border border-gray-200">
                  <Checkbox
                    className=" mr-2"
                    checked={value || false}
                    onChange={(e) =>
                      setProductPermissions({
                        ...productPermissions,
                        [key]: e.target.checked,
                      })
                    }
                    type="checkbox"
                  />
                  <span className="text-xl truncate" title={key}>
                    {key}
                  </span>
                </label>
              ))}
            </div>
            :
            <div className="w-full h-16 px-2 flex items-center justify-center border border-gray-200">
              <span className="text-xl">No Products avaliable</span>
            </div>
          }
        </>
      ),
    },
    {
      element: (
        <div className="w-full flex justify-center">
          <ButtonSubmit
            loading={loading}
            value={"Save Changes"}
            onClick={() => applyChanges()}
          />
        </div>
      ),
    },
  ];

  const companyPageHeader = {
    selectedCompanies: selectedCompanies,
    EnableChange: EnableChange,
    companyList: companyList,
    setCompany: setCompanyToFilter,
    company: company,
    productList: productList,
    product: product,
    setProduct: setProductToFilter,
    platformList: platformList,
    setPlatform: setPlatformToFilter,
    platform: platform,
    versionList: versionList,
    setVersion: setVersionToFilter,
    version: version,
    openPopUp: openPopUp,
  };

  const closeButtonOnClick = () => {
    setCompany(null);
    setSubPage("See all");
  };

  const modal = {
    title: "Create Company",
    open: subPage === "Create" || subPage === "Edit",
    onClose: closeButtonOnClick,
  };

  return (
    <>
      <div className="w-full flex flex-col py-6 px-8 items-center bg-[#f8f8ff] font-semibold gap-6 relative">
        <PageHeader>
          <div className='flex flex-wrap justify-center border-none gap-3'>
            <button className="min-w-[130px] truncate h-12 border-black border-2 text-black text-lg px-4 flex items-center justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-[50ms] hover:bg-blue-800 hover:border-blue-800 hover:text-slate-50 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => companyPageHeader.EnableChange('enable')}>
              Enable
            </button>
            <button className="min-w-[130px] truncate h-12 border-black border-2 text-black text-lg px-4 flex items-center justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-[50ms] hover:bg-blue-800 hover:border-blue-800 hover:text-slate-50 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => companyPageHeader.EnableChange('disable')}>
              Disable
            </button>
            <button className="min-w-[130px] truncate h-12 border-black border-2 text-black text-lg px-4 flex items-center justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-[50ms] hover:bg-blue-800 hover:border-blue-800 hover:text-slate-50 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => companyPageHeader.selectedCompanies.length > 0 ? companyPageHeader.openPopUp() : addAlert({ message: 'Please select at least one item.', severity: 'warning', timeout: 5 })}>
              Delete
            </button>
          </div>
          <div className='flex flex-wrap justify-center border-none gap-x-6 gap-y-2'>
            <div>
              <DropdownMenu items={companyPageHeader.companyList} setSelected={companyPageHeader.setCompany} selected={companyPageHeader.company} title={"Company"} background={false} />
            </div>
            <div>
              <DropdownMenu items={companyPageHeader.productList} setSelected={companyPageHeader.setProduct} selected={companyPageHeader.product} title={"Product"} background={false} />
            </div>
            <div>
              <DropdownMenu items={companyPageHeader.platformList} setSelected={companyPageHeader.setPlatform} selected={companyPageHeader.platform} title={"Platform"} background={false} />
            </div>
            <div>
              <DropdownMenu items={companyPageHeader.versionList} setSelected={companyPageHeader.setVersion} selected={companyPageHeader.version} title={"Version"} background={false} />
            </div>
          </div>
          <button className="min-w-[130px] truncate h-12 border-blue-800 border-2 text-blue-800 text-lg px-4 flex items-center justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-[50ms] hover:bg-blue-800 hover:border-blue-800 hover:text-slate-50 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setSubPage("Create")}>
            {modal.title}
          </button>
        </PageHeader>
        {PopUp && (
          <FormDialog open={PopUp} setOpen={setPopUp} params={parameters} />
        )}
        <div className="h-auto w-full overflow-auto shadow-md rounded-lg ring-1 ring-slate-300">
          <div className="table w-full text-base text-left rtl:text-right text-slate-600 border-spacing-0">
            {companies &&
              companies.map((company, index) => (
                <div
                  key={index}
                  className="table-row odd:bg-white-lilac-50 odd:hover:bg-[#e4e4f5] even:bg-[#eaeaf7] even:hover:bg-[#dadaf4]">
                  <CompanyRow
                    item={company}
                    permissions={permissions}
                    openAll={false}
                    enabled={company.enabled}
                    editCompany={changeToEdit}
                    selectItems={selectItems}
                    selectedItems={selectedCompanies}
                    applyPermissions={applyPermissions}
                    updatePermissions={updateCompanies}
                  />
                </div>
              ))}
          </div>

          <TableFooterPagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>


        <ModalPage open={modal.open} onClose={modal.onClose}>
          <CardPageHeader closeButton={true} closeButtonOnClick={modal.onClose}>
            <h1 className="font-semibold text-slate-700 text-2xl">
              {subPage === "Create" ? "Register Company" : "Edit Company"}
            </h1>
            {subPage === "Edit" && company && (
              <h1 className="font-semibold text-slate-700 text-2xl">
                "{company}"
              </h1>
            )}
          </CardPageHeader>

          <CardPageBody>
            {subPage === "Edit" &&
              dropdownMenus.map((item, index) => (
                <CardLabelItem key={index} text="Select a Company:">
                  <DropdownMenu
                    selected={item.selected}
                    title={item.title}
                    items={item.items}
                    setSelected={item.setSelected}
                    background={item.background}
                    checkbox={item.checkbox}
                    selectAllItems={item.selectAllItems}
                    selectedItems={item.selectedItems}
                    size={"w-[150px]"}
                  />
                </CardLabelItem>
              ))}

            {customElement.map((item, index) => (
              <CardLabelItem key={index} text={item.title}>
                {item.element}
              </CardLabelItem>
            ))}
          </CardPageBody>
        </ModalPage>

      </div>



    </>
  );

}
