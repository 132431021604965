import SearchIcon from '@mui/icons-material/Search';


export default function Search({ searchValue, setSearchValue }) {
  return (
    <div className="relative">
      <input
        id="search"
        type="text"
        name="search"
        placeholder="Search"
        value={searchValue || ""}
        onChange={(e) => setSearchValue(e.target.value)}
        className="w-64 flex px-4 py-2 rounded-lg bg-white-lilac-50 font-medium text-lg placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200 transition ease-in-out duration-200"
        required />
      <div className={`absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none ${searchValue ? "hidden" : "block"}`}>
        <SearchIcon className="w-6 h-6 text-slate-400" />
      </div>
    </div>
  )
}