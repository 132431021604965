import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../assets/logo_pi.png'
import { useUser } from '../../../users/UserContext';
import { Box, Modal } from '@mui/material';
import globalRequest from '../../../functions/backendRequests';
import { useEffect, useState } from 'react';
import ButtonSubmit from '../../buttons/ButtonSubmit';
import { useAlerts } from '../../../alerts/AlertContext';
import Input from '../../forms/Input';

export default function UserPopup(props) {
  const { addAlert, clearAlerts } = useAlerts();
  const { user, setUser } = useUser(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pwdStrengh, setPwdStrengh] = useState({ chars: false, number: false, special: false, caps: false });

  function ClosePopup() {
    props.setPopUp(false)
  }

  async function submitForm(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    let data = {};
    formData.forEach((value, key) => data[key] = value);

    if (!Object.values(pwdStrengh).every(Boolean)) {
      addAlert({ message: 'Weak password', severity: 'warning', timeout: 5 });
      return
    }

    if (data['currentPassword'] === data['newPassword']) {
      addAlert({ message: 'New password cannot be the same as the old password!', severity: 'warning', timeout: 5 });
      return
    }

    if (data['newPassword'] !== data['confirmPassword']) {
      addAlert({ message: 'Passwords do not match!', severity: 'warning', timeout: 5 });
      return
    }
    setLoading(true);

    const res = await globalRequest("users/changepassword", "", "PATCH", {
      "Content-Type": "application/json",
    }, JSON.stringify(data), "res", setUser)

    setLoading(false);

    const resJson = res && await res.json();

    if (res.status === 200)
      addAlert({ message: resJson.message, severity: 'success', timeout: 5 });
    else {
      addAlert({ message: resJson.message, severity: 'error', timeout: 5 });
    }
    
    e.target.reset();
    setPwdStrengh({ chars: false, number: false, special: false, caps: false });
    ClosePopup();


  }

  useEffect(() => {
    if (user && userData === null) {
      globalRequest("users/userQuery", "", "GET", {}, null, "", setUser).then((res) => {
        setUserData(res.users);
      }).catch(err => console.warn(err));
    }
  }, [user, userData]);

  function checkPassword(password) {
    const chars = new RegExp('(?=.*[a-z])');
    const caps = new RegExp('(?=.*[A-Z])');
    const number = new RegExp('(?=.*[0-9])');
    const special = new RegExp('(?=.*[^a-zA-Z0-9])');
    setPwdStrengh({
      chars: password.length >= 8,
      caps: caps.test(password),
      number: number.test(password),
      special: special.test(password),
    });
  }

  const inputStyle = "w-full px-4 py-2 rounded-lg text-black placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200"

  const inputs = [
    {
      label: "Current Password",
      type: "password",
      name: "currentPassword",
      placeholder: "Current Password",
      required: true,
      autoComplete: "new-password",
    },
    {
      label: "New Password",
      type: "password",
      name: "newPassword",
      placeholder: "New Password",
      required: true,
      onChange: (e) => checkPassword(e.target.value),
      autoComplete: "new-password",
    },
    {
      label: "Confirm new Password",
      type: "password",
      name: "confirmPassword",
      placeholder: "Confirm Password",
      required: true,
      autoComplete: "new-password",
    }
  ]



  return (
    <Modal
      open={props.open || false}
      onClose={ClosePopup}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >

      <Box className='absolute h-[80%] max-h-[660px] w-[50%] max-w-[950px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white-lilac-50 rounded-lg outline-none'>
        <div className="flex flex-col w-full h-full bg-white-lilac-50 z-40 divide-y divide-slate-200 overflow-hidden rounded-lg">
          <div className="relative w-full min-h-[55px] h-[55px] max-h-[55px] border-2 border-gray-300 flex justify-center items-center z-10">
            <span>User Information</span>
            <button
              className="absolute right-0 z-0 h-full w-16 hover:bg-red-500 hover:text-slate-100 transition duration-200 ease-in-out rounded-tr-md"
              onClick={ClosePopup}>
              <CloseIcon />
            </button>
          </div>

          {user && user.name && userData &&
            <div className='h-16 w-full flex flex-col'>
              <div className='w-full h-full p-3 px-10 flex items-center justify-center xl:justify-between space-x-2'>
                <div className='hidden xl:block max-w-48 truncate'>
                  <span>Name: {userData.name} </span>
                </div>
                <div className='max-w-64 truncate'>
                  <span>Email: {userData.email}</span>
                </div>
                <div className='hidden xl:block max-w-64 truncate'>
                  <span>Company: {userData["company.name"]}</span>
                </div>
              </div>
            </div>
          }

          <div className='overflow-auto [@media(min-height:720px)]:h-full [@media(min-height:720px)]:flex [@media(min-height:720px)]:items-center'>
            <form className="w-full h-fit flex py-2 px-14 justify-center items-center flex-col border-none gap-6" method="post" autoComplete="off" onSubmit={submitForm}>
              <h1 className="text-3xl font-semibold text-blue-500">Change Password</h1>
              {
                inputs.map((input, index) => (
                  <div className='w-full flex flex-col gap-2' key={index}>
                    <Input
                      key={index}
                      label={input.label}
                      type={input.type}
                      name={input.name}
                      placeholder={input.placeholder}
                      required={input.required}
                      onChange={input.onChange}
                      inputStyle={inputStyle}
                    />

                    {
                      input.name === "newPassword" && (

                        <div className='w-full flex flex-wrap justify-around items-center gap-2'>
                          <div className='flex items-center gap-2'><div className={`rounded-full h-2 w-2 ${pwdStrengh.chars ? 'bg-lime-500' : 'bg-red-500'}`} /><p>8 characters</p></div>
                          <div className='flex items-center gap-2'><div className={`rounded-full h-2 w-2 ${pwdStrengh.number ? 'bg-lime-500' : 'bg-red-500'}`} /><p>1 number</p></div>
                          <div className='flex items-center gap-2'><div className={`rounded-full h-2 w-2 ${pwdStrengh.special ? 'bg-lime-500' : 'bg-red-500'}`} /><p>1 special</p></div>
                          <div className='flex items-center gap-2'><div className={`rounded-full h-2 w-2 ${pwdStrengh.caps ? 'bg-lime-500' : 'bg-red-500'}`} /><p>1 uppercase</p></div>
                        </div>
                      )}
                  </div>
                ))
              }

              <div className="w-full pb-4 flex justify-center items-center">
                <ButtonSubmit loading={loading} value="Change password" />
              </div>

            </form>
          </div>
        </div>
      </Box>
    </Modal>
  )
}