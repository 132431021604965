

export default function CardImageText(props) {

  return (
    <div className='relative min-h-[400px] xl:h-[400px] w-full xl:w-2/3 flex flex-col cl:flex-row  items-center justify-center rounded-3xl  gap-x-10 px-14 py-10 border-2 border-zinc-300 shadow-zinc-400 shadow-sm hover:shadow-md hover:shadow-zinc-400 hover:border-zinc-200 transition ease-in-out duration-200 object-contain bg-gradient-to-br from-transparent via-zinc-800 to-transparent filter group cursor-pointer' onClick={props.onClickCard || (() => { })}>
      <div className="h-full w-full absolute bg-slate-700 rounded-3xl opacity-50 z-0"></div>
      <div className='h-full w-full cl:w-2/3 flex flex-col items-center cl:items-start justify-center gap-y-5 z-10 order-2 cl:order-1'>
        <h1 className='text-xl md:text-4xl text-white font-semibold'>{props.title || "Title"}</h1>
        <span className='max-h-[200px] text-zinc-300 text-start overflow-y-auto'>{props.text || "Text"}</span>
        <button className='rounded-full bg-blue-600 text-white px-6 py-3 flex justify-center items-center group-hover:bg-white group-hover:text-blue-600 transition ease-in-out duration-200 truncate' onClick={props.onClickButton || (() => { })}>{props.buttonText || "Button Text"}</button>
      </div>
      <div className='h-full w-1/3 min-w-36 flex items-center justify-center z-10 order-1 cl:order-2'>
        <img src={props.image || "https://via.placeholder.com/150"} className='h-auto max-h-full w-fit object-contain' alt="imgCard" />
      </div>
    </div>
  )
}