import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function MoreOptionsDropdown(props) {
  return (
    <Menu as="div" className="relative h-full w-auto">

      <MenuButton className={`inline-flex items-center justify-center w-auto rounded-full p-1 font-semibold group ${props.menuButtonDarkMode ? 'text-blue-50 hover:bg-blue-100 hover:text-slate-700' : 'text-slate-700 hover:text-blue-50  hover:bg-slate-700'}  `}>
        <MoreVertIcon className='cursor-pointer transition ease-in-out duration-500' />
      </MenuButton>

      <MenuItems
        transition
        className="absolute flex justify-center w-[200px] max-h-60 shadow-lg ring-1 ring-black ring-opacity-10 rounded-md bg-white-lilac-50 right-7 mt-[-30px] z-[9999] transition ease-in-out duration-150"

      >
        <div className='w-full max-w-[300px] overflow-y-auto rounded-md'>
          {props.moreOptionsDropdown && props.moreOptionsDropdown.items && props.moreOptionsDropdown.items.length > 0 ?

            props.item && props.item.id ?

              props.moreOptionsDropdown.items.map((item, index) => (
                <MenuItem key={index}>
                  {item &&
                    <button
                      onClick={() => item.function(props.item)}
                      className={`w-full flex items-center justify-start px-4 py-2 text-sm text-slate-700 overflow-hidden truncate hover:bg-white-lilac-100`}
                    >
                      <div className='w-[30px] flex justify-center mr-4'>{item.icon}</div>
                      {item.name === "Download" && props.item.wasFileDownloadedByTheUser
                        ? <span className='w-full flex justify-start truncate'>Download Again</span>
                        : <span className='w-full flex justify-start truncate'>{item.name}</span>
                      }
                    </button>
                  }
                </MenuItem>
              ))

              :

              props.moreOptionsDropdown.items.map((item, index) => (
                item && item.multiFile &&
                <MenuItem key={index}>
                  <button
                    onClick={() => item.function(props.item)}
                    className={`w-full flex items-center justify-start px-4 py-2 text-sm text-slate-700 overflow-hidden truncate hover:bg-white-lilac-100`}
                  >
                    <div className='w-[30px] flex justify-center mr-4'>{item.icon}</div>
                    {item.name === "Download" && props.item.wasFileDownloadedByTheUser
                      ? <span className='w-full flex justify-start truncate'>Download Again</span>
                      : <span className='w-full flex justify-start truncate'>{item.name}</span>
                    }
                  </button>
                </MenuItem>
              ))

            :

            <MenuItem>
              <button
                className="w-full text-center justify-center flex px-4 py-2 text-sm text-slate-700 data-[focus]:bg-blue-100 data-[focus]:text-slate-900 overflow-hidden"
              >
                No items
              </button>
            </MenuItem>
          }

          {props.item && props.item.platform === "PDF" && props.moreOptionsDropdown.pdfOnlyItems.map((item, index) => (
            <MenuItem key={index}>
              <button
                onClick={() => item.function(props.item)}
                className={`w-full flex items-center justify-start px-4 py-2 text-sm text-slate-700 overflow-hidden truncate hover:bg-white-lilac-100`}
              >
                <div className='w-[30px] flex justify-center mr-4'>{item.icon}</div>
                <span className='w-full flex justify-start truncate'>{item.name}</span>
              </button>

            </MenuItem>
          ))}

          {props.isAdmin &&

            <div className='border-t-2 border-slate-200'>
              <span className='w-full text-center justify-center flex py-2 text-xs font-semibold text-slate-700'>Admin</span>
              {props.moreOptionsDropdown.adminItems.map((item, index) => (
                <MenuItem key={index}>
                  {item &&
                    <button
                      onClick={() => item.function(props.item)}
                      className={`w-full flex items-center justify-start px-4 py-2 text-sm text-slate-700 overflow-hidden truncate hover:bg-white-lilac-100`}
                    >
                      <div className='w-[30px] flex justify-center mr-4'>{item.icon}</div>
                      <span className='w-full flex justify-start truncate'>{item.name}</span>
                    </button>

                  }
                </MenuItem>
              ))
              }
            </div>
          }

        </div>
      </MenuItems>
    </Menu>
  );
}