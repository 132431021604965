import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox } from "@mui/material";

export default function TableHead(props) {
  const cols = props.cols;
  const sort = props.sort;

  return (
    <tr className="w-full">
      <th scope="col" className="px-1 py-3 border-r-2 border-slate-300">
        <div className="flex items-center justify-center">
          <Checkbox
            className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150"
            checked={props.selectAllChecked}
            onChange={(e) => props.selectAllItems(e.target.checked)}
          />
        </div>
      </th>
      {cols && cols.map((col, index) => (
        <th
          key={index}
          onClick={() => sort(col.key)}
          scope="col"
          className="px-6 py-3 border-r-2 border-slate-300 cursor-pointer"
        >
          <div className="flex items-center space-x-1 hover:text-blue-600">
            <span className="text-nowrap">{col.name}</span>
            <div className="w-auto flex flex-col">
              <div className="h-2">
                <ArrowDropUpIcon
                  className={`${props.sorted.enabled &&
                    props.sorted.ascending &&
                    props.sorted.field === col.key &&
                    "text-blue-600"
                    }`}
                />
              </div>
              <ArrowDropDownIcon
                className={`${props.sorted.enabled &&
                  !props.sorted.ascending &&
                  props.sorted.field === col.key &&
                  "text-blue-600"
                  }`}
              />
            </div>
          </div>
        </th>
      ))}
    </tr>

  );

}