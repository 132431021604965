import { createContext, useContext, useRef, useState } from "react";
import { Alerts, AlertsWrapper } from "./Alerts";
import { v4 } from "uuid";

const AlertsContext = createContext();

const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (alert) => {
    const id = v4();
    setAlerts((prevAlerts) => [...prevAlerts, { ...alert, id: id }]);
    return id;
  };

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  return (
    <AlertsContext.Provider value={{ alerts, addAlert, removeAlert }}>
      <AlertsWrapper>
        {alerts.map((alert) => (
          <Alerts key={alert.id} {...alert} onClose={() => { removeAlert(alert.id); }} />
        ))}
      </AlertsWrapper>
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlerts = () => {
  const [alertIds, setAlertIds] = useState([]);
  const alertIdsRef = useRef(alertIds);
  const { addAlert, removeAlert } = useContext(AlertsContext);

  const addAlertWithId = (alert) => {
    const id = addAlert(alert);
    alertIdsRef.current.push(id);
    setAlertIds(alertIdsRef.current);
  }

  const clearAlerts = () => {
    alertIdsRef.current.forEach((id) => removeAlert(id));
    alertIdsRef.current = [];
    setAlertIds([]);
  }
  return { addAlert: addAlertWithId, clearAlerts };
}

export { AlertsProvider };