import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function TableFiltersDropdownMenu(props) {
  return (
    <Menu as="div" className={`relative text-left inline-block items-center h-full ${props.size ? props.size + " text-center  min-w-[100px]" : "w-auto"}`} >


      <MenuButton className={`inline-flex items-center justify-center h-full w-full min-w-32 gap-x-1.5 rounded-lg bg-white-lilac-50 px-4 py-3 text-sm font-semibold text-slate-600 shadow-sm ring-2 ring-inset ring-slate-200 hover:ring-slate-400 group data-[active]:ring-slate-400 transition ease-in-out duration-150`}>
        <span className='bg-[#e2e2e9] text-slate-600 px-2 py-1 rounded-md'>{props.qtdFilters || 0}</span>
        <span className='w-full overflow-hidden text-nowrap'>{props.title}</span>
        <ArrowDropDownIcon aria-hidden="true" className="h-5 w-5 text-slate-400 self-end group-data-[active]:rotate-180 group-data-[active]:text-blue-700 transition ease-in-out duration-500" />
      </MenuButton>


      <MenuItems transition className={`absolute flex justify-center mt-2 ${props.size ? 'w-[100%] min-w-[200px]' : "w-[140px]"} z-10 max-h-40 text-sm truncate ring-2 ring-black ring-opacity-10 rounded-md transition focus:outline-none`} >
        <div className='w-full max-w-[300px] overflow-y-auto rounded-md bg-white-lilac-50 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'>
          {props.items && props.items.length > 0 ?

            props.items.map((filter) => (
              <MenuItem key={filter.key + filter.value} className="w-full h-10 flex items-center justify-between text-sm text-slate-700 bg-white-lilac-50 border-b border-gray-200">
                <div className="w-full flex items-center">
                  <div className="w-full h-full flex items-center justify-start text-[16px] text-slate-600">
                    <div className="h-full w-full px-3 flex items-center truncate border-r border-gray-200">
                      <span onClick={(e) => e.stopPropagation()} className='truncate' title={filter.key + ": " + filter.value}>{filter.key}: {filter.value}</span>
                    </div>
                    <button className='cursor-pointer w-[50px] h-full hover:bg-white-lilac-100'>
                      <CancelOutlinedIcon onClick={(e) => { props.removeFilter(filter.key, filter.value, filter.itemId); e.stopPropagation() }} className="h-5 w-5 transition ease-in-out  text-red-500 cursor-pointer hover:text-red-700" title="Remove filter" />
                    </button>
                  </div>
                </div>
              </MenuItem>
            )
            )

            :

            <MenuItem>
              <button className="w-full text-center justify-center flex px-4 py-2 text-sm text-slate-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-slate-900 overflow-hidden">
                No active filters
              </button>
            </MenuItem>
          }

        </div>
      </MenuItems>
    </Menu>
  );


}