import TableRows from "./TableRows";
import { useEffect, useState } from "react";
import TableHead from "./TableHead";
import TableFooterPagination from "./TableFooterPagination";
import { calcPageSizeInitialState } from "../../functions/helper";

export default function Table(props) {

  const fetchData = props.fetchData;

  const cols = props.cols;
  const rows = props.rows;
  const filter = props.filter;

  const { page, pageSize, setPageSize, totalPages, fetchState, handlePageChange } = props.pagination;

  if (fetchState === "Loading...") return (
    <div className="w-full h-full flex justify-center items-center">
      <span>{fetchState}</span>
    </div>
  )

  if (fetchState === "Success") return (
    <>
      <div className="h-auto overflow-auto rounded-t-lg">
        <table className=" w-full text-base text-left rtl:text-right text-slate-600">
          <thead className="w-full text-xs text-slate-700 uppercase bg-[#eaeaf7]">
            <TableHead
              cols={cols}
              sort={props.sortItem}
              sorted={props.sorted}
              selectAllItems={props.selectAllItems}
            />
          </thead>
          <tbody>
            {rows && rows.length > 0 ? (
              pageSize && new Array(pageSize).fill(0).map((item, index) => {
                return (
                  <TableRows
                    key={index}
                    item={rows[index]}
                    cols={cols}
                    filter={filter}
                    selectItem={props.selectItem}
                    selectedItems={props.selectedItems}
                    selectAllChecked={props.selectAllChecked}
                    functions={props.functions}
                  />
                )


              })
            ) : (
              <tr key={0} className="w-full flex justify-center px-6 py-4">
                <td
                  colSpan={cols.length + 1}
                  className="w-full flex justify-center px-6 py-4 text-nowrap"
                >
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <TableFooterPagination
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

    </>
  );
}