import { useNavigate } from "react-router-dom";
import { useUser } from "../users/UserContext";
import CardPage from "../components/card/cardPage/CardPage";
import globalRequest from "../functions/backendRequests";
import { useState } from "react";
import { useAlerts } from "../alerts/AlertContext";
import CardPageHeader from "../components/card/cardPage/CardPageHeader";
import CardPageBody from "../components/card/cardPage/CardPageBody";
import Form from "../components/forms/Form";
import logo from "../assets/logo_pi.png";

export default function SelfRegister() {
  const { addAlert, clearAlerts } = useAlerts();

  const navigate = useNavigate();
  const { setUser } = useUser();
  const [loading, setLoading] = useState(false);

  function submitForm(e) {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData(e.target);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));

    globalRequest(
      "auth/selfRegister",
      "",
      "POST",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify(data),
      ""
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 401) {
          res
            .json()
            .then((res) =>
              addAlert({ message: res.message, severity: "error", timeout: 5 })
            );
          return;
        }

        addAlert({ message: res.message, severity: "success", timeout: 5 });

        navigate("/login");
      })
      .catch((err) => console.warn(err));
  }

  const fields = [
    {
      type: "name",
      label: "Name",
      name: "name",
      placeholder: "Name",
      autocomplete: "on",
    },
    {
      type: "email",
      label: "Email",
      name: "email",
      placeholder: "Email",
      autocomplete: "on",
    },
  ];

  const form = {
    fields: fields,
    submit: submitForm,
    submitButtonValue: "Sign in",
    loading: loading,
    type: "register",
  };

  return (
    <div className="min-h-fit h-full w-full flex items-center justify-center bg-clean bg-no-repeat bg-center bg-cover overflow-y-auto py-5">
      <CardPage>
        <CardPageHeader>
          <img
            src={logo}
            className="h-[90px]"
            alt="Planeta Informática Logo"
          />
          <h1 className="font-semibold text-slate-700 text-2xl">
            Create your Planeta account with limited access
          </h1>
        </CardPageHeader>

        <CardPageBody>
          <Form
            fields={form.fields}
            textarea={form.textarea}
            checkbox={form.checkbox}
            onSubmit={form.submit}
            submitButtonValue={form.submitButtonValue}
            loading={form.loading}
            type={form.type}
            signUp={form.signUp}
          />
        </CardPageBody>
      </CardPage>
    </div>
  );
}
