export default function CardPage({ children, ...props }) {
  return (
    <div className={`w-full min-h-fit flex font-noto items-center justify-center`}>
      <div
        className={`flex flex-col w-full py-8 p-8 items-center justify-center flex-grow`}>
        <div className="w-full h-full flex items-start justify-center">
          <div className="h-auto min-h-[500px] w-full max-w-[700px] bg-white-lilac-50 rounded-3xl py-8 flex flex-col items-center ring-black ring-opacity-10 ring-2 divide-y divide-slate-200 px-14 hover:shadow-xl transition ease-in-out duration-150">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
