import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function UploadFile(props) {
  return (
    <div className='w-full flex flex-col justify-center border-none'>
      <div className='w-full mt-14 flex items-center justify-between border-none'>
        <span className='font-medium text-slate-600 text-lg mr-5'>{props.title}</span>

        <button onClick={props.selectFileFromComputer} className='bg-black text-white px-4 py-2 flex justify-center rounded hover:bg-blue-800 transition ease-in-out duration-200'>

          <FileUploadIcon className='text-slate-50' />
        </button>

      </div>

      <div className='mt-5 flex flex-row border-none w-full'>
        {
          props.selectedFile &&
          <div className='flex items-center justify-between border-none w-full'>
            <span className='font-medium text-slate-600 text-lg mr-2'>Selected: </span>
            <div className='flex items-center'>
              <span className='font-medium text-slate-600 text-lg mr-2 max-w-64 truncate' title={props.selectedFile.name || props.selectedFile.get('name')}>{props.selectedFile.name || props.selectedFile.get('name')}</span>
              <CancelOutlinedIcon onClick={props.dismissFile} className='text-red-500 cursor-pointer hover:text-red-700 transition ease-in-out' title="Dismiss file" />
            </div>
          </div>
        }
      </div>
    </div>
  );
}