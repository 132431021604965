import { useEffect, useState } from "react";
import MoreOptionsDropdown from "../dropdown/MoreOptionsDropdown";
import ButtonProductColor from "../buttons/ButtonProductColor";
import { Checkbox, Tooltip } from "@mui/material";
import { darkenColor } from "../../functions/helper";
import ArticleIcon from "@mui/icons-material/Article";
import FloatingProgressBar from "../progressBar/FloatingProgressBar";
import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function CardFile({ ...props }) {
  const [imgSrc, setImgSrc] = useState("");
  const item = props && props.item;
  const givenDate = new Date(item.updatedAt);
  const currentDate = new Date();

  const extension = item.path.split(".").pop();
  const timeDifference = currentDate - givenDate;
  const timeDifferenceInDays = Math.floor(
    timeDifference / (1000 * 60 * 60 * 24)
  );

  const productColorBGHover =
    props.productColor &&
    item.latestVersion &&
    darkenColor(props.productColor, 25);

  useEffect(() => {
    if (item.cardImage && item.cardImage.data) {
      try {
        const blob = new Blob([new Uint8Array(item.cardImage.data)], {
          type: "image/png",
        });
        const url = URL.createObjectURL(blob);
        setImgSrc(url);
      } catch (error) { }
    }
  }, [item.cardImage]);

  const svgH = 130;
  const svgW = 300;


  return (
    <div className=" flex justify-center font-noto">
      <div
        className={`h-[340px] w-[300px] rounded-3xl flex flex-col items-center ring-black ring-opacity-15 ring-1 divide-y divide-slate-200 hover:shadow-xl hover:ring-opacity-30 transition ease-in-out duration-150 group relative overflow-hidden ${props.selectedFiles &&
          props.selectedFiles.length > 0 &&
          props.selectedFiles.includes(item.id)
          ? " border-4 border-mischka-400"
          : ""
          } `}
      >
        <svg
          className="absolute z-0"
          width={svgW}
          height={svgH + 50}
          viewBox={`0 0 ${svgW} ${svgH + 50}`}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M 0 0 L 0 ${svgH} A 150 50 0 0 0 ${svgW} ${svgH} L ${svgW} 0 Z`}
            fill={props.productColor ? props.productColor : "#656970"}
          />
        </svg>

        <div className="h-full w-full flex flex-col items-start justify-between border-none">
          <div className="w-full font-semibold text-lg  border-none text-white relative z-20 p-4">
            <div
              className={`w-full flex items-center justify-between text-2xl ${timeDifferenceInDays < 1 && !item.wasFileDownloadedByTheUser
                ? "pt-0 pb-3"
                : "pt-0 pb-3"
                }`}>
              <div
                id="fileName"
                className="flex items-center justify-start truncate space-x-2 ">
                <Checkbox
                  style={{ color: "white" }}
                  className="hover:"
                  checked={
                    props.selectedFiles &&
                    props.selectedFiles.length > 0 &&
                    props.selectedFiles.includes(item.id)
                  }
                  onChange={() => props.selectFile(item)}
                />
                <h1
                  className="max-w-[350px] truncate"
                  title={item.products.name}>
                  {item.products.name}
                </h1>
              </div>
              {props.fileOptions && (
                <div
                  id="moreOptions"
                  className="w-auto flex items-center "
                  onClick={(e) => e.stopPropagation()}>
                  <MoreOptionsDropdown
                    item={props.item}
                    moreOptionsDropdown={props.moreOptionsDropdown}
                    isAdmin={props.isAdmin}
                    menuButtonDarkMode={true}
                  />
                </div>
              )}
            </div>
            <div className="w-full pb-6 flex flex-col items-start px-4 relative">
              <div className="w-full flex items-center gap-2">
                <h1 className="truncate">{`Version: ${item.version} `}</h1>
                <span>{props.icons[item.platform]}</span>
              </div>
              <span className="text-sm">Released: {item.createdAt}</span>
              <span className="text-sm">Size: {item.sizeKB}</span>

              {timeDifferenceInDays < 1 && !item.wasFileDownloadedByTheUser && (
                <span className="absolute flex h-fit w-fit mt-7 ml-44 items-center gap-x-2">
                  <div className="flex h-3 w-3 relative">
                    <span className="absolute animate-ping h-full w-full rounded-full bg-white "></span>
                    <span className="rounded-full h-full w-full bg-white"></span>
                  </div>
                  <div className="flex h-fit bg-slate-300 bg-opacity-55 rounded-lg">
                    <span className="px-2">new</span>
                  </div>
                </span>
              )}
            </div>
          </div>

          <div className="w-full h-full flex flex-col items-center pt-6 pb-4 gap-y-4   relative">
            <span
              className="font-semibold text-slate-700 cursor-pointer hover:underline flex items-center gap-x-2"
              onClick={(e) => {
                e.stopPropagation();
                props.showDetails(item);
              }}>
              Details <ArticleIcon />
            </span>
            {props.buttonFunction && (
              <div className="space-x-2 px-2 flex justify-center items-center">
                {props.downloadPercentage && props.filesBeingDownloaded.length > 0 && props.filesBeingDownloaded.includes(item.id) && props.selectedFiles.length > 0 && !props.selectedFiles.includes(item.id) ? (
                  <>
                    <FloatingProgressBar
                      title={"Downloading"}
                      percentage={props.downloadPercentage}
                    />
                    <Tooltip
                      title="Cancel download"
                      placement="top"
                      arrow="true">
                      <CancelIcon
                        className="cursor-pointer hover:text-red-800 transition ease-in-out duration-150"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.cancelDownload();
                        }}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <div className="flex flex-col items-center gap-2" onClick={(e) => e.stopPropagation()}>
                    <ButtonProductColor
                      item={props.item}
                      buttonText={
                        props.item.wasFileDownloadedByTheUser
                          ? "Download Again"
                          : "Download"
                      }
                      bgColorHover={productColorBGHover}
                      buttonFunction={props.buttonFunction}
                    />
                  </div>
                )}
              </div>
            )}
            {extension === "pdf" && (
              <div className="absolute w-full flex justify-center bottom-3">
                <button className="text-sm text-blue-700 hover:text-slate-800 hover:underline flex items-center gap-x-2" onMouseDown={(e) => { e.stopPropagation(); props.openPdf(item) }}>
                  <span>Open PDF</span>
                  <OpenInNewIcon sx={{ fontSize: 20 }} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}