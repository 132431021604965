export default function CardLabelItem({ children, ...props }) {
  return (
    <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center justify-between border-none w-full">
      {props.text && (
        <span className="font-medium text-slate-600 text-lg">{props.text}</span>
      )}
      {children}
    </div>
  );
}
