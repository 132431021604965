import { useUser } from "../../users/UserContext";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import globalRequest from "../../functions/backendRequests";
import { Card, Tooltip } from "@mui/material";
import CardWelcomeUser from "../../components/card/CardWelcomeUser";
import CardTitleMultipleButtons from "../../components/card/CardTitleMultipleButtons";


export default function AdminDashboard() {
  const { user, setUser } = useUser();
  const [latestActions, setLatestActions] = useState(null);
  const navigate = useNavigate();

  const fetchLatestActions = () => {
    globalRequest("actions/getLatestActions", "", "GET", {}, "", "").then((res) => {
      if (res.status === 401) {
        navigate("/login");
        return;
      }
      setLatestActions(res);
    }).catch(err => console.warn(err));
  }

  useEffect(() => {
    if (latestActions && latestActions.length >= 0) return;
    fetchLatestActions();
  }, [latestActions]);

  const pages = [
    {
      name: "Companies",
      icon: <ApartmentOutlinedIcon className="w-20 h-20 rounded-full" sx={{ fontSize: 30 }} />,
      onClick: () => navigate("/admin/companies")
    },
    {
      name: "Products",
      icon: <FolderOpenOutlinedIcon className="w-20 h-20 rounded-full" sx={{ fontSize: 30 }} />,
      onClick: () => navigate("/admin/products")
    },
    {
      name: "Files",
      icon: <InsertDriveFileOutlinedIcon className="w-20 h-20 rounded-full" sx={{ fontSize: 30 }} />,
      onClick: () => navigate("/admin/files")
    },
    {
      name: "Users",
      icon: <GroupOutlinedIcon className="w-20 h-20 rounded-full" sx={{ fontSize: 30 }} />,
      onClick: () => navigate("/admin/users")
    },
  ]

  return (
    <div className="h-full w-full flex font-semibold bg-teal-900">
      <div className='min-h-full h-fit w-full flex  items-center justify-center bg-clean bg-no-repeat bg-center bg-cover py-10'>

        <div className="h-full w-full flex flex-wrap  justify-center font-semibold text-wrap  px-14 py-10 gap-10">

          <CardWelcomeUser user={user}>
            {latestActions && latestActions.length > 0 &&
              <div className="z-10 mt-5 ">
                <h1 className="text-lg text-white font-medium z-10">Latest actions as admin</h1>

                <div className="h-full w-full flex flex-col gap-y-2 mt-2 max-h-[250px] overflow-y-auto">
                  {latestActions.map((item, index) => (
                    <div key={index} className="h-full w-full flex items-center gap-x-2 bg-zinc-800 rounded-3xl p-5">
                      <div className="w-2 h-2 rounded-full bg-zinc-200"></div>
                      <h1 className="text-md text-zinc-200">{item.action}</h1>
                    </div>
                  ))}
                </div>
              </div>
            }
          </CardWelcomeUser>

          <CardTitleMultipleButtons title="Admin Dashboard" buttons={pages} />
        </div>

      </div>
    </div>
  );
}