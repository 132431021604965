import { useNavigate } from "react-router-dom";
import { useUser } from "../users/UserContext";
import CardPage from "../components/card/cardPage/CardPage";
import globalRequest from "../functions/backendRequests";
import { useState, useEffect } from "react";
import { useAlerts } from "../alerts/AlertContext";
import CardPageHeader from "../components/card/cardPage/CardPageHeader";
import CardPageBody from "../components/card/cardPage/CardPageBody";
import Form from "../components/forms/Form";
import logo from "../assets/logo_pi.png";
import Turnstile from "react-turnstile";
import RecoveryPopup from "../components/PopUps/ForgotPwdPopup/RecoveryPopup";


export default function Login() {
  const { addAlert, clearAlerts } = useAlerts();

  const navigate = useNavigate();
  const { setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [turnstileInstance, setTurnstileInstance] = useState(null);
  const [popUp, setPopUp] = useState(false);

  const handleTurnstileChange = (token) => {
    setToken(token);
  };

  const openPopUp = () => {
    setPopUp(true);
  };

  function submitForm(e) {
    e.preventDefault();

    setLoading(true);
    if(!token) {
      setLoading(false);
      addAlert({ message: "Please complete the CAPTCHA", severity: "warning", timeout: 5 });
      return;
    }
    const formData = new FormData(e.target);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    data["token"] = token;

    globalRequest(
      "auth/login",
      "",
      "POST",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify(data),
      ""
    )
      .then((res) => {
        setLoading(false);
        if (turnstileInstance && typeof turnstileInstance.reset === "function") {
          turnstileInstance.reset();
          setToken("");
        } else {
          console.warn("Turnstile instance is invalid, cannot reset.");
        }
        if (res.status === 401) {
          res
            .json()
            .then((res) =>
              addAlert({ message: res.message, severity: "error", timeout: 5 })
            );
          return;
        }
        setUser({
          name: res.name,
          company: res.company,
          admin: res.admin,
          email: res.email,
        });
        navigate("/main");
      })
      .catch((err) => console.warn(err));
  }

  

  const fields = [
    {
      type: "email",
      label: "Email",
      name: "email",
      placeholder: "Email",
      autocomplete: "on",
    },
    {
      type: "password",
      label: "Password",
      name: "password",
      placeholder: "Password",
      autocomplete: "on",
      isPassword: true,
    },
  ];

  const captcha = {
    element: 
      <Turnstile  
        sitekey={'0x4AAAAAABBbc4eBtMSu7acU'}  
        theme="light" 
        onVerify={handleTurnstileChange}  
        onLoad={(widgetId, instance) => {
          setTurnstileInstance(instance);
        }}
        className="mt-8"/>
  };

  const forget = {text: "Forgot your password?", onclick: openPopUp}

  const signUp = () => {
    navigate("/register");
  };

  const form = {
    fields: fields,
    submit: submitForm,
    submitButtonValue: "Sign in",
    loading: loading,
    type: "login",
    signUp: signUp,
    captcha: captcha
  };

  return (
    <div className="h-full w-full flex items-center justify-center bg-clean bg-no-repeat bg-center bg-cover overflow-y-auto">
      {popUp && <RecoveryPopup open={popUp} setPopUp={setPopUp} />}
      <CardPage>
        <CardPageHeader>
          <img src={logo} className="h-[90px]" alt="Planeta Informática Logo" />
          <h1 className="font-semibold text-slate-700 text-2xl">
            Sign in with your Planeta Account
          </h1>
        </CardPageHeader>

        <CardPageBody>
          <Form
            fields={form.fields}
            textarea={form.textarea}
            checkbox={form.checkbox}
            captcha={form.captcha}
            onSubmit={form.submit}
            submitButtonValue={form.submitButtonValue}
            loading={form.loading}
            type={form.type}
            signUp={form.signUp}
            forget = {forget}
          />
        </CardPageBody>
      </CardPage>
    </div>
  );
}
