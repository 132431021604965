import React, { useState, useEffect } from 'react';
import globalRequest from '../../../functions/backendRequests';
import { Checkbox } from '@mui/material';
import { useUser } from '../../../users/UserContext';

export default function VersionRow(props) {

  const { setUser } = useUser();

  const { platform, companyName, productName, permissions, version, applyPermissions, updatePermissions} = props;
  const [selectedVersions, setSelectedVersions] = useState({});
  const [aux, setAux] = useState(false);
  

  useEffect(() => {
    const checkVersions = (permissions && permissions.find((p) => p.name === platform.name)?.versions) || [];

    if (checkVersions.length > 0) {
      const newSelectedVersions = checkVersions.reduce((acc, v) => {
        const key = `${productName}-${platform.name}-${v}`;
        acc[key] = true;
        return acc;
      }, {});
      setSelectedVersions(newSelectedVersions);
      setAux(true);
    } else {
      setSelectedVersions({});
      setAux(false);
    }
  }, [platform, companyName, productName, permissions]);

  const bulkPermissions = (company, product, platform, toInput) => {
    setAux(!aux);
    platform.versions.forEach((version) => {
      const key = `${product}-${platform.name}-${version}`;
      setSelectedVersions(prevSelectedVersions => ({
        ...prevSelectedVersions,
        [key]: toInput
      }));
    });

    globalRequest(
      'companies/setBulkPermissions',
      '',
      'POST',
      { "Content-Type": "application/json" },
      JSON.stringify({
        companyName: company,
        productName: product,
        platform: platform.name,
        toInput
      }), "",
      setUser
    ).then((res) => {
      updatePermissions();
    });
  };


  const toggleVersionSelection = (key) => {
    setSelectedVersions(prevSelectedVersions => ({
      ...prevSelectedVersions,
      [key]: !prevSelectedVersions[key]
    }));
  };

  return (
    <>
      <div className="w-full min-h-16 h-auto bg-white flex items-stretch text-center border-b border-slate-300">
        <div className="min-w-[336px] flex items-center border-r border-slate-300 ">
          <span className="pl-32">
            <Checkbox
              type="checkbox"
              checked={aux}
              className="mr-2"
              onChange={(e) => bulkPermissions(companyName, productName, platform, e.target.checked)}
            />
            {platform.name}
          </span>
        </div>
        <div className="w-full flex flex-wrap items-center">
          {platform.versions.map((v) => {
            const key = `${productName}-${platform.name}-${v}`;
            return (
              <React.Fragment key={key}>
                <label className="flex items-center mx-12">
                  <Checkbox
                    type="checkbox"
                    checked={!!selectedVersions[key]}
                    onChange={() => {
                      toggleVersionSelection(key);
                      applyPermissions(companyName, productName, platform.name, v, !selectedVersions[key]);
                    }}
                  />
                  <span className="ml-1">{v}</span>
                </label>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};