import React, { useEffect, useState } from "react";
import CardPage from "../../components/card/cardPage/CardPage";
import PageTable from "../../components/tables/PageTable";
import globalRequest from "../../functions/backendRequests";
import { useAlerts } from "../../alerts/AlertContext";
import { useUser } from "../../users/UserContext";
import { useLocation } from "react-router-dom";
import { extractColors } from "extract-colors";
import EditIcon from "@mui/icons-material/Edit";
import CardPageHeader from "../../components/card/cardPage/CardPageHeader";
import CardPageBody from "../../components/card/cardPage/CardPageBody";
import CardLabelItem from "../../components/card/cardPage/CardLabelItem";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import Form from "../../components/forms/Form";
import UploadFile from "../../components/upload/UploadFile";
import ColorSelector from "../../components/colorSelector/ColorSelector";
import {
  calcPageSizeInitialState,
  formatSortedObjectForQuery,
} from "../../functions/helper";
import { use } from "react";
import ModalPage from "../../components/modal/ModalPage";

export default function AdminProducts() {
  const { addAlert, clearAlerts } = useAlerts();
  const { setUser } = useUser();
  const [products, setProducts] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBuffer, setImageBuffer] = useState(null);
  const [imageColors, setImageColors] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#fff");
  const location = useLocation();
  const [productId, setProductId] = useState(
    location.state ? location.state.productId : null
  );
  const [activeFilters, setActiveFilters] = useState(productId ? true : false);

  const [filters, setFilters] = useState(
    location.state ? [location.state] : []
  );
  const [sorted, setSorted] = useState({
    enabled: false,
    ascending: true,
    field: "",
  });
  const [fetchState, setFetchState] = useState("Loading...");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  const [pageSize, setPageSize] = useState(calcPageSizeInitialState);
  const [subPage, setSubPage] = useState("See all");

  const updateItems = () => {
    fetchData(
      page,
      pageSize,
      setTotalPages,
      setFetchState,
      searchValue,
      sorted.enabled && formatSortedObjectForQuery(sorted)
    );
  };

  useEffect(() => {
    updateItems();
  }, [page, pageSize, filters, searchValue, sorted]);

  useEffect(() => {
    if (!imageBuffer) return;

    const image = new Image();
    const objectURL = URL.createObjectURL(new Blob([imageBuffer]));
    image.src = objectURL;

    const fetchColors = async () => {
      try {
        await new Promise((resolve) => {
          image.onload = resolve;
        });

        let colors = await extractColors(objectURL);
        colors = colors.sort((a, b) => b.area - a.area);
        setImageColors(colors);
      } catch (error) {
        console.error("Erro ao extrair as cores:", error);
      }
    };

    fetchColors();
  }, [imageBuffer]);

  useEffect(() => {
    resetAllStates();
  }, [subPage])

  const resetStartingFilters = () => {
    setProductId(null);
    setActiveFilters(false);
    setProducts(null);
  };

  const startingFilters = {
    activeFilters: activeFilters,
    resetStartingFilters: resetStartingFilters,
  };

  const selectFileFromComputer = (e) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png, .jpg, .jpeg";
    fileInput.onchange = (e) => {
      const [file] = e.target.files;
      if (file.size === 0) {
        addAlert({ message: "Empty file", severity: "error", timeout: 5 });
        return;
      }

      if (file.size > 16 * 1024 * 1024) {
        addAlert({
          message: "File size is too big (> 16MB)!",
          severity: "error",
          timeout: 5,
        });
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const buffer = event.target.result;
        setImageBuffer(buffer);
      };
      reader.readAsArrayBuffer(file);
      var fd = new FormData();
      fd.append("name", file.name);
      fd.append("size", file.size);
      fd.append("type", file.type);
      fd.append("ext", file.name.split(".").pop());
      fd.append("file", file);
      setSelectedImage(fd);
    };
    fileInput.click();
  };

  const submitProduct = (e) => {
    e.preventDefault();

    const fd = selectedImage || new FormData();
    const formData = new FormData(e.target);

    setLoading(true);
    formData.forEach((value, key) => fd.append(key, value));
    fd.set(
      "selectedCompanies",
      selectedItems.map((item) => item.id)
    );
    fd.set("color", color);

    globalRequest("products/upload", "", "POST", {}, fd, "res", setUser)
      .then((res) => {
        setLoading(false);
        return res.json().then((resJson) => {
          if (res.status === 200) {
            addAlert({ message: resJson.message, severity: "success", timeout: 5 });
            e.target.reset();
            setSelectedImage(null);
            setSelectedItems([]);
            setColor("#fff");
            updateItems();
          } else {
            addAlert({ message: resJson.message, severity: "error", timeout: 5 });
          }
        });
      })
      .catch((err) => console.warn(err));
    setSubPage("See all");
  };

  useEffect(() => {
    if (companies) return;

    globalRequest("companies/checkCompanies", "", "GET", {}, null, "", setUser)
      .then((res) => {
        setCompanies(res);
      })
      .catch((err) => console.warn(err));
  });

  const dismissFile = () => {
    setSelectedImage(null);
  };

  const uploadFile = {
    title: "Select an image to upload:",
    selectFileFromComputer: selectFileFromComputer,
    dismissFile: dismissFile,
    selectedFile: selectedImage,
  };

  const selectPageOption = (option) => {
    setSubPage(option);
  }

  const addProductToCompany = (products, companies) => {
    if (products.length === 0)
      return addAlert({
        message: "Please select a product!",
        severity: "warning",
        timeout: 5,
      });
    if (companies.length === 0)
      return addAlert({
        message: "Please select a company!",
        severity: "warning",
        timeout: 5,
      });

    setLoading(true);

    globalRequest(
      "products/addProductsToCompanies",
      "",
      "POST",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify({
        products: products.map((product) => product.id),
        companies: companies.map((company) => company.id),
      }),
      "",
      setUser
    )
      .then((res) => {
        if (res && res.message) {
          addAlert({ message: res.message, severity: "success", timeout: 5 });
        } else {
          res
            .json()
            .then((res) =>
              addAlert({ message: res.message, severity: "error", timeout: 5 })
            );
        }
        setLoading(false);
      })
      .catch((err) => console.warn(err));
  };

  const deleteProducts = (e, products, resetItems, handleClose) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const deleteConfirmation = formJson.deleteConfirmation;
    const deleteOnCascade = formJson.deleteOnCascade;

    if (deleteConfirmation !== "delete") {
      addAlert({
        message: 'Please type "delete" to confirm action!',
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    setLoading(true);

    globalRequest(
      "products/delete",
      "",
      "DELETE",
      { "Content-Type": "application/json" },
      JSON.stringify({
        products: products.map((product) => product.id),
        deleteOnCascade: deleteOnCascade === "on",
      }),
      "",
      setUser
    )
      .then((res) => {
        if (res && res.message) {
          addAlert({ message: res.message, severity: "success", timeout: 5 });
          updateItems();
          resetItems();
        } else {
          console.warn("Unexpected response format:", res);
          addAlert({ message: "An unexpected error occurred", severity: "error", timeout: 5 });
        }
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.error("Error deleting products:", err);
        setLoading(false);
      });
  };

  const resetAllStates = () => {
    setSelectedImage(null);
    if (subPage !== 'Edit') {
      setSelectedProduct(null);
      setSelectedItems([]);
    }
    setImageColors([])
    if (subPage === 'Create') setColor("#f0f0f0");
  }

  const editProduct = (product) => {
    if (!product?.id) {
      console.warn("Invalid product data:", product);
      addAlert({
        message: "Invalid product data",
        severity: "error",
        timeout: 5,
      });
      return;
    }

    globalRequest(
      "companies/companiesWithProduct",
      `?id=${product.id}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        if (!Array.isArray(res)) {
          console.warn("Unexpected response format:", res);
          addAlert({
            message: "Unexpected response format",
            severity: "error",
            timeout: 5,
          });
          return;
        }

        setSelectedProduct(product);
        setSelectedItems(res);
        setSubPage("Edit");
        setColor(product.color);


      })
      .catch((err) => {
        console.error("Error fetching companies for product:", err);
        addAlert({
          message: "Failed to load product data",
          severity: "error",
          timeout: 5,
        });
      });
  };


  const submitEditProduct = (e) => {
    e.preventDefault();

    const fd = selectedImage || new FormData();
    const formData = new FormData(e.target);

    setLoading(true);

    formData.forEach((value, key) => fd.append(key, value));
    fd.set(
      "selectedCompanies",
      selectedItems.map((item) => item.id)
    );
    fd.set("color", color);
    fd.set("productId", selectedProduct.id);

    globalRequest("products/edit", "", "PUT", {}, fd, "res", setUser)
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          res.json().then((data) => {
            addAlert({
              message: data.message || "Product edited successfully",
              severity: "success",
              timeout: 5,
            });
            e.target.reset();
            resetAllStates();
            setColor("#fff");
            setSubPage("See all");
            updateItems();
          });
        } else {
          res.json().then((data) => {
            addAlert({
              message: data.message || "Failed to edit product",
              severity: "error",
              timeout: 5,
            });
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.warn("Error in submitEditProduct:", err);
        addAlert({
          message: "An error occurred while editing the product",
          severity: "error",
          timeout: 5,
        });
      });
    setSubPage("See all");
  }

  const fields = [
    {
      type: "text",
      id: "name",
      label: "Product name",
      name: "productName",
      placeholder: "Product name",
      autocomplete: "off",
      required: true,
    },
    {
      type: "text",
      id: "description",
      label: "Description",
      name: "productDescription",
      placeholder: "Description",
      autocomplete: "off",
      required: true,
    },
    {
      type: "text",
      id: "link",
      label: "Link",
      name: "productLink",
      placeholder: "Link (Only for Website)",
      autocomplete: "off",
      required: false,
    },
  ];

  const functions = [
    {
      name: "Delete Products",
      func: deleteProducts,
      dialogTitle: "Delete products",
      dialogContentText: "Are you sure you want to delete these products?",
      textField: {
        name: "deleteConfirmation",
        label: 'Confirm delete by typing "delete"',
      },
      checkBox: {
        name: "deleteOnCascade",
        label: "Delete all files from this product",
      },
    },
    {
      type: "modal",
      name: "Add to companies",
      func: addProductToCompany,
      loading: loading,
    },
  ];

  const pageOptions = ["See all", "Create"];

  const pageHeader = {
    title: "Products",
    options: pageOptions,
    selectPageOption: selectPageOption,
    subPage: subPage,
  };

  const setSelectedCompanyInfo = (companyName) => {
    setSelectedCompany(companyName);
  };

  const selectItems = (item) => {
    if (selectedItems.some((e) => e.id === item.id)) {
      setSelectedItems(selectedItems.filter((e) => e.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const selectAllItems = () => {
    if (selectedItems.length === companies.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems([...companies]);
    }
  };

  const dropdownMenus = [
    {
      spanTitle: "Select companies that can access this product:",
      title: "Companies",
      items: companies,
      setSelected: setSelectedCompanyInfo,
      selected: selectedCompany,
      background: true,
      checkbox: true,
      selectItem: selectItems,
      selectAllItems: selectAllItems,
      selectedItems: selectedItems,
      setSelectedItems: setSelectedItems,
    },
  ];

  const formatFiltersForQuery = (filters) => {
    return filters.map((filter) => `${filter.key}=${filter.itemId}`).join("&");
  };

  const fetchData = (
    page,
    pageSize,
    setTotalPages,
    setFetchState,
    searchValue,
    sorted
  ) => {
    try {
      globalRequest(
        "products/adminTableProducts",
        `?page=${page}&pageSize=${pageSize}&filters=${formatFiltersForQuery(
          filters
        )}&search=${searchValue || ""}&sort=${sorted || ""}`,
        "GET",
        {},
        null,
        "",
        setUser
      )
        .then((res) => {
          if (!res || !res.data) {
            throw new Error("Invalid response from server");
          }

          res.data.forEach((product) => {
            try {
              product.createdAt = new Date(product.createdAt).toLocaleString(
                "en-US",
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }
              );
              product.updatedAt = new Date(product.updatedAt).toLocaleString(
                "en-US",
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }
              );
            } catch (error) {
              console.error("Error formatting date:", error);
            }
          });

          setProducts(res.data);
          setTotalPages(res.totalPages);

          if (res.status === 404) {
            setFetchState("No data found");
          } else {
            setFetchState(res.state || "Success");
          }
        })
        .catch((err) => {
          console.warn("Error fetching data:", err);
          setFetchState("Error fetching data");
        });
    } catch (error) {
      console.error("Error in fetchData:", error);
      setFetchState("An error occurred");
    }
  };


  const handlePageChange = (e, pageNumber) => {
    if (page === pageNumber) return;
    setPage(pageNumber);
  };

  const tablePagination = {
    page: page,
    pageSize: pageSize,
    totalPages: totalPages,
    data: products,
    fetchState: fetchState,
    setPage: setPage,
    setPageSize: setPageSize,
    setTotalPages: setTotalPages,
    handlePageChange: handlePageChange,
  };

  const table = {
    rows: products && products,
    cols: [
      { name: "Id", key: "id" },
      { name: "Name", key: "name" },
      { name: "Description", key: "description" },
      {
        name: "Color",
        key: "color",
        render: (item) => (
          <div
            className="w-[20px] h-[20px] rounded-full"
            style={{ backgroundColor: item.color }}></div>
        ),
      },
      { name: "Link", key: "link" },
      { name: "Created At", key: "createdAt" },
      { name: "Updated At", key: "updatedAt" },
      {
        name: "Edit Product",
        key: "editProduct",
        render: (item) => (
          <div
            className="max-h-40 max-w-64 flex items-center gap-x-2 hover:underline text-blue-600 cursor-pointer truncate"
            onClick={() => editProduct(item)}>
            <span>Edit Product</span> <EditIcon />{" "}
          </div>
        ),
      },
    ],
    functions: functions,
    fetchData: fetchData,
    filters: filters,
    pagination: tablePagination,
  };

  const addOrRemoveFilter = (key, value, itemId) => {
    if (
      filters.some(
        (filter) =>
          filter.key === key &&
          filter.value === value &&
          filter.itemId === itemId
      )
    ) {
      setFilters(
        filters.filter(
          (filter) =>
            !(
              filter.key === key &&
              filter.value === value &&
              filter.itemId === itemId
            )
        )
      );
    } else {
      setFilters([...filters, { key: key, value: value, itemId: itemId }]);
    }
  };

  const form = {
    fields: fields,
    checkbox: null,
    submit: subPage === "Create" ? submitProduct : submitEditProduct,
    submitButtonValue: subPage === "Create" ? "Create Product" : "Save Edit",
    loading: loading,
    resetForm: true,
  };

  const closeButtonOnClick = () => {
    setSelectedProduct(null);
    setSubPage("See all");
  };

  if (form && selectedProduct) {
    form.fields.forEach((item) => {
      Object.keys(selectedProduct).forEach((select) => {
        if (item["id"].includes(select)) {
          item["defaultValue"] = selectedProduct[select];
        }
      });
    });

    if (form && form.textarea) {
      Object.keys(selectedProduct).forEach((select) => {
        if (form.textarea["id"].includes(select)) {
          form.textarea["defaultValue"] = selectedProduct[select];
        }
      });
    }
  }

  const modal = {
    title: "Create Product",
    open: subPage === "Create" || subPage === "Edit",
    onClose: closeButtonOnClick,
  };

  return (
    <div className="w-full flex flex-col py-6 px-8 items-center bg-[#f8f8ff] font-semibold gap-6 relative">

      <PageTable
        title="All products"
        table={table}
        pageHeader={pageHeader}
        adminPage={true}
        dropdownMenus={dropdownMenus}
        startingFilters={startingFilters}
        fields={products}
        addOrRemoveFilter={addOrRemoveFilter}
        filters={filters}
        setFilters={setFilters}
        sorted={sorted}
        setSorted={setSorted}
        subPage={subPage}
        setSubPage={setSubPage}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        modal={modal}
      />

      <ModalPage open={modal.open} onClose={modal.onClose}>
        <CardPageHeader closeButton={true} closeButtonOnClick={modal.onClose}>
          <h1 className="font-semibold text-slate-700 text-2xl">
            {subPage === "Create" ? "Create Product" : "Edit Product"}
          </h1>
          {subPage === "Edit" && selectedProduct && (
            <h1 className="font-semibold text-slate-700 text-2xl">
              "{selectedProduct && selectedProduct.name}"
            </h1>
          )}
        </CardPageHeader>

        <CardPageBody>
          {dropdownMenus.map((item, index) => (
            <CardLabelItem
              key={index}
              text="Select companies that can access this product:">
              <DropdownMenu
                selected={item.selected}
                selectItem={item.selectItem}
                title={item.title}
                items={item.items}
                setSelected={item.setSelected}
                background={item.background}
                checkbox={item.checkbox}
                selectAllItems={item.selectAllItems}
                selectedItems={item.selectedItems}
                size={"w-[150px]"}
              />
            </CardLabelItem>
          ))}

          <UploadFile
            title={uploadFile.title}
            selectedFile={uploadFile.selectedFile}
            dismissFile={uploadFile.dismissFile}
            selectFileFromComputer={uploadFile.selectFileFromComputer}
          />

          <ColorSelector
            color={color}
            onChange={setColor}
            colors={imageColors}
            currentColor={color}
          />

          <Form
            fields={form.fields}
            textarea={form.textarea}
            checkbox={form.checkbox}
            onSubmit={form.submit}
            submitButtonValue={form.submitButtonValue}
            loading={form.loading}
            type={form.type}
            signUp={form.signUp}
          />
        </CardPageBody>
      </ModalPage>
    </div>
  );
}
