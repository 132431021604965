import { useEffect } from "react";
import { useUser } from "./UserContext";
import globalRequest from "../functions/backendRequests";



export default function UseAuth({ children }) {
  const { setUser } = useUser();

  useEffect(() => {
    globalRequest("auth/check", "", "GET", {}, null, "", setUser).then((res) => {
      if (res && res.loggedIn) {
        setUser({ name: res.name, company: res.company, admin: res.admin, email: res.email });
      } else {
        setUser(null);
      }
    }).catch(err => console.warn(err));

  }, [setUser]);

  return children;
}
