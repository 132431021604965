import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";
import SupportPopup from "./PopUps/supportPopup/SupportPopup";
import UserPopup from "./PopUps/userPopup/UserPopup";
import React from "react";
import { useState } from "react";

export default function Layout({ children, isAdminPage }) {
  const [subPage, setSubPage] = useState('See all');

  return (
    <div className="w-full flex flex-col h-screen">
      <Navbar />
      <UserPopup />
      <div className="w-full flex flex-row flex-1">
        {isAdminPage && (<Sidebar activePage={children.type.name} setSubPage={setSubPage} subPage={subPage} />)}
        {subPage && (
          <main className="w-full h-full overflow-x-hidden">
            {children}
          </main>
        )}
      </div>
      <SupportPopup />
      <Footer />
    </div>
  )
}