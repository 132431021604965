import React from 'react'
import { Box, Modal } from '@mui/material';
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ButtonSubmit from '../../buttons/ButtonSubmit';
import globalRequest from '../../../functions/backendRequests';
import { useAlerts } from '../../../alerts/AlertContext';

export default function RecoveryPopup(props) {

  const [failed, setFailed] = useState(false)
  const [email, setEmail] = useState('')
  const { addAlert, clearAlerts } = useAlerts();

  const changePassword =  async (e) => {
    e.preventDefault();
    
    const res = await globalRequest(
      "users/recoverPassword",
      "",
      "POST",
      { "Content-Type": "application/json" },
      JSON.stringify({ email }),
      "res"
    )
    if (res.status !== 200) {
      clearAlerts();
      const resJson = await res.json();
      addAlert({ message: resJson.message, severity: "error", timeout: 5 });
      setFailed(false)
    } 
    else {
      clearAlerts();
      addAlert({ message: "Password changed successfully!", severity: "success", timeout: 5 });
      addAlert({ message: "Check your email", severity: "success", timeout: 5 });
      setFailed(false)
      ClosePopup()
    }
  }
  function ClosePopup() {
    props.setPopUp(false)
  }
  
    return (
      <Modal
      open={props.open || false}
      onClose={ClosePopup}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
        <Box className='absolute h-[60%] max-h-[350px] w-[40%] max-w-[950px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white-lilac-50 rounded-lg outline-none'>
          <div className="flex flex-col w-full h-full bg-white-lilac-50 z-40 divide-y divide-slate-200 overflow-hidden rounded-lg">
            <div className="relative w-full min-h-[55px] h-[55px] max-h-[55px] border-2 border-gray-300 flex justify-center items-center z-10">
              <span>Planeta Informática</span>
              <button
                className="absolute right-0 z-0 h-full w-16 hover:bg-red-500 hover:text-slate-100 transition duration-200 ease-in-out rounded-tr-md"
                onClick={ClosePopup}>
                <CloseIcon />
              </button>
            </div>
            <div className="w-full flex flex-1 p-3 flex-col justify-center items-center px-14 py-10 gap-y-[20%]">
              <div className='w-full flex flex-col items-start space-y-2'>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email Address' className='pl-2 w-full h-10 border-2 border-gray-300 rounded-lg outline-none' />
              </div>
              <ButtonSubmit value={"Send"} onClick={changePassword} className='w-full h-10 mt-5' />
            </div>
          </div>
        </Box>
      </Modal>
    )
}