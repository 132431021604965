import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Checkbox } from '@mui/material';

export default function DropdownMenu({ ...props }) {

  if(props.selectedItems){
  }
  return (
    <Menu as="div" className={`relative text-left inline-block items-center h-full data-[active]:z-20 ${props.size ? props.size + " min-w-[100px]" : "w-auto"} `} >

      {!props.email && (props.background ?
        <MenuButton type="button" className={`inline-flex items-center justify-center h-full w-full min-w-32 gap-x-1.5 rounded-lg bg-white px-4 py-3 text-sm font-semibold text-slate-600 shadow-sm ring-2 ring-inset ring-gray-200 hover:ring-slate-400 group data-[active]:ring-gray-400 transition ease-in-out duration-150`}>
          <span className='w-full overflow-hidden text-nowrap'>{props.selected ? props.selected : props.title}</span>
          <ArrowDropDownIcon aria-hidden="true" className="h-5 w-5 text-slate-400 self-end group-data-[active]:rotate-180 group-data-[active]:text-blue-700 transition ease-in-out duration-500" />
        </MenuButton>
        :
        <div className="w-full flex items-center justify-start">
          {props.selected && (
            <button type="button" className="mr-2" onClick={() => props.setSelected("")}>
              <CancelOutlinedIcon className="text-red-500 cursor-pointer hover:text-red-700 transition ease-in-out" />
            </button>
          )}
          <MenuButton type="button" className="inline-flex items-start justify-start w-full gap-x-1.5 rounded-md py-2 font-semibold text-slate-600 hover:text-blue-700 data-[active]:text-blue-700 group">
            <span className={props.className}>{props.selected ? props.selected : props.title}</span>
            <ArrowDropDownIcon aria-hidden="true" className="h-5 w-5 text-slate-400 self-end group-data-[active]:rotate-180 group-data-[active]:text-blue-700 group-hover:text-blue-700" />
          </MenuButton>
        </div>
      )}
      {props.email && (
        <MenuButton type="button" id='email' onClick={() => { }} className="inline-flex items-start justify-between w-full gap-x-1.5 rounded-md py-2 font-semibold text-slate-600 hover:text-blue-700 data-[active]:text-blue-700 group">
          <span className={props.className}>{props.selected ? props.selected : props.title}</span>
          <ArrowDropDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400 self-end group-data-[active]:rotate-180 group-data-[active]:text-blue-700 group-hover:text-blue-700" />
        </MenuButton>
      )}

      <MenuItems
        transition
        className={`absolute flex justify-center mt-2 w-full z-20 max-h-40 ${props.itemsSize && props.itemsSize} `}
      >
        <div className='w-full max-w-[300px] overflow-y-auto rounded-md bg-white-lilac-50 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'>
          {props.checkbox && props.items && props.items.length > 0 &&
            <MenuItem>
              <div className='flex items-center border-b border-gray-200'>
                <Checkbox className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150" onChange={() => props.selectAllItems()} checked={props.selectedItems.length === props.items.length} onClick={(e) => e.stopPropagation()} />
                <span onClick={(e) => e.stopPropagation()} className="w-full text-start justify-start flex text-sm text-slate-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-slate-900 overflow-hidden">
                  Select All
                </span>
              </div>
            </MenuItem>
          }
          
          <div className='border-t-2 border-slate-200'>
            {props.checkbox && <span className='w-full text-center justify-center flex py-2 text-xs font-semibold text-slate-700'></span>}
            {props.items && props.items.length > 0 ?
              props.items.map((item, index) => (
                <MenuItem key={index}>
                  {item && props.checkbox ?

                    <div className='flex items-center border-b border-gray-200 truncate' title={item.name}>
                      <Checkbox className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150" onChange={() => props.selectItem(item)} checked={(props.selectedItems.length > 0 && props.selectedItems.some(selectedItem => selectedItem.id === item.id)) || props.selectedItems.includes(item)} onClick={(e) => e.stopPropagation()} />
                      <span onClick={(e) => e.stopPropagation()} className="w-full text-start justify-start flex text-sm text-slate-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-slate-900 truncate">
                        {item.name}
                      </span>
                    </div>
                    :
                    <button
                      type="button"
                      onClick={() => props.setSelected(item)}
                      className={`w-full text-center flex items-center ${props.image ? "justify-start p-1 gap-x-2" : "justify-center px-4 py-2"}
                       text-sm text-gray-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-gray-900 border-b border-gray-200`}
                    >
                      {props.image &&
                        <div className='min-h-[35px] min-w-[50px] flex items-center justify-center'>
                          <img src={props.image[item]} className='h-auto max-h-[35px] w-auto max-w-[50px] p-1 mx-2' alt='productImage' />
                        </div>
                      }
                      {props.icons &&
                        <div className='min-h-[35px] min-w-[50px] flex items-center justify-center'>
                          {props.icons[item]}
                        </div>
                      }
                      <span className='w-full flex justify-start truncate' title={item}>{item}</span>
                    </button>
                  }
                </MenuItem>
              ))
              :
              <MenuItem>
                <button
                  type="button"
                  className="w-full text-center justify-center flex px-4 py-2 text-sm text-gray-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-gray-900 overflow-hidden"
                >
                  No items
                </button>
              </MenuItem>
            }
          </div>
        </div>
      </MenuItems>
    </Menu>
  );
}