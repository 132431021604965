import DownloadDropdown from "../dropdown/DownloadDropdown";

export default function ButtonProductColor(props) {
  return (
    <button
      onClick={() =>
        (props.buttonFunction && props.buttonFunction(props.item)) || ""
      }
      className={`min-w-[160px] w-auto max-w-[300px] group  border-slate-700 border-2 text-slate-700 px-4 py-3 flex  justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-150 hover:border-transparent cursor-pointer text-nowrap`}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = props.bgColorHover || "#656970";
        e.currentTarget.style.color = "#fff ";
        e.currentTarget.style.borderColor = "transparent";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "transparent";
        e.currentTarget.style.color = "#334155";
        e.currentTarget.style.borderColor = "#334155";
      }}>
      <div className="flex items-center gap-3">
        <span className="w-full truncate text-nowrap">{props.buttonText}</span>

        {props.dropdown && (
          <div
            id="downloadButton"
            onClick={(e) => e.stopPropagation()}
            className="w-6 h-6 flex items-center justify-center rounded-full text-slate-700 group-hover:text-white hover:bg-white-lilac-50">
            <DownloadDropdown
              openDropdown={props.openDropdown}
              productName={props.productName}
              items={props.dropdown.availablePlatforms}
              checkbox={true}
              selectItem={props.dropdown.selectItems}
              selectAllItems={props.dropdown.selectAllItems}
              selectedItems={props.dropdown.selectedItems}
            />
          </div>
        )}

        {props.icon}
      </div>
    </button>
  );
}
