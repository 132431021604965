const globalRequest = async (page, query, method, headers = {}, body = null, responseType, setUser) => {
  try {
    const options = {
      credentials: "include",
      method,
      ...(headers && Object.keys(headers).length > 0 && { headers }),
      ...(method !== "GET" && body && { body }),
    };

    const res = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}/${page}${query}`, options);

    if (res.ok) {
      switch (responseType) {
        case "blob":
          return await res.blob();
        case "res":
          return res;
        default:
          return await res.json();
      }
    }

    if (res.status === 406) {
      await tokenRefresh(setUser);
      return globalRequest(page, query, method, headers, body, responseType, setUser);
    }

    if (res.status === 401 && setUser) {
      const resJson = await res.json();
      if (resJson.loggedIn === false)
        setUser(null);
      return;
    }

    return res;

  } catch (err) {
    console.error(err);
    throw err;
  }
};



const tokenRefresh = async (setUser) => {

  await globalRequest("auth/refresh", "", "GET", {}, null, "res", setUser).then((res) => {
    if (res.status === 401) {
      return false;
    }
  }).catch(err => console.error(err));

}


export default globalRequest;