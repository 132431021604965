import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox } from '@mui/material';
import { useEffect, useRef } from 'react';

export default function DownloadDropdown(props) {
  const dropBtn = useRef(null);
  useEffect(() => {
    if (props.openDropdown && dropBtn.current) {
      dropBtn.current.click();
      props.setOpenDropdown(false);
    }
  }, [props.openDropdown]);

  return (
    <Menu as="div" className={`relative text-left inline-block items-center hover:text-slate-700`} >
      {({ open }) => (
        <>
          <div className="w-full flex items-center justify-start">
            <MenuButton type="button" id='openDrop' className="inline-flex items-start justify-start w-full gap-x-1.5 rounded-md py-2 font-semibold group" ref={dropBtn}>
              <ArrowDropDownIcon aria-hidden="true" className="h-5 w-5  self-end group-data-[active]:rotate-180 transition-transform ease-in-out duration-500 delay-75 " />
            </MenuButton>
          </div>

          {(props.openDropdown || open) && (
            <MenuItems
              transition
              className={`absolute bottom-16 right-12 flex justify-center mt-2 w-full z-[1000px] max-h-40 ${props.size && 'min-w-[200px]'} `}
            >
              <div className='min-w-[160px] w-auto max-w-[300px]} overflow-y-auto rounded-md bg-white-lilac-50 shadow-lg ring-2 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'>
                <span className='w-full text-center justify-center flex py-2 text-xs font-semibold text-slate-700'>Platforms</span>

                {props.checkbox && props.items && props.items.length > 0 &&
                  <MenuItem>

                    <div className='flex items-center border-b border-gray-200 cursor-default'>
                      <Checkbox className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150" onChange={() => props.selectAllItems()} checked={props.selectedItems.length === props.items.length} onClick={(e) => e.stopPropagation()} />
                      <label onClick={(e) => e.stopPropagation()} className="w-full text-start justify-start flex text-sm text-slate-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-slate-900 overflow-hidden">
                        Select All
                      </label>
                    </div>
                  </MenuItem>
                }
                <div className='border-t-2 border-slate-200'>
                  {props.items && props.items.length > 0 ?

                    props.items.map((item, index) => (
                      <MenuItem key={index}>
                        {item && props.checkbox &&

                          <div className='flex items-center border-b border-gray-200 cursor-default'>
                            <Checkbox className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150" onChange={() => props.selectItem(item)} checked={(props.selectedItems.length > 0 && props.selectedItems.some(selectedItem => selectedItem === item)) || props.selectedItems.includes(item)} onClick={(e) => e.stopPropagation()} />
                            <label onClick={(e) => e.stopPropagation()} className="w-full text-start justify-start flex text-sm text-slate-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-slate-900 overflow-hidden">
                              {item}
                            </label>
                          </div>

                        }
                      </MenuItem>
                    ))
                    :
                    <MenuItem>
                      <button
                        type="button"
                        className="w-full text-center justify-center flex px-4 py-2 text-sm text-gray-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-gray-900 overflow-hidden"
                      >
                        No items
                      </button>
                    </MenuItem>
                  }
                </div>
              </div>
            </MenuItems>
          )}
        </>
      )}
    </Menu>
  );
}