import { createContext, useContext, useState } from "react";

const UserContext = createContext();


export function UserProvider({children}) {
  const [user, setUser] = useState({name: null, company: null, admin: null });

  return(
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}