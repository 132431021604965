import Pagination from '@mui/material/Pagination';


export default function TableFooterPagination(props) {
  return (
    <table className="table-fixed w-full text-base text-left rtl:text-right text-slate-600 border-t-2 border-gray-300 rounded-b-lg">
      <tfoot className="h-20 w-full flex items-center justify-center text-xs text-slate-700 uppercase sticky bottom-0">
        <tr className="h-full w-full flex items-center justify-center">
          <td className="h-full w-full flex justify-center items-center">
            <Pagination
              page={props.page}
              count={props.totalPages}
              onChange={props.handlePageChange}
              showFirstButton
              showLastButton
            />
          </td>
        </tr>
      </tfoot>
    </table>

  );

}