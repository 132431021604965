import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function UserProfileDropdown({ ...props }) {

  return (

    
    <Menu as="div" className={`relative text-left inline-block items-center h-full z-50`} >

      <MenuButton
        type="button"
        className="flex items-start justify-start w-full gap-x-1.5 rounded-md py-2 font-semibold text-slate-600 hover:text-blue-700 data-[active]:text-blue-700 group"
      >
        <div className="w-full flex font-semibold items-center justify-between gap-x-2 px-2 py-2 text-center cursor-pointer hover:text-blue-600 group-data-[active]:bg-mischka-200 group-data-[active]:text-slate-700 rounded-lg">
          <PersonOutlineOutlinedIcon />
          <span className={`${props.isNavOpen ? "max-w-28" : "max-w-48"} truncate text-ellipsis`}>{props.userName}</span>
        </div>
      </MenuButton>

      <MenuItems
        transition
        className={`absolute flex justify-center w-full z-50 max-h-64 min-w-[350px] right-0 origin-top-right rounded-2xl shadow-lg bg-white-lilac-50 ring-1 ring-black ring-opacity-5 focus:outline-none`}
      >
        <div className='w-full max-w-[350px] px-5 overflow-y-auto rounded-2xl bg-white-lilac-50 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'>
          <div id="userProfile" className="h-auto w-full flex items-center font-semibold p-5 gap-x-8 overflow-hidden truncate">
            <div className="w-10 h-10 rounded-full flex items-center justify-center">
              <AccountCircleIcon className="w-10 h-10 rounded-full text-blue-600" sx={{ fontSize: 80 }} />
            </div>
            <div className="h-full w-full flex justify-center flex-col truncate">
              <span className="text-sm text-slate-500">{props.userRole || "User"}</span>
              <h1 className="text-xl text-slate-700 truncate">{props.userName || "User"}</h1>
              <h2 className="text-md text-slate-600 truncate">{props.userEmail || "User@email.com"}</h2>
            </div>
          </div>
          <div className='w-full flex flex-col border-t-2 border-gray-200 py-2 gap-y-2'>

            {props.items && props.items.length > 0 ?

              props.items.map((item, index) => (
                <MenuItem key={index}>
                  <button
                    type="button"
                    onClick={(e) => { e.stopPropagation(); item.onClick(item) }}
                    className={`w-full text-center flex items-center justify-center py-2
                       text-base text-gray-700 data-[focus]:bg-blue-100 data-[focus]:text-gray-900 rounded-lg`}
                  >
                    {item.icon &&
                      <div className='min-h-[35px] min-w-[50px] flex items-center justify-center'>
                        {item.icon}
                      </div>
                    }
                    <span className='w-full flex justify-start truncate font-semibold' title={item.label}>{item.label}</span>
                  </button>

                </MenuItem>
              ))
              :
              <MenuItem>
                <button
                  type="button"
                  className="w-full text-center justify-center flex px-4 py-2 text-sm text-gray-700 data-[focus]:bg-white-lilac-100 data-[focus]:text-gray-900 overflow-hidden"
                >
                  No items
                </button>
              </MenuItem>
            }
          </div>
          {props.logout &&
            <div className='w-full flex items-center justify-center border-none pt-3 pb-5'>
              <button onClick={props.logout} type="button" id="btnLogin"
                className="w-full text-white focus:ring-4 focus:outline-none font-medium rounded-xl px-4 py-2 text-center bg-blue-600 hover:bg-blue-800 focus:ring-blue-900 transition-all ease-in-out duration-150">
                Logout
              </button>
            </div>
          }

        </div>
      </MenuItems>
    </Menu>
  );
}