import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from 'react';

export default function Input({ label, type, name, placeholder, inputStyle, onChange, autoComplete, required, ...props }) {

  const [isTypePassword, setIsTypePassword] = useState(true);

  const showPassword = (e) => {
    const password = document.getElementById(name);
    if (password.type === "password") {
      password.type = "text";
      setIsTypePassword(false);
    } else {
      password.type = "password";
      setIsTypePassword(true);
    }
  };

  return (
    <div className='w-full flex flex-col items-center'>
      <label className='self-start'>{label}</label>

      <div className="relative w-full">

        <input
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          className={inputStyle}
          onChange={onChange}
          required={required}
          autoComplete={autoComplete} />

        <button
          type="button"
          className="absolute right-4 top-1/2 transform -translate-y-1/2 transition duration-200 ease-in-out text-slate-500 hover:text-blue-600 hover:bg-blue-100 rounded-full p-2"
          onClick={(e) => showPassword(e)}>
          {isTypePassword === true ? (
            <VisibilityOffIcon />
          ) : (
            <VisibilityIcon />
          )}
        </button>
      </div>
    </div>
  )
}