
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox } from '@mui/material';
import { useState } from 'react';


export default function FormDialog(props) {
  const { open, setOpen } = props;
  const { action, dialogTitle, dialogContentText, textField, checkBox, onSubmit, items, resetItems, color, type } = props.params;
  const [checked, setChecked] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='font-noto max-w-[400px]'>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (e) => onSubmit(e, items, resetItems, handleClose),
        }}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '450px',
            borderRadius: '12px',
            backgroundColor: '#f9f9fe',
          },
        }}
      >
        <DialogTitle sx={{ padding: '32px 32px 16px' }}>{dialogTitle}</DialogTitle>
        <DialogContent sx={{ padding: '32px', paddingBottom: '12px' }}>
          <DialogContentText>
            {dialogContentText}
          </DialogContentText>
          {items &&
            <div className='mt-4 flex flex-col items-start justify-between border-none w-full max-h-[150px] overflow-auto ring-1 ring-black ring-opacity-10 rounded-lg'>
              <div className='h-full w-full relative flex items-start justify-between '>
                <div className='min-h-full absolute w-3 bg-blue-400 '>
                  <span className='text-blue-400'>#</span>
                </div>
                <div className={`${!type && 'w-full'} px-5 py-3 ml-3 bg-blue-50 flex flex-col h-full space-y-2`}>
                  {type && type === 'text' ?
                    <span className='text-slate-700 text-nowrap'>{items}</span>
                    :
                    <>
                      {
                        items.map((item, index) => (
                          <span key={index} className='text-slate-700 text-nowrap truncate'>{index + 1}. {item.name}</span>
                        ))
                      }
                    </>
                  }

                </div>
              </div>
            </div>
          }
          {textField &&
            <TextField
              autoFocus
              required
              margin="dense"
              id="txtField"
              name={textField.name}
              label={textField.label}
              type="text"
              fullWidth
              variant="standard"
            />
          }
          {checkBox &&
            <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center justify-between border-none w-full">
              <span className="font-medium text-slate-600 text-lg mr-5">
                {checkBox.label}
              </span>

              <Checkbox
                id="chkBox"
                name={checkBox.name}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </div>
          }

        </DialogContent>
        <DialogActions sx={{ paddingBottom: '16px', paddingRight: '24px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color={`${color || 'error'}`} type="submit">{action}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
