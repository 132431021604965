import LogoPNG from "../assets/logo_pi.png";

export default function SelectedProductCard({ selectedProduct, imgSrc }) {
  return (
    <div
      className={`min-h-[350px] w-full flex justify-center relative filter overflow-hidden text-zinc-200  bg-zinc-800`}>
      <span className="absolute w-full flex justify-end text-sm  font-semibold mt-4 pr-6 z-10">
        Version: {selectedProduct.latestVersion || "N/A"}
      </span>

      <div className="min-h-[350px] h-full w-auto max-w-[1000px] 3xl:max-w-[1200px] flex flex-col cl:flex-row items-center py-3 px-14 gap-x-14">
        <div className="h-full min-w-[400px] w-full flex flex-col items-start justify-start py-8 gap-y-5">
          <div>
            <h1 className="text-5xl text-zinc-50 font-semibold truncate break-words max-w-[400px]">
              {selectedProduct && selectedProduct.name}
            </h1>
          </div>

          <div className="w-full max-h-[200px] overflow-y-auto">
            <h2 className="text-lg font-medium ">
              {selectedProduct && selectedProduct.description}
            </h2>
          </div>
        </div>

        <div className="w-fit min-h-fit h-auto relative flex items-center z-10  ">
          <div className="min-h-64 h-64 max-h-64 min-w-64 w-64 max-w-64 flex items-center justify-center bg-white rounded-3xl">
            <img
              className="h-full w-fit object-contain p-10"
              src={imgSrc.length > 0 ? imgSrc : LogoPNG}
              alt="productImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}