import { useUser } from "../../users/UserContext";
import { Link, useNavigate } from "react-router-dom";
import logoazul from "../../assets/Logo Azul.png";
import { useEffect, useState } from "react";
import UserPopup from "../PopUps/userPopup/UserPopup";
import globalRequest from "../../functions/backendRequests";
import UserProfileDropdown from "../dropdown/UserProfileDropdown";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const { user, setUser } = useUser();
  const [popUp, setPopUp] = useState(false);
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);


  let location = useLocation();

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  function logout() {
    if (user && user.name) {
      globalRequest("auth/logout", "", "POST", {}, {}, "", setUser)
        .then(() => {
          setUser(null);
          navigate("/");
        })
        .catch((err) => console.warn(err));
    } else {
      navigate("/login");
    }
  }

  const activePageClassName =
    "h-full w-full inline-block py-2 px-3 cl2:px-0 rounded text-blue-700 underline underline-offset-[4px]";
  const normalPageClassName =
    "h-full w-full inline-block py-2 px-3 cl2:px-0 text-slate-600 rounded rounded cl2:p-0 hover:text-blue-500";

  const userProfileItems = [
    {
      label: "Change Password",
      onClick: () => setPopUp(!popUp),
      icon: <VpnKeyIcon />,
    },
  ];

  const checkLocation = (path) => {
    return location.pathname.split("/")[1].includes(path) || (location.pathname.split("/")[1] === "" && path === "main");
  };


  const pages = [
    {
      label: "Home", to: "/main", activeclassname: "main", userHasAccessToPage: true, className: checkLocation("main")
        ? activePageClassName
        : normalPageClassName
    },
    {
      label: "Products", to: "/products", activeclassname: "products", userHasAccessToPage: user && user.name ? true : false, className: checkLocation("products")
        ? activePageClassName
        : normalPageClassName
    },
    {
      label: "Files", to: "/files", activeclassname: "files", userHasAccessToPage: user && user.name ? true : false, className: checkLocation("files")
        ? activePageClassName
        : normalPageClassName
    },
    {
      label: "Admin", to: "/admin/dashboard", activeclassname: "admin", userHasAccessToPage: user && user.admin ? true : false, className: checkLocation("admin")
        ? activePageClassName
        : normalPageClassName
    },
  ];

  return (
    <>
      {isNavOpen && <div className='fixed top-0 left-0 h-full w-full bg-black opacity-50 z-[100]' onClick={() => setIsNavOpen(!isNavOpen)} />}
      <nav className="bg-white-lilac-50 w-full sticky block z-[100] top-0 start-0 border-b-2">

        <div
          className={`max-w-full flex flex-wrap items-center justify-between mx-auto px-[53px] py-2`}>
          <a
            href="/main"
            className="flex items-center space-x-3 rtl:space-x-reverse hover:filter hover:brightness-110 transition duration-200 ease-in-out">
            <img
              src={logoazul}
              className="h-14"
              alt="Planeta Informática Logo"></img>
          </a>

          <div
            className={`
          ${isNavOpen
                ? "max-h-0 opacity-100"
                : "max-h-0 cl2:max-h-[48px] opacity-0 cl2:opacity-100 overflow-hidden"
              }
          items-end flex flex-col cl2:justify-between w-full cl2:h-auto cl2:items-center cl2:flex-row cl2:w-auto order-1 cl2-order-0 
          ${isNavOpen ? "transition-opacity duration-300" : ""}`}
            id="navbar-sticky">
            <ul className="w-48 cl2:w-fit flex flex-col p-4 cl2:p-0 mt-4 font-semibold text-[20px] border-2 bg-BackColor-100 rounded-lg bg-mischka-50 cl2:space-x-8 rtl:space-x-reverse cl2:flex-row cl2:items-center cl2:mt-0 cl2:border-0 cl2:bg-transparent gap-y-2 cl2:gap-y-0">
              <div className="cl2:hidden flex items-center justify-start">
                {user && user.name && (
                  <UserProfileDropdown
                    userName={user.name}
                    userEmail={user.email}
                    userRole={user.admin ? "Admin" : "User"}
                    items={userProfileItems}
                    logout={logout}
                    isNavOpen={isNavOpen}
                  />
                )}
              </div>
              {
                pages.map((page) => (
                  page.userHasAccessToPage &&
                  <li key={page.label}>
                    <Link
                      exact="true"
                      activeclassname={page.activeclassname}
                      to={page.to}
                      id={page.label}
                      className={page.className}>
                      {page.label}
                    </Link>
                  </li>
                ))
              }

            </ul>
          </div>

          <div className="flex space-x-3 cl2:space-x-8 rtl:space-x-reverse text-[17px] order-0 cl2:order-1">
            <div className="hidden cl2:flex items-center">
              {user && user.name && (
                <UserProfileDropdown
                  userName={user.name}
                  userEmail={user.email}
                  userRole={user.admin ? "Admin" : "User"}
                  items={userProfileItems}
                  logout={logout}
                />
              )}
              {(!user || !user.name) && (
                <button
                  onClick={logout}
                  type="button"
                  id="btnLogin"
                  className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg px-4 py-2 text-center bg-blue-600 hover:bg-blue-800 focus:ring-blue-900 transition-all ease-in-out duration-150">
                  Login
                </button>
              )}
            </div>
            <button
              onClick={toggleNavbar}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg cl2:hidden hover:ring-slate-700 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 hover:ring-2 focus:ring-slate-700 focus:bg-blue-950 focus:text-white">
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>
        {popUp && <UserPopup open={popUp} setPopUp={setPopUp} />}
      </nav>
    </>
  );
}
