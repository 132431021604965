import { Tooltip } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function CardWelcomeUser({ user, children }) {
  return (

    <div className="relative h-fit w-fit flex flex-col justify-center font-semibold text-wrap gap-y-2 px-10 py-5 rounded-3xl border-2 border-zinc-300 shadow-zinc-400 shadow-sm hover:shadow-md hover:shadow-zinc-400 hover:border-zinc-200 transition ease-in-out duration-200 bg-gradient-to-br from-transparent via-zinc-800 to-transparent ">
      <div className="h-full w-full inset-0 absolute bg-slate-700 rounded-3xl opacity-50 z-0"></div>

      <div className="w-full flex text-xl text-white font-medium z-10 gap-x-2">
        <span>Welcome to the admin dashboard,</span>
        <Tooltip title={user.name} placement="top" arrow>
          <span className="max-w-52 font-bold truncate">{user.name}</span>
        </Tooltip>
      </div>
      <div id="userProfile" className="h-full w-full flex items-center font-semibold gap-x-5 z-10">
        <div className="w-20 h-20 rounded-full bg-white-lilac-50 flex items-center justify-center">
          <AccountCircleIcon className="w-20 h-20 rounded-full text-blue-600" sx={{ fontSize: 100 }} />
        </div>
        <div className="h-full w-full flex justify-center flex-col gap-y-2">
          <h1 className="max-w-96 text-xl text-zinc-50 truncate">{user.name}</h1>
          <h2 className="max-w-96 text-md text-zinc-200 truncate">{user.email}</h2>
        </div>
      </div>
      {children} 
    </div>
  )
}