import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { useAlerts } from '../alerts/AlertContext';
import globalRequest from '../functions/backendRequests';
import vsamSDK from '../assets/vsam sdk.png'
import { useNavigate } from 'react-router-dom';
import CardIconText from '../components/card/CardIconText';
import CardImageText from '../components/card/CardImageText';
import CardTitleText from '../components/card/CardTitleText';
import { useState } from 'react';

const MAIN_PAGE_TITLE = "Welcome to Planeta Documents!";
const MAIN_PAGE_DESCRIPTION = "The official website for downloading product files";

const WELCOME_CARD_TITLE = "Discover Our Innovative Solutions";
const WELCOME_CARD_TEXT = "Explore our cutting-edge products designed to transform your business. From advanced technologies to custom-built solutions, we focus on meeting your unique needs. Stay ahead in your industry with tools that enhance efficiency, reliability, and performance. Let us help you navigate the future with confidence.";
const WELCOME_CARD_BUTTON_TEXT = "Start here";

const SUPPORT_CARD_TITLE = "Get Expert Support and Swift Resolutions";
const SUPPORT_CARD_TEXT = "Our dedicated support team is here to assist you with any challenges or questions. We provide fast, effective solutions to keep your operations running smoothly. Whether it's technical advice or troubleshooting, count on us to ensure your success. Reach out today and experience world-class service.";

export default function Main() {

  const { addAlert } = useAlerts();
  const navigate = useNavigate();

  const siteOptions = [
    { link: "/products", name: "Manuals", icon: <MenuBookOutlinedIcon fontSize='large' /> },
    { link: "/products", name: "Products", icon: <FolderOpenOutlinedIcon fontSize='large' /> },
    { link: "/products", name: "Files", icon: <InsertDriveFileOutlinedIcon fontSize='large' /> },
  ];

  return (
    <div className="h-full w-full bg-white-lilac-50 font-semibold pb-10">
      <div className="h-fit w-full bg-clean bg-no-repeat bg-center bg-cover">
        <div className="h-full w-full flex flex-col items-center justify-evenly font-semibold text-wrap px-14 py-10">
          <div className="w-full flex flex-col items-center justify-center font-semibold text-wrap gap-y-2">
            <h1 className="text-5xl text-blue-500">{MAIN_PAGE_TITLE}</h1>
            <h2 className="text-xl text-white">{MAIN_PAGE_DESCRIPTION}</h2>
          </div>

          <div className='h-fit w-full max-w-[1500px] flex flex-wrap xl:flex-nowrap items-center justify-center mt-5 gap-4 p-4'>

            <CardImageText title={WELCOME_CARD_TITLE} image={vsamSDK} text={WELCOME_CARD_TEXT} buttonText={WELCOME_CARD_BUTTON_TEXT} onClickCard={() => { navigate('/products') }} />

            <CardTitleText title={SUPPORT_CARD_TITLE} text={SUPPORT_CARD_TEXT} />

          </div>
        </div>
      </div>
      <div className='w-full flex justify-center px-14 py-10'>
        <div className='w-full max-w-[1500px] flex items-center justify-center xl:justify-between px-4 flex-wrap gap-x-16 gap-y-4 '>
          {siteOptions.map((page, index) => (
            <CardIconText key={index} icon={page.icon} title={page.name} link={page.link} />
          ))
          }
        </div>
      </div>
    </div>
  );
}









