import { useEffect, useState } from 'react';
import globalRequest from '../../functions/backendRequests';
import { useAlerts } from '../../alerts/AlertContext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MoreOptionsDropdown from '../dropdown/MoreOptionsDropdown';
import PublicIcon from '@mui/icons-material/Public';
import LanguageIcon from '@mui/icons-material/Language';
import { darkenColor } from '../../functions/helper';
import ButtonProductColor from '../buttons/ButtonProductColor';

export default function CardProducts({ ...props }) {
  const { addAlert, clearAlerts } = useAlerts();

  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imgSrc, setImgSrc] = useState('');
  const item = props && props.item;

  const productColorBGHover = props.productColor && darkenColor(props.productColor, 25);

  useEffect(() => {
    if (item.cardImage && item.cardImage.data) {
      try {
        const blob = new Blob([new Uint8Array(item.cardImage.data)], { type: "image/png" });
        const url = URL.createObjectURL(blob);
        setImgSrc(url);
      } catch (error) {
      }
    }
  }, [item.cardImage]);

  const selectItems = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter(e => e !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  }

  const selectAllItems = () => {
    if (selectedItems.length === availablePlatforms.availablePlatforms.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems([...availablePlatforms.availablePlatforms]);
    }
  }

  const dropdown = {
    availablePlatforms: availablePlatforms.availablePlatforms,
    selectItems: selectItems,
    selectAllItems: selectAllItems,
    selectedItems: selectedItems
  }

  const downloadAllLatestVersionsFromProduct = () => {

    if (selectedItems.length === 0) {
      setOpenDropdown(true);
      addAlert({ message: "Please select at least one platform", severity: 'error', timeout: 5 });
      return;
    }

    setLoading(true);
    globalRequest("files/download-all", "?productId=" + item.id + "&platforms=" + selectedItems, "GET", {}, null, "res", props.setUser).then(async (res) => {

      const contentDisposition = res.headers.get("Content-Disposition");
      const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "downloaded_file.pgp";

      const blob = await res.blob();

      if (blob.status && blob.status === 404) {
        blob.json().then(res => {
          addAlert({ message: res.error, severity: 'error', timeout: 5 })
        });
        return;
      }
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setLoading(false);
    }).catch(err => console.warn(err));
  }

  return (
    <div className="w-full flex justify-center font-noto">
      <div className={`w-[325px] h-[350px] ring-black ring-opacity-15 ring-1 px-6 pb-5 rounded-3xl flex flex-col group items-center hover:shadow-xl divide-slate-200 transition ease-in-out duration-75 relative  text-slate-700 bg-slate-100 z-10`}
      >
        <div className='absolute top-0 left-0 w-full h-[100px] bg-slate-200 rounded-t-3xl filter brightness-90 group-hover:brightness-75 z-0 transition ease-in-out duration-150' style={{ backgroundColor: props.productColor }}></div>

        {props.item.link &&
          <LanguageIcon className="absolute top-7 left-6 cursor-pointer z-10" sx={{ fontSize: 25 }} style={{ color: 'white' }} />
        }
        {props.isAdmin &&
          <div id="moreOptions" className='absolute top-6 right-4 cursor-pointer w-auto flex items-center' onClick={(e) => e.stopPropagation()}>
            <MoreOptionsDropdown item={props.item} moreOptionsDropdown={props.moreOptionsDropdown} isAdmin={props.isAdmin} menuButtonDarkMode={true} />
          </div>
        }
        <div className='w-[120px] h-[120px] mt-9 flex flex-col items-center justify-center z-20 rounded-full bg-white'>
          {props.img ?
            <div className='w-[120px] h-[120px] flex items-center justify-center rounded-full z-10 relative'>
              <img
                src={imgSrc}
                className="relative max-h-full max-w-full z-20 h-auto w-auto p-5"
                alt='productImg'
              />
            </div>
            :
            <div className='w-[120px] h-[120px] flex items-center justify-center rounded-full z-10 relative'>
              <h1 className='font-semibold truncate'>{props.title}</h1>
            </div>
          }
        </div>
        <div className='w-full h-10 justify-between flex items-center  mb-4 text-nowrap' >
          <span className='w-full text-2xl mr-2 font-semibold truncate break-words' title={props.title}>{props.title}</span>
          {props.item.latestVersion && <span className='min-w-fit w-fit text-sm font-medium'>{`Ver: ${props.item.latestVersion}`}</span>}
        </div>
        <div className='flex flex-1 w-full max-h-full overflow-y-auto'>
          <span className='break-words text-wrap break-all'>{props.description}</span>
        </div>

        <div className='pt-3 px-2 flex justify-center items-center' onClick={() => props.viewProduct(item)}>
          <div>
            <ButtonProductColor item={props.item} buttonText={props.item.link ? "View Website" : "View Files"} bgColorHover={productColorBGHover} buttonFunction={props.buttonFunction} loading={loading} setLoading={setLoading} icon={props.item.link && <OpenInNewIcon />} />
          </div>
        </div>

      </div>
    </div >
  );
}

