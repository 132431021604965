import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function ListHeader(props) {
  return (
    <div className={`h-auto w-full rounded pb-5 px-5 flex items-center justify-between overflow-hidden space-x-5 text-base font-bold z-40`} >

      {props.fields &&

        props.fields.map((field, index) => (
          <div key={index} className={`${field.css} min-w-fit items-center hover:cursor-pointer group text-nowrap `} onClick={() => props.sortItems(field.name)}>
            <div className='flex items-center justify-start truncate'>
              <span>{field.headerLabel || field.name}</span>
              <div className="w-auto flex flex-col ">
                <div className="h-2">
                  <ArrowDropUpIcon className={`${props.sorting.active && props.sorting.direction === 'asc' && props.sorting.field === field.name && "text-blue-600"}`} />
                </div>
                <ArrowDropDownIcon className={`${props.sorting.active && props.sorting.direction !== 'asc' && props.sorting.field === field.name && "text-blue-600"}`} />
              </div>
            </div>
          </div>
        )
        )
      }

      <div id="moreOptions" className='w-auto text-transparent flex items-center p-1'>
        <MoreVertIcon />
      </div>

    </div>
  );
}