import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import globalRequest from "../../functions/backendRequests";
import { useAlerts } from "../../alerts/AlertContext";
import { useUser } from "../../users/UserContext";
import CardFile from "../../components/card/CardFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple, faLinux, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { formatFileSize } from "../../functions/helper";

export default function FileDownload(props) {
  const { setUser } = useUser();
  const { addAlert, clearAlerts } = useAlerts();
  const navigate = useNavigate();
  const fileLink = window.location.href.split('/')[4].split('?')[1] || "";
  const permaLink = {
    product: window.location.href.split('/')[4],
    platform: window.location.href.split('/')[5],
  }
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    if ((fileLink || permaLink) && !file) {

      globalRequest("files/getFile", fileLink ? `?id=${fileLink || permaLink}` : "", "GET", {}, null, "", setUser).then((res) => {
        if (!res.file) navigate("/files");

        res.file.createdAt = new Date(res.file.createdAt).toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
        res.file.sizeKB = formatFileSize(res.file.sizeKB);

        setFile(res.file);
      }).catch(err => console.warn(err));
    }
  });

  const platformIcons = {
    Windows: <FontAwesomeIcon icon={faWindows} size="lg" />,
    Mac: <FontAwesomeIcon icon={faApple} size="lg" />,
    Linux: <FontAwesomeIcon icon={faLinux} size="lg" />,
    Android: <FontAwesomeIcon icon={faAndroid} size="lg" />,
    iOS: <FontAwesomeIcon icon={faApple} size="lg" />,
    All: <FontAwesomeIcon icon={faLayerGroup} size="lg" />,
    Other: <FontAwesomeIcon icon={faLayerGroup} size="lg" />
  }

  if (!file) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-white-lilac-50">
        <h1>Loading...</h1>
      </div>
    );
  }

  const downloadFile = () => {
    if (!file) {
      addAlert({ message: 'File not selected!', severity: 'warning', timeout: 5 });
      return;
    }

    const selectedFilesIds = [file.id];

    setLoading(true);
    globalRequest("files/download", `?ids=${selectedFilesIds}`, "GET", {}, null, "res", setUser)
      .then(async (res) => {
        if (!res.ok) {
          addAlert({ message: res.error, severity: 'error', timeout: 5 })
        }
        const contentDisposition = res.headers.get("Content-Disposition");
        const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
        const fileName = fileNameMatch ? fileNameMatch[1] : "downloaded_file.pgp";

        const blob = await res.blob();

        const downloadUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
        setLoading(false);
      }).catch(err => console.warn(err));


  }

  const fileData = [
    {
      name: "Product",
      value: file.products.name || "N/A",
    },
    {
      name: "File",
      value: file.path,
    },
    {
      name: "Created At",
      value: file.createdAt,
    },
  ]

  return (
    <div className="h-full w-full pt-5 pb-[30px] flex flex-col items-center bg-white-lilac-50 border-none font-noto">
      <div className="h-auto w-[97%] py-3 px-14 mb-10 flex justify-between items-center rounded-3xl ring-1 ring-slate-300 flex-wrap gap-y-4 gap-x-10">

        {fileData.map((data, index) => (
          <div key={index} className="flex items-center gap-x-2 text-slate-600 text-lg font-semibold">
            <span className="font-bold">{data.name}: </span>
            <span>{data.value}</span>
          </div>
        ))}

        <div className="flex items-center text-slate-600 cursor-pointer hover:text-blue-700 transition ease-in-out duration-150 gap-2" onClick={downloadFile}>
          {loading &&
            <div className="flex items-center gap-1 font-medium text-slate-700">
              <AutorenewIcon className="w-6 h-6 animate-spin" />
              Loading...
            </div>
          }
          <span className="text-lg font-bold">Download File </span>
          <FileDownloadIcon />
        </div>

      </div>
      <CardFile
        item={file}
        title={file.products.name}
        type={"Platform"}
        buttonLabel="Download"
        buttonFunction={downloadFile}
        buttonSendStateLink={true}
        fileOptions={true}
        productColor={file.products.color}
        loading={loading}
        icons={platformIcons}
      />
    </div>
  );
}


