import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import VersionRow from "./VersionRow";
import { Checkbox } from "@mui/material";
import globalRequest from "../../../functions/backendRequests";
import { useUser } from "../../../users/UserContext";
import { useAlerts } from "../../../alerts/AlertContext";

export default function ProductRow(props) {
  const { addAlert, clearAlerts } = useAlerts();

  const { setUser } = useUser();

  const { openNext, companyName, product, platform, permissions, applyPermissions, updatePermissions } = props;


  function compareArrays(arr1, arr2) {

    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort((a, b) => a.name.localeCompare(b.name));
    const sortedArr2 = arr2.slice().sort((a, b) => a.name.localeCompare(b.name));

    for (let i = 0; i < sortedArr1.length; i++) {
      const obj1 = sortedArr1[i];
      const obj2 = sortedArr2[i];

      if (obj1.name !== obj2.name) {
        return false;
      }

      const sortedVersions1 = obj1.versions.slice().sort();
      const sortedVersions2 = obj2.versions.slice().sort();

      if (JSON.stringify(sortedVersions1) !== JSON.stringify(sortedVersions2)) {
        return false;
      }
    }

    return true;
  }

  const [isOpen, setIsOpen] = useState(openNext);

  const p = product.platform;


  const bulkPermissions = (company, product, toInput) => {
    globalRequest(
      'companies/setBulkPermissions',
      '',
      'POST',
      { "Content-Type": "application/json" },
      JSON.stringify({
        companyName: company,
        productName: product,
        toInput
      }), "",
      setUser
    ).then((res) => {
      updatePermissions();
      addAlert({ message: res.message, severity: 'success', timeout: 5 });
      //update page with the new permissions
    });
  };


  const addAllFiles = () => {
    bulkPermissions(companyName, product.name, true);
  };

  const removeAllFiles = () => {
    bulkPermissions(companyName, product.name, false);
  };

  return (
    <div className="w-full odd:bg-white-lilac-50 odd:hover:bg-[#e4e4f5] even:bg-[#eaeaf7] even:hover:bg-[#dadaf4]">
      {product.name !== null &&
        <div className="w-full h-16 flex items-center text-center border-b border-slate-300">

          <div className="min-w-[336px] w-64 h-full flex items-center border-r border-slate-300 group cursor-pointer" onClick={() => setIsOpen(!isOpen)}>

            <span className="w-full text-start pl-24 group-hover:text-blue-500" >
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              {product.name}
            </span>

          </div>

          <div className="w-full flex items-center justify-start gap-6 px-6">


            <span className="text-blue-500 hover:text-blue-600 hover:underline cursor-pointer" onClick={addAllFiles}>
              Select all files
            </span>

            <span className="text-blue-500 hover:text-blue-600 hover:underline cursor-pointer" onClick={removeAllFiles}>
              Remove all files
            </span>
          </div>
        </div>
      }
      {isOpen && (
        <div className="w-full">
          <div className="w-full">
            {Object.entries(p).length > 0 && !platform &&
              p.map((p) => (
                <VersionRow key={p.name} platform={p} companyName={companyName} productName={product.name} permissions={permissions} applyPermissions={applyPermissions} updatePermissions={updatePermissions} />
              ))}
            {Object.entries(p).length > 0 && platform &&
              p.map((p) => (
                p.name === platform &&
                <VersionRow key={p.name} platform={p} companyName={companyName} productName={product.name} permissions={permissions} applyPermissions={applyPermissions} updatePermissions={updatePermissions} />
              ))}
            {Object.entries(p).length < 1 &&
              <div className="w-full h-16 bg-white flex items-center justify-center border-b border-slate-300">
                <span>No versions</span>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
}
