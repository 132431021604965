import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/logo_pi.png'
import { Box, Modal } from '@mui/material';

export default function FileDetailsModal(props) {
  function ClosePopup() {
    props.setPopUp(false)
  }

  const file = props.item && [
    { label: "Name", data: props.item.name },
    { label: "Platform", data: props.item.platform },
    { label: "Release Notes", data: props.item.releaseNotes },
    { label: "Size", data: props.item.sizeKB },
    { label: "Version", data: props.item.version },
    { label: "Product name", data: props.item.products.name },
    { label: "Release date", data: props.item.createdAt },
  ]

  return (
    <Modal
      open={props.open}
      onClose={ClosePopup}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >

      <Box className='absolute h-[70%] max-h-[660px] w-[75%] max-w-[1050px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white-lilac-50 rounded-lg outline-none font-medium'>
        <div className="flex flex-col w-full h-full bg-white-lilac-50 z-40 divide-y divide-slate-900 overflow-hidden rounded-lg">
          <div className="w-full h-[55px] pl-4 border-l border=black flex justify-center items-center z-10 relative">
            <span>File Details</span>
            <button className='absolute end-0 h-full w-16 hover:bg-red-500 hover:text-slate-100 transition duration-200 ease-in-out' onClick={ClosePopup}>
              <CloseIcon />
            </button>
          </div>

          <div className='overflow-auto h-full flex flex-col items-center px-5 py-5 z-0'>

            {file &&
              file.map((item, index) => (
                <div key={index} className='w-full flex justify-between items-center text-slate-700 flex-wrap even:bg-blue-50 odd:bg-slate-200 transition ease-in-out duration-150 py-5 px-5 break-words'>
                  <span className='text-xl font-semibold'>{item.label}:</span>
                  <span className='max-h-52 w-full text-xl font-semibold overflow-y-auto'>{item.data}</span>
                </div>
              ))

            }
          </div>
        </div>
      </Box>
    </Modal>
  )
}