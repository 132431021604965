import { Box, CircularProgress, Typography } from "@mui/material";

export default function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress // uncomplete progress
        variant="determinate"
        value={100}
        sx={{
          color: "#e0e0e0",
          position: "absolute",
        }}
      />
      <CircularProgress
        variant="determinate"
        sx={{ color: "text.primary" }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
