import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import ListIcon from "@mui/icons-material/List";
import WindowIcon from "@mui/icons-material/Window";
import CloseIcon from "@mui/icons-material/Close";
import List from "../../components/list/List";
import FileDetailsModal from "../../components/list/FileDetailsModal";
import ShareIcon from "@mui/icons-material/Share";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BarChartIcon from "@mui/icons-material/BarChart";
import InfoIcon from "@mui/icons-material/Info";
import { useUser } from "../../users/UserContext";
import globalRequest from "../../functions/backendRequests";
import { useAlerts } from "../../alerts/AlertContext";
import FormDialog from "../../components/dialogs/FormDialog";
import CardFile from "../../components/card/CardFile";
import { Checkbox, Tooltip } from "@mui/material";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAndroid,
  faApple,
  faLinux,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import { faFilePdf, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import ProductSideBar from "../../components/sidebar/ProductSideBar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PageHandler from "../../components/pagination/PageHandler";
import GridCards from "../../components/grid/GridCards";
import Search from "../../components/search/Search";
import { formatFileSize } from "../../functions/helper";
import SelectedItems from "../../selected/SelectedItems";
import SelectedProductCard from "../../selected/SelectedProductCard";

export default function Files() {
  const { addAlert, clearAlerts } = useAlerts();

  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const openModal = (item) => {
    setSelectedItem(item);
    setPopUp(!popUp);
  };
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("fileViewMode") || "card"
  );

  const location = useLocation();
  const [selectedProduct, setSelectedProduct] = useState(location.state ? location.state.product : "");
  const [products, setProducts] = useState(null);
  const [imgSrc, setImgSrc] = useState([]);

  const [fileList, setFileList] = useState(null);
  const [versionList, setVersionList] = useState(null);
  const [platformList, setPlatformList] = useState(null);

  const [platform, setPlatform] = useState(
    localStorage.getItem("fileFilterPlatform") || ""
  );
  const [version, setVersion] = useState(
    localStorage.getItem("fileFilterVersion") || ""
  );
  const [pageSize, setPageSize] = useState(6);
  const [activeFilters, setActiveFilters] = useState(false);

  const [filteringByNewFiles, setFilteringByNewFiles] = useState(
    localStorage.getItem("fileFilterByNewFiles") === "true" || false
  );
  const [showOldFiles, setShowOldFiles] = useState(
    localStorage.getItem("fileShowOldFiles") === "true" || false
  );
  const [searchValue, setSearchValue] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogParams, setDialogParams] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [allFilesSelected, setAllFilesSelected] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([
    { label: "Home", link: "/" },
    { label: "Products", link: "/products" },
    { label: "Files", link: "/files" },
  ]);
  const [loading, setLoading] = useState(false);
  const [filesBeingDownloaded, setFilesBeingDownloaded] = useState([]);
  useEffect(() => {
    if (products) return;

    globalRequest("products/checkproducts", "", "GET", {}, null, "", setUser)
      .then((res) => {
        res &&
          res.sort((a, b) =>
            a.name[0].toUpperCase() + a.name.slice(1).toLowerCase() >
              b.name[0].toUpperCase() + b.name.slice(1).toLowerCase()
              ? 1
              : -1
          );
        setProducts(res);
      })
      .catch((err) => console.warn(err));
  }, [products]);

  useEffect(() => {
    if (selectedProduct) {
      setActiveFilters(!activeFilters);
    }
  }, [selectedProduct]);

  const [percentage, setPercentage] = useState(null);

  const setNewPercentage = (pct) => {
    if (pct && pct !== percentage) setPercentage(pct);
  };

  const platformIcons = {
    Windows: <FontAwesomeIcon icon={faWindows} size="lg" />,
    Mac: <FontAwesomeIcon icon={faApple} size="lg" className="mb-1" />,
    Linux: <FontAwesomeIcon icon={faLinux} size="lg" />,
    Android: <FontAwesomeIcon icon={faAndroid} size="lg" />,
    iOS: <FontAwesomeIcon icon={faApple} size="lg" className="mb-1" />,
    All: <FontAwesomeIcon icon={faLayerGroup} size="lg" />,
    Other: <FontAwesomeIcon icon={faLayerGroup} size="lg" />,
    PDF: <FontAwesomeIcon icon={faFilePdf} size="lg" />,
  };

  const openPopUp = (func, fileLink) => {
    setDialogParams({
      dialogTitle: func.dialogTitle,
      dialogContentText: func.dialogContentText,
      textField: func.textField,
      checkBox: func.checkBox,
      action: func.name,
      onSubmit: func.func,
      items: fileLink,
      color: func.color,
      type: func.type,
    });
    setDialogOpen(true);
  };

  const openPdf = async (item) => {

    const res = await globalRequest('files/pdfUrl', `?id=${item.id}`, 'GET', {}, null, 'res', setUser)
    if (res.status !== 200) {
      addAlert({ message: "File not found", severity: 'error', timeout: 5 });
      return;
    }
    const url = await res.json();

    window.open(url, '_blank');

  }

  const selectFile = (file) => {
    if (selectedFiles.includes(file.id)) {
      setSelectedFiles(selectedFiles.filter((item) => item !== file.id));
    } else {
      setSelectedFiles([...selectedFiles, file.id]);
    }
  };

  useEffect(() => {
    setAllFilesSelected(
      selectedFiles &&
      selectedFiles.length > 0 &&
      fileList &&
      selectedFiles.length === fileList.length
    );
  }, [selectedFiles, fileList]);

  const selectAllFiles = (checked) => {
    if (checked) {
      setAllFilesSelected(true);
      setSelectedFiles(fileList.map((item) => item.id));
    } else {
      setAllFilesSelected(false);
      setSelectedFiles([]);
    }
  };

  const clearSelectedFiles = () => {
    setAllFilesSelected(false);
    setSelectedFiles([]);
  };

  useEffect(() => {
    if (
      selectedProduct &&
      selectedProduct.cardImage &&
      selectedProduct.cardImage.data
    ) {
      try {
        const blob = new Blob(
          [new Uint8Array(selectedProduct.cardImage.data)],
          { type: "image/png" }
        );
        const url = URL.createObjectURL(blob);
        setImgSrc(url);
      } catch (error) { }
    }
  }, [selectedProduct]);

  const fileActions = (selectedFile) => {
    const fileIds =
      selectedFile.id !== undefined ? [selectedFile.id] : selectedFile;
    navigate("/admin/actions", {
      state: fileIds.map((id) => {
        return { key: "FileId", value: id, itemId: id };
      }),
    });
  };

  const resetFilters = () => {
    setFilteringByNewFiles(false);
    localStorage.removeItem("fileFilterByNewFiles");
    setPlatform("");
    localStorage.removeItem("fileFilterPlatform");
    setVersion("");
    localStorage.removeItem("fileFilterVersion");
    setSearchValue("");
    localStorage.removeItem("fileSearchValue");
  };

  const filterByNewFiles = (checked) => {
    setFilteringByNewFiles(checked);
    setActiveFilters(!activeFilters);
    localStorage.setItem("fileFilterByNewFiles", checked);
  };

  const addOldFiles = (checked) => {
    setShowOldFiles(checked);
    setActiveFilters(!activeFilters);
    localStorage.setItem("fileShowOldFiles", checked);
  };

  const unsecuredCopyToClipboard = (text, handleClose) => {
    console.error("Clipboard API failed, falling back to unsecured method");
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed"; // Avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        console.warn("Fallback: when on http, cant copy to clipboard");
      } else {
        throw new Error("Fallback: Unable to copy to clipboard");
      }
    } catch (err) {
      console.error("Fallback: Could not copy text", err);
      alert("Your browser does not support clipboard copying.");
    }

    document.body.removeChild(textArea);
    handleClose?.();
  };

  const copyLink = (e, fileLink, handleClose) => {
    e.preventDefault();
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(fileLink)
        .then(() => {
          addAlert({
            message: "Link copied to clipboard!",
            severity: "success",
            timeout: 5,
          });
          handleClose?.();
        })
        .catch((err) => {
          console.error(
            "Clipboard API failed, falling back to unsecured method",
            err
          );
          unsecuredCopyToClipboard(fileLink, handleClose);
        });
    } else {
      unsecuredCopyToClipboard(fileLink, handleClose);
    }
  };

  const func = {
    name: "Copy file link",
    dialogTitle: "Share file",
    dialogContentText: "Copy the file link below and share it with others",
    textField: false,
    checkBox: false,
    func: copyLink,
    color: "success",
    type: "text",
  };

  const shareFile = (selectedFile) => {
    if (!selectedFile) {
      addAlert({
        message: "File version not selected!",
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    globalRequest(
      "files/share/",
      `?id=${selectedFile.id}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        const fileLink =
          process.env.REACT_APP_FRONTEND_ADDRESS + "/files/download?" + res.id;

        openPopUp(func, fileLink);
      })
      .catch((err) => console.warn(err));
  };

  const setPlatformDropdown = (item) => {
    setPlatform(item);
    setActiveFilters(!activeFilters);
    localStorage.setItem("fileFilterPlatform", item);
  };

  const setVersionDropdown = (item) => {
    setVersion(item);
    setActiveFilters(!activeFilters);
    localStorage.setItem("fileFilterVersion", item);
  };

  const setViewCardIcon = (value) => {
    setViewMode(value);
    localStorage.setItem("fileViewMode", value);
  };

  const viewFileAsAdmin = (item) => {
    navigate("/admin/files", {
      state: { key: "id", value: item.id, itemId: item.id },
    });
  };

  const downloadFilesS3 = async (file, setLoadingParam) => {
    setLoadingParam && setLoadingParam(true);

    const files = file.id !== undefined ? [file.id] : file;
    const res = await globalRequest("files/get-signed-download-urls", `?ids=${files}`, "GET", {}, null, "res", setUser);

    const urls = await res.json();
    if (res.status === 200 && urls.signedUrls) {
      for (let i = 0; i < urls.signedUrls.length; i++) {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = urls.signedUrls[i].url;
          link.download = `arquivo${i}.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, i * 500); // Pequeno atraso para evitar bloqueio do navegador
      }
    } else {
      addAlert({ message: res.error || urls.message, severity: 'error', timeout: 5 });
    }

    setLoadingParam && setLoadingParam(false);
    setFilesBeingDownloaded([]);
    return { ok: true };
  }



  const moreOptionsDropdown = {
    title: "More options",
    items: [
      {
        name: "Share",
        icon: <ShareIcon />,
        function: shareFile,
        multiFile: false,
      },
      {
        name: "Download",
        icon: <FileDownloadIcon />,
        function: downloadFilesS3,
        multiFile: true,
      },
      {
        name: "Details",
        icon: <InfoIcon />,
        function: openModal,
        multiFile: false,
      },
    ],
    adminItems: [
      {
        name: "View File",
        icon: <InfoOutlinedIcon />,
        function: viewFileAsAdmin,
        adminOnly: true,
        multiFile: true,
      },
      {
        name: "Actions",
        icon: <BarChartIcon />,
        function: fileActions,
        adminOnly: true,
        multiFile: true,
      },
    ],
    pdfOnlyItems: [
      {
        name: "Open PDF",
        icon: <FontAwesomeIcon icon={faFilePdf} size="lg" />,
        function: openPdf,
        multiFile: false,
      },
    ],
  };

  const fields = [
    {
      name: "name",
      css: "w-fit lg:w-[350px] flex",
    },
    {
      name: "platform",
      css: "min-w-fit w-[10%] hidden xl:flex",
    },
    {
      headerLabel: "size",
      name: "sizeKB",
      css: "min-w-fit  w-[10%] hidden lg:flex",
    },
    {
      name: "version",
      css: "min-w-fit w-[10%] hidden xl:flex",
    },
  ];

  const fetchData = (
    page,
    pageSize,
    setData,
    setTotalPages,
    setFetchState,
    searchValue,
    sorted
  ) => {
    globalRequest(
      "files/paginationFiles",
      `?page=${page}&pageSize=${pageSize}&search=${searchValue}&platform=${platform}&version=${version}&productId=${selectedProduct && selectedProduct.id
      }&newFiles=${filteringByNewFiles}&olderVersions=${showOldFiles}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        res.data.map((file) => {
          file.createdAt = new Date(file.createdAt).toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          file.sizeKB = formatFileSize(file.sizeKB);
        });
        setData(res.data);
        setTotalPages(res.totalPages);
        setVersionList([
          ...new Set(
            res.data
              .map((item) => item.version)
              .sort()
              .reverse()
          ),
        ]);
        setPlatformList([
          ...new Set(res.data.map((item) => item.platform).sort()),
        ]);
        setFileList(res.data);
        if (res.status === 404) setFetchState("No data found");
        else setFetchState(res.state);
      })
      .catch((err) => console.warn(err));
  };



  const fileCardComponent = (files) => {
    return (
      <GridCards width="300px">
        {files.map(
          (file) =>
            (file.latestVersion || showOldFiles) && (
              <CardFile
                key={file.id}
                item={file}
                title={file.products.name}
                buttonLabel="Download"
                buttonFunction={downloadFilesS3}
                fileOptions={true}
                buttonSendStateLink={true}
                moreOptionsDropdown={moreOptionsDropdown}
                isAdmin={user.admin}
                productColor={file.products.color}
                selectFile={selectFile}
                selectedFiles={selectedFiles}
                icons={platformIcons}
                showDetails={openModal}
                filesBeingDownloaded={filesBeingDownloaded}
                openPdf={openPdf}
              />
            )
        )}
      </GridCards>
    );
  };

  const fileListComponent = (files) => {
    return (
      <div className={`h-full flex-grow pb-20`}>
        <List
          items={files}
          downloadFile={downloadFilesS3}
          fileActions={fileActions}
          shareFile={shareFile}
          openModal={openModal}
          onItemClick={selectFile}
          selectedItems={selectedFiles}
          showOldFiles={showOldFiles}
          fields={fields}
          moreOptionsDropdown={moreOptionsDropdown}
        />
      </div>
    );
  };

  if (!user || !user.name) {
    navigate("/login");
  }

  const filterCheckboxes = [
    {
      checked: filteringByNewFiles,
      onChange: (e) => filterByNewFiles(e.target.checked),
      label: "Recently uploaded",
    },
    {
      checked: showOldFiles,
      onChange: (e) => addOldFiles(e.target.checked),
      label: "Older versions",
    },
    {
      checked: allFilesSelected,
      onChange: (e) => selectAllFiles(e.target.checked),
      label: "Select all files",
    }
  ]

  return (
    <div className="h-full w-full flex flex-col items-center border-none font-noto relative overflow-y-auto">
      <div className="w-full flex flex-col pt-3 px-6 gap-y-3">
        <BreadCrumb breadCrumbs={breadCrumbs} />

        <div className="h-auto w-full px-14 py-3 flex justify-center md:justify-between items-center rounded-xl ring-1 ring-slate-300 flex-wrap gap-x-3 gap-y-2 z-30 text-slate-600 text-lg xl:text-xl 3xl:text-2xl font-semibold">
          <div className="flex items-center gap-x-2">
            <WindowIcon
              className={`cursor-pointer hover:text-blue-700 ${viewMode === "card" && "text-blue-700"
                } transition-[scale] ease-in-out duration-300 scale-90 hover:scale-100`}
              onClick={() => setViewCardIcon("card")}
            />
            <ListIcon
              fontSize="large"
              className={`cursor-pointer hover:text-blue-700 ${viewMode === "list" && "text-blue-700"
                } transition-[scale] ease-in-out duration-300 hover:scale-110`}
              onClick={() => setViewCardIcon("list")}
            />
          </div>

          <div className="flex flex-wrap items-center justify-center gap-x-3">
            <div className="border-none flex justify-center hover:text-blue-700">
              <DropdownMenu
                items={platformList}
                setSelected={setPlatformDropdown}
                selected={platform}
                title={"Platform"}
                background={false}
                icons={platformIcons}
                size={"w-full"}
                itemsSize={"min-w-[200px]"}
              />
            </div>
            <div className="border-none flex justify-center hover:text-blue-700">
              <DropdownMenu
                items={versionList}
                setSelected={setVersionDropdown}
                selected={version}
                title={"Version"}
                background={false}
              />
            </div>

            {filterCheckboxes.map((checkbox, index) => (
              <label
                key={index}
                className="border-none flex justify-center items-center hover:text-blue-700 cursor-pointer"
              >
                <Checkbox
                  checked={checkbox.checked}
                  onChange={checkbox.onChange}
                />
                <span>{checkbox.label}</span>
              </label>
            ))
            }
          </div>

          <span
            className={`cursor-pointer hover:underline text-blue-700 text-nowrap flex font-semibold`}
            onClick={resetFilters}>
            Reset Filters
          </span>

          <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>
      </div>

      <div className={`h-full w-full justify-center relative`}>
        <SelectedItems {...{ selectedItems: selectedFiles, clearSelectedItems: clearSelectedFiles, fileActions, download: downloadFilesS3, setLoading, user }} />

        <div
          className={`h-full w-full flex flex-col  ${selectedFiles.length > 0 ? "pt-24" : "pt-5"
            } transition-[padding-top] ease-in-out duration-100 gap-y-5`}>

          {selectedProduct && (
            <SelectedProductCard
              selectedProduct={selectedProduct}
              imgSrc={imgSrc}
            />
          )}

          {viewMode === "card" ? (
            <PageHandler
              fetchData={fetchData}
              component={fileCardComponent}
              pageSize={pageSize}
              filters={activeFilters}
              searchValue={searchValue}
            />
          ) : (
            <PageHandler
              fetchData={fetchData}
              component={fileListComponent}
              pageSize={pageSize}
              filters={activeFilters}
              searchValue={searchValue}
            />
          )}
        </div>
      </div>



      <FileDetailsModal item={selectedItem} open={popUp} setPopUp={setPopUp} />

      {dialogOpen && (
        <FormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          params={dialogParams}
        />
      )}

      <div className="fixed top-1/2 left-0 z-50">
        <ProductSideBar
          items={products}
          changeProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
        />
      </div>
    </div>
  );
}