import { useState } from "react";
import Table from "./Table";
import PageHeader from "../header/PageHeader";
import ActionPopUp from "../PopUps/AdminPopUp/ActionPopUp";
import FormDialog from "../dialogs/FormDialog";
import TableFiltersDropdownMenu from "../dropdown/TableFiltersDropdownMenu";
import SearchIcon from '@mui/icons-material/Search';
import { useAlerts } from "../../alerts/AlertContext";

export default function PageTable(props) {
  const fields = props.fields
  const { addAlert } = useAlerts();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogParams, setDialogParams] = useState(null);

  const [popUp, setPopUp] = useState(false);
  const [popType, setPopType] = useState();
  const [parameters, setParameters] = useState(null);


  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { sorted, setSorted, searchValue, setSearchValue } = props

  const openPopUp = (func, items) => {
    if (func.type === 'modal') {
      setParameters({ selectedProducts: selectedItems, func: func });
      setPopUp(true);
    } else {
      setDialogParams({ dialogTitle: func.dialogTitle, dialogContentText: func.dialogContentText, textField: func.textField, checkBox: func.checkBox, action: func.name, onSubmit: func.func, items: items, resetItems: resetItems, color: func.color });
      setDialogOpen(true);
    }

  };

  const resetItems = () => {
    setSelectedItems([]);
    setSelectAllChecked(false);
  }

  const resetFilters = async () => {
    props.setFilters([]);
    props.setSorted({ enabled: false, ascending: true, field: '' });
  }

  const searchFilter = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  }

  const selectAllItems = (value) => {
    if (value) {
      setSelectedItems(fields)
    } else {
      setSelectedItems([])
    }
  };

  const selectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const sortItem = (field) => {
    if (sorted.field === field) {
      setSorted({ enabled: true, ascending: !sorted.ascending, field: field });
    } else {
      setSorted({ enabled: true, ascending: true, field: field });
    }
  }


  return (
    < >
      {props.pageHeader &&
        <PageHeader>
          <div className="flex flex-wrap cl2:flex-nowrap items-center justify-center gap-2">
            {
              props.table.functions && props.table.functions.map((func, index) => (
                <div key={index} className="flex items-center ">
                  <button
                    key={index}
                    onClick={() => selectedItems.length > 0 ? openPopUp(func, selectedItems) : addAlert({ message: 'Please select at least one item.', severity: 'warning', timeout: 5 })}
                    // disabled={selectedItems.length === 0}
                    className="min-w-[160px] w-auto max-w-[300px] truncate h-12 border-black border-2 text-black text-lg px-4 flex items-center justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-[50ms] hover:bg-blue-800 hover:border-blue-800 hover:text-slate-50 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed">
                    {func.name}
                  </button>
                </div>
              ))
            }
          </div>

          <span onClick={() => resetFilters()} className={`cursor-pointer hover:underline ${props.filters.length > 0 ? 'text-red-700' : 'text-blue-700'}  text-nowrap flex`}>Reset filters</span>
          <div className="flex items-center justify-center ">
            <TableFiltersDropdownMenu
              title={"Active filters"}
              items={props.filters}
              removeFilter={props.addOrRemoveFilter}
              background={true}
              size={"w-[200px]"}
              qtdFilters={props.filters.length}
            />
          </div>

          <div className="flex items-center justify-center relative">
            <input
              id="search"
              type="text"
              name="search"
              placeholder="Search"
              onChange={searchFilter}
              className="w-64 flex px-4 py-3 rounded-lg bg-white-lilac-50 font-medium text-lg placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200 hover:ring-slate-400 transition ease-in-out duration-200"
              required />
            <div className={`absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none ${document.querySelector('#search')?.value ? "hidden" : "block"}`}>
              <SearchIcon className="w-6 h-6 text-slate-400" />
            </div>
          </div>
          {props.modal && 
            <button className="min-w-[130px] truncate h-12 border-blue-800 border-2 text-blue-800 text-lg px-4 flex items-center justify-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-[50ms] hover:bg-blue-800 hover:border-blue-800 hover:text-slate-50 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => props.setSubPage("Create")}>
              {props.modal.title}
            </button>
          }
        </PageHeader>
      }

      <div className={`h-auto w-full shadow-md rounded-lg ring-1 ring-slate-300`}>
        <Table
          rows={fields}
          cols={props.table.cols}
          filter={props.addOrRemoveFilter}
          functions={props.table.functionsSingleItem}
          fetchData={props.table.fetchData}
          filters={props.table.filters}
          searchValue={searchValue}
          sorted={sorted}
          selectAllItems={selectAllItems}
          selectItem={selectItem}
          selectedItems={selectedItems}
          sortItem={sortItem}
          selectAllChecked={selectAllChecked}
          pagination={props.table.pagination}
        />
      </div>
      {popUp && <ActionPopUp parms={parameters} open={popUp} setPopUp={setPopUp} type={popType} dropdownMenus={props.dropdownMenus} />}

      {dialogOpen && <FormDialog open={dialogOpen} setOpen={setDialogOpen} params={dialogParams} />}

    </>

  )
}