import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useRef, useState } from "react";
import chroma from "chroma-js";

export default function ColorSelector(props) {
  const cRef = useRef(null);
  const [colorInput, setColorInput] = useState("");
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (props.colors && props.colors.length > 0) {
      props.colors.map((color) => (color.hex = adjustColor(color.hex)));
    }
    setColors(props.colors);
  }, [props.colors]);

  useEffect(() => {
    if (props.currentColor) {
      setColorInput(props.currentColor);
      let hex = adjustColor(props.currentColor);
      props.onChange(hex);
    }
  }, [props.currentColor]);

  useEffect(() => {
    if (props.selected && props.selected.color) {
      props.onChange(props.selected.color);
    }
  }, [props.selected]);

  const colorClick = () => {
    if (cRef.current) {
      cRef.current.click();
    }
  };

  const hexValidator = (hex) => {
    setColorInput(hex);
    if (hex.length === 6 || hex.length === 3) {
      if (/^(?:[a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(hex)) {
        hex = "#" + hex;
      }
    }
    if (hex.length === 7 || hex.length === 4) {
      if (/^#[a-fA-F0-9]{6}$|^#[a-fA-F0-9]{3}$/.test(hex)) {
        setColorInput(hex);
        props.onChange(hex);
      }
    }
  };

  function adjustColor(color, darkThreshold = 0.3) {
    const labColor = chroma(color).lab();

    const lightness = labColor[0] / 100;

    if (chroma.distance("#ffffff", color) < 30) {
      return chroma(color).luminance(0.6).hex();
    }

    if (lightness < darkThreshold) {
      return chroma(color).luminance(0.2).hex();
    }

    return color;
  }

  return (
    <div className="mt-10 flex flex-col sm:flex-row items-center justify-between border-none w-full">
      <span className="font-medium text-slate-600 text-lg mr-5">
        {"Select a color "}
        <Tooltip title="Color will be automatic adjusted if dark or light.">
          <InfoOutlinedIcon />
        </Tooltip>
      </span>
      <div>
        <div className="flex items-center justify-center">
          <input
            type="text"
            value={colorInput}
            className="w-full h-10 px-3 rounded-tl-lg text-black placeholder-slate-400 outline-none focus:ring-1 ring-1 ring-black"
            onChange={(e) => hexValidator(e.target.value)}
            pattern="#[0-9a-fA-F]{3}([0-9a-fA-F]{3})?"
            maxLength={7}
            minLength={7}
            placeholder="#000000"
          />
          <div
            className="h-10 w-12 ring-1 ring-black rounded-tr-lg cursor-pointer flex items-center justify-center text-lg"
            style={{
              background: props.currentColor ? props.currentColor : "#f0f0f0",
            }}
            onClick={colorClick}>
            +
          </div>
          <input
            type="color"
            className="size-0"
            ref={cRef}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        </div>
        <div className="w-full h-10 bg-white ring-1 ring-black rounded-b-lg flex items-center justify-around">
          {colors ? (
            colors.map((color, index) => (
              <button
                key={index}
                className={`size-5 rounded-sm`}
                style={{ background: color.hex }}
                onClick={() => props.onChange(color.hex)}
              />
            ))
          ) : (
            <span>Select a image</span>
          )}
        </div>
      </div>
    </div>
  );
}
