export default function ModalPage({ children, ...props }) {
  const { open } = props;
  const { onClose } = props;

  return (
    <>
      {open &&
        <div className="absolute top-0 left-0 w-full h-screen flex items-start justify-center pt-10 z-10">

          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-20 z-10"
            onClick={() => onClose && onClose()}
          ></div>

          <div className="relative w-full min-h-[500px] max-w-[700px] 3xl:max-w-[1000px] z-20 bg-white-lilac-50 rounded-lg py-8 flex flex-col items-center ring-black ring-opacity-10 ring-2 divide-y divide-slate-200 px-14 hover:shadow-xl transition ease-in-out duration-150 max-h-[90vh] overflow-y-auto">
            {children}
          </div>

        </div>
      }
    </>
  );
}
