
const darkenColor = (hex, percent) => {
  // Convert hex to RGB
  let num = parseInt(hex.slice(1), 16),
    amt = Math.round(2.55 * percent),  // Calculate the amount to darken/lighten
    R = (num >> 16) - amt,
    G = (num >> 8 & 0x00FF) - amt,
    B = (num & 0x0000FF) - amt;

  // Ensure RGB values stay within 0-255 range
  R = Math.max(0, Math.min(255, R));
  G = Math.max(0, Math.min(255, G));
  B = Math.max(0, Math.min(255, B));

  // Convert RGB back to hex
  return `#${(R << 16 | G << 8 | B).toString(16).padStart(6, '0')}`;
};

const formatFileSize = (kbytes) => {
  if (kbytes < 1024) {
    return kbytes.toFixed(2) + " KB";
  } else if (kbytes < 1048576) {
    return (kbytes / 1024).toFixed(2) + " MB";
  } else {
    return (kbytes / 1048576).toFixed(2) + " GB";
  }
}

const calcPageSizeInitialState = () => {
  const width = window.screen.width;

  if (width < 1024) {
    return 3;
  } else if (width < 1500) {
    return 6;
  } else if (width < 2500) {
    return 12;
  } else if (width < 2816) {
    return 18;
  } else {
    return 20;
  }
};

const formatSortedObjectForQuery = (sorted) => {
  if (sorted.enabled) {
    return `${sorted.field},${sorted.ascending ? "asc" : "desc"}`;
  }
  return "";
};

module.exports = { darkenColor, formatFileSize, calcPageSizeInitialState, formatSortedObjectForQuery };