import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import LogoPNG from '../../assets/logo_pi.png';

export default function ProductSideBar(props) {
  const [visibility, setVisibility] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (props.items) {
      const imageArrayS = [];
      props.items.forEach((value) => {
        if (value.cardImage && value.cardImage.data) {
          try {
            const blob = new Blob([new Uint8Array(value.cardImage.data)], { type: "image/png" });
            const url = URL.createObjectURL(blob);
            imageArrayS.push({ name: value.name, url: url });
          } catch (error) {
            console.error("Error creating image URL:", error);
          }
        } else {
          imageArrayS.push({ name: value.name, url: null });
        }
      });

      setImageArray(imageArrayS);
    }
  }, [props.items])

  return (
    <>
      <nav id="verticalNavbar" className={`h-full fixed top-0 ${visibility ? 'w-80' : 'w-0'} transition-[width] duration-250 ease-in-out flex flex-row bg-transparent z-50`}>
        <div className={`w-full h-full bg-[#f7fbff] ${visibility && 'border-r border-slate-300'} pt-[75px] overflow-y-auto`}>
          <div className='flex justify-center items-center h-12 w-full bg-[#f7fbff] border-b border-slate-300 font-roboto font-bold'>
            PRODUCTS
          </div>
          <div className='w-full px-3 py-3 h-16 border-b border-slate-300 z-[9999]'>
            <div className='w-full h-full flex items-center justify-center flex-row border border-slate-300 rounded-lg'>
              <SearchIcon className="w-6 h-6 mx-3 text-slate-400" />
              <input type="text" className="w-full h-full rounded-lg bg-[#f7fbff] outline-none" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
          </div>
          <ul className="h-fit w-full flex flex-col font-semibold text-lg truncate">
            {imageArray.map((item, index) => (
              item.name.toLowerCase().includes(search.toLowerCase()) && (
                <li key={index} className={`truncate w-full min-h-14 flex items-center justify-start px-4 py-2 bg-[#f7fbff] border-b border-slate-300 cursor-pointer hover:bg-blue-200 transition duration-200 ease-in-out ${props.selectedProduct && props.selectedProduct.name === item.name ? 'bg-blue-400' : ''}`}
                  onClick={() => { props.changeProduct(props.items.find(product => product.name === item.name)); setVisibility(!visibility) }}>
                  <div className="w-full flex items-center gap-x-5 truncate ">
                    <div className="w-12 h-12 flex items-center justify-center">
                      <img src={item.url || LogoPNG} alt={item.name} className="w-12 max-h-12" />
                    </div>
                    {<Tooltip title={item.name} placement="right-start" arrow>
                      <span className={`${visibility ? 'opacity-100 transition-opacity duration-300 ease-in-out delay-200 max-w-48 truncate' : 'opacity-0 pointer-events-none '}`}>
                        {item.name}
                      </span>
                    </Tooltip>
                    }
                  </div>
                </li>)
            ))}
          </ul>
        </div>
        {visibility &&
          <div>
            <button type="button" className="text-slate-700 h-12 w-6 bg-slate-300 hover:bg-slate-400 rounded-r-md hover:text-blue-900 text-sm relative top-[50%] right-0 inline-flex items-center" onClick={() => setVisibility(!visibility)}>
              <KeyboardArrowLeftIcon className="cursor-pointer hover:scale-110 transition duration-200 ease-in-out" fontSize="medium" />
            </button>
          </div>}
      </nav>
      {visibility && <div className='fixed top-0 left-0 h-full w-full bg-black opacity-50' onClick={() => setVisibility(!visibility)} />}
      {!visibility &&
        <div>
          <button type="button" className="text-slate-700 h-12 w-6 bg-slate-300 hover:bg-slate-400 rounded-r-md hover:text-blue-900 text-sm relative top-[50%] right-0 inline-flex items-center" onClick={() => setVisibility(!visibility)}>
            <KeyboardArrowRightIcon className="cursor-pointer hover:scale-110 transition duration-200 ease-in-out" fontSize="medium" />
          </button>
        </div>
      }
    </>
  );
}