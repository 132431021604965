import { useEffect, useState } from 'react';
import { useUser } from '../../users/UserContext';
import ListItem from './ListItem';
import ListHeader from './ListHeader';
import { compareVersions } from "compare-versions";


export default function List(props) {
  const { user } = useUser();
  const [selectedItem, setSelectedItem] = useState(null);
  const [sorting, setSorting] = useState({
    active: false,
    direction: "",
    field: null
  });

  useEffect(() => {
    setFilteredFields(props.items);
  }, [props.items]);

  const [filteredFields, setFilteredFields] = useState(props.items);

  const openMoreOptionsDropdown = (index) => {
    if (selectedItem === index) {
      setSelectedItem(null);
    } else {
      setSelectedItem(index);
    }
  };


  const sortItems = (key) => {
    let sortingDirection = "asc";

    if (sorting.active) {
      if (sorting.direction === "asc") {
        if (key === sorting.field) {
          setFilteredFields([...filteredFields].reverse());
          sortingDirection = "desc";
        }
      }
      else {
        if (key === sorting.field) {
          setFilteredFields([...filteredFields]);
          sortingDirection = "asc";
        }
      }
    }

    const sortedFilteredFields = [...filteredFields].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (key === 'version' || key === 'latestVersion') {
        return compareVersions(valueA || '0.0.0', valueB || '0.0.0');
      }

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {

        if (valueA.includes('/') && valueB.includes('/')) {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);
          return dateA - dateB;
        }

        return valueA.localeCompare(valueB);
      }

      return 0;
    });


    setFilteredFields(
      sortingDirection === "asc" ? sortedFilteredFields : sortedFilteredFields.reverse()
    );
    setSorting({ active: true, direction: sortingDirection, field: key });
  }


  return (
    <div className="w-full flex flex-col items-center font-noto px-10 pb-24">
      <ListHeader sorting={sorting} sortItems={sortItems} fields={props.fields} />
      {filteredFields && filteredFields.length > 0 ?
        filteredFields.map((item, index) => (
          <div key={index} className='w-full'>
            {(item.latestVersion || props.showOldFiles || props.type === 'product') &&
              <ListItem key={index} type={props.type} index={index} item={item} openMoreOptionsDropdown={openMoreOptionsDropdown} moreOptionsDropdown={props.moreOptionsDropdown} isAdmin={user.admin} productColor={(item.products && item.products.color) || item.color} onItemClick={props.onItemClick} selectedItems={props.selectedItems} fields={props.fields} />
            }
          </div>
        ))
        :
        <div className='w-full flex items-center justify-center text-slate-600 mt-10'>No items found</div>
      }


    </div>
  );
}

