import { useNavigate } from "react-router-dom"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';

export default function BreadCrumb(props) {
  const navigate = useNavigate();
  return (
    <div className="h-auto w-auto flex items-center justify-start gap-x-4 py-2 px-6 rounded-xl text-xl font-semibold text-mischka-800 self-start bg-mischka-100">
      {props.breadCrumbs.map((item, index) => (
        <div key={index} className="flex items-center gap-x-4">
          <span key={index} className={`flex items-center cursor-pointer ${index === props.breadCrumbs.length - 1 && "text-mischka-950"} hover:text-blue-600`} onClick={() => navigate(item.link)}> {index === 0 ? <HomeIcon /> : item.label} </span>
          {index < props.breadCrumbs.length - 1 && <NavigateNextIcon />}
        </div>
      ))

      }
    </div>
  )
}