import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ProductRow from "./ProductRow";
import { Checkbox } from "@mui/material";
export default function CompanyRow(props) {

  const [isOpen, setIsOpen] = useState(props.openAll);
  const [openNext, setOpenNext] = useState(props.openAll);

  const { item, product, permissions, selectedItems, selectItems, applyPermissions, updatePermissions, editCompany } = props;
  const pr = item.products.sort((a, b) => a.name.localeCompare(b.name));

  const setOpenAll = (value) => {
    setIsOpen(value);
    setOpenNext(value);
  }

  return (
    <>
      <div className="w-full h-16 flex items-center border-b border-slate-300 " >
        <div className="w-full h-full flex">
          <div className="h-full flex">
            <div className="w-20 px-1 py-2 border-r-2 border-slate-300 flex items-center justify-center">
              <Checkbox
                className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150"
                checked={selectedItems.includes(item.name)}
                onChange={() => selectItems(item.name)} />
            </div>
            <div id="company" className="min-w-64 w-64 px-7 h-full flex items-center justify-start border-r border-slate-300 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
              <span className="hover:text-blue-500 truncate">
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                {item.name}
              </span>
            </div>
          </div>
          <div className="w-full h-full flex justify-around px-10 space-x-10 text-blue-600 text-nowrap">
            <div className="h-full flex items-center justify-center">
              <span className="cursor-pointer hover:text-blue-800 hover:underline" onClick={() => editCompany(item.name)}>
                Edit Company
              </span>
            </div>
            <div className="h-full flex items-center justify-center">
              <span className="cursor-pointer hover:text-blue-800 hover:underline" onClick={() => setOpenAll(!isOpen)}>
                {isOpen ? "Hide All" : "Show All"}
              </span>
            </div>

          </div>
          <div className={`h-full min-w-6 w-6 flex items-center justify-center ${props.enabled ? 'bg-blue-600' : 'bg-yellow-600'}`}>
          </div>
        </div>
      </div>

      {isOpen && (
        <>
          <div className="w-full">
            <div className="w-full">
              {isOpen && pr.length > 0 && !product &&
                pr.map((pr) => (
                  <ProductRow key={pr.name} product={pr} companyName={item.name} openNext={openNext} permissions={permissions && permissions[item.name] && permissions[item.name].products && permissions[item.name].products[pr.name]?.platform} applyPermissions={applyPermissions} updatePermissions={updatePermissions} />
                ))}
              {pr.length > 0 && product &&
                pr.map((pr) => (
                  pr.name === product &&
                  <ProductRow key={pr.name} product={pr} companyName={item.name} openNext={openNext} permissions={permissions && permissions[item.name] && permissions[item.name].products && permissions[item.name].products[pr.name]?.platform} applyPermissions={applyPermissions} updatePermissions={updatePermissions} />
                ))}
              {pr.some((pr) => pr.name === null) &&
                <div className="w-full h-16 bg-white flex items-center justify-center border-b border-slate-300">
                  <span>No products</span>
                </div>
              }
            </div>
          </div>
        </>
      )}
    </>
  );
}
