import CloseIcon from "@mui/icons-material/Close";
import logo from "../../../assets/logo_pi.png";
import { Box, Modal } from "@mui/material";
import DropdownMenu from "../../dropdown/DropdownMenu";
import { useEffect, useState } from "react";
import { useUser } from "../../../users/UserContext";
import globalRequest from "../../../functions/backendRequests";
import ButtonSubmit from "../../buttons/ButtonSubmit";
import { useAlerts } from "../../../alerts/AlertContext";
import Turnstile from 'react-turnstile';
import { useRef } from "react";

export default function SupportModal(props) {
  const { addAlert, clearAlerts } = useAlerts();
  const [token, setToken] = useState(null);
  const [turnstileInstance, setTurnstileInstance] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUser();
  const [userData, setUserData] = useState(null);

  const handleTurnstileChange = (value) => {
    setToken(value);
  };

  function ClosePopup() {
    props.setPopUp(false);
  }

  function submitForm(e) {
    e.preventDefault();
    setLoading(true);

    if(!token){
      setLoading(false);
      addAlert({ message: "Please complete the CAPTCHA", severity: "warning", timeout: 5 });
      return;
    }

    const formData = new FormData(e.target);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));

    if (selected) {
      data["subject"] = selected;
    } else {
      data["subject"] = "Other";
    }

    if (userData) {
      data["email"] = userData.email;
      data["name"] = userData.name;
    }

    data["token"] = token;

    globalRequest(
      "users/support",
      "",
      "POST",
      { "Content-Type": "application/json" },
      JSON.stringify(data),
      "",
      setUser
    )
      .then((res) => {
        setToken("");
        setLoading(false);
        if (turnstileInstance && typeof turnstileInstance.reset === "function") {
          turnstileInstance.reset();
          setToken("");
        } else {
          console.warn("Turnstile instance is invalid, cannot reset.");
        }
        if (res && res.message)
          addAlert({ message: res.message, severity: "success", timeout: 5 });
        else {
          res
            .json()
            .then((res) =>
              addAlert({ message: res.message, severity: "error", timeout: 5 })
            );
        }
        e.target.reset();
        selected && setSelected(null);
      })
      .catch((err) => console.warn(err));
  }

  const setSelectedDropdown = (option) => {
    setSelected(option);
  };

  const options = ["Account", "Products", "Files", "Downloads", "Other"];

  useEffect(() => {
    if (user && userData === null) {
      globalRequest("users/userQuery", "", "GET", {}, null, "", setUser)
        .then((res) => {
          setUserData(res.users);
        })
        .catch((err) => console.warn(err));
    }
  }, [user, userData]);

  return (
    <Modal
      open={props.open}
      onClose={ClosePopup}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box className="absolute h-[85%] max-h-[660px] w-[50%] max-w-[950px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white-lilac-50 rounded-lg outline-none font-medium">
        <div className="flex flex-col w-full h-full bg-white-lilac-50 z-[9999] overflow-hidden rounded-lg">
          <div className="relative w-full min-h-[55px] h-[55px] max-h-[55px] border-2 border-gray-300 flex justify-center items-center z-10">
            <span>Contact Support</span>
            <button
              className="absolute right-0 z-0 h-full w-16 hover:bg-red-500 hover:text-slate-100 transition duration-200 ease-in-out"
              onClick={ClosePopup}>
              <CloseIcon />
            </button>
          </div>
          {user && user.name && userData && (
            <div className="h-16 w-full border-y-2 border-zinc-400 flex flex-col ">
              <div className="w-full h-full p-3 px-10 flex items-center justify-between gap-2">
                <div className="max-w-48 truncate">
                  <span>Name: {userData.name} </span>
                </div>
                <div className="max-w-64 truncate">
                  <span>Email: {userData.email}</span>
                </div>
                <div className="max-w-64 truncate">
                  <span>Company: {userData["company.name"]}</span>
                </div>
              </div>
            </div>
          )}

          <div className="overflow-auto [@media(min-height:630px)]:h-full [@media(min-height:630px)]:flex [@media(min-height:630px)]:flex-col [@media(min-height:630px)]:items-center px-16 pt-5">
            <div className="w-full flex flex-col md:flex-row justify-between items-center">
              <span className="truncate">Select a subject</span>
              <DropdownMenu
                items={options}
                setSelected={setSelectedDropdown}
                selected={selected}
                title={"Subject"}
                background={true}
              />
            </div>
            <form
              className="w-full flex-1 py-5 flex justify-start items-center gap-4 flex-col border-none  "
              autoComplete="off"
              onSubmit={submitForm}>
              <div className="w-full flex justify-center items-center">
                {(!user || !user.name) && (
                  <div className="w-full flex flex-col gap-4">
                    <div className="w-full">
                      <label >Your name</label>
                      <input
                        type="name"
                        name="name"
                        placeholder="User name"
                        className="w-full px-4 py-3 rounded-lg text-black font-medium placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200 transition duration-200 ease-in-out"
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label >Your email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="User email address"
                        className="w-full px-4 py-3 rounded-lg text-black font-medium placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200 transition duration-200 ease-in-out"
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full">
                <label htmlFor="txtAreaMessage">
                  How can we help you today?
                </label>
                <textarea
                  id="txtAreaMessage"
                  type="text"
                  name="txtAreaMessage"
                  placeholder="Type your message here..."
                  className="w-full min-h-[150px] max-h-[150px] px-4 py-3 rounded-lg text-black font-medium placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-slate-200 transition duration-200 ease-in-out"
                  required
                  autoComplete="new-password"
                />
              </div>
              <div className="w-full flex-1 flex justify-around items-center">
                <Turnstile 
                sitekey={'0x4AAAAAABBbc4eBtMSu7acU'} 
                theme="light"
                onVerify={handleTurnstileChange} 
                onLoad={(widgetId, instance) => {
                  setTurnstileInstance(instance);
                }}/>
                <ButtonSubmit loading={loading} value={"Submit Message"} />
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
}