export default function BoxSidebar(props) {
  return (
    <div className='mt-4 flex flex-col items-start justify-between border-none w-full min-h-[150px] max-h-[150px] overflow-hidden ring-1 ring-black ring-opacity-10 rounded-lg relative'>
      <div className={`w-full absolute min-h-[150px] flex items-start justify-between transition ease-in-out duration-300`} >
        <div className='w-[5px] min-h-[150px] filter brightness-100 group-hover:brightness-75 transition ease-in-out duration-300' style={props.bgColor ? { backgroundColor: props.bgColor } : { backgroundColor: '#656970' }}> </div>
        <div id='boxContent' className='w-full h-[150px] px-5 py-3 flex flex-col space-y-2 overflow-auto bg-white-lilac-50'>
          {props.type && props.type === 'Description' ?
            <>
              <span className='text-slate-700 font-bold'>{props.title}</span>
              <span className='text-slate-700 text-wrap break-words'>{props.text}</span>
            </>
            :
            props.items.map((item, index) => (
              <div>
                <span key={index} className='text-slate-700 text-nowrap truncate'>{index + 1}. {item.name}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}