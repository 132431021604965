import { useEffect, useState } from "react";
import ListIcon from '@mui/icons-material/List';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WindowIcon from '@mui/icons-material/Window';
import CardProducts from "../../components/card/CardProducts";
import globalRequest from "../../functions/backendRequests";
import { useUser } from "../../users/UserContext";
import List from "../../components/list/List";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import PageHandler from "../../components/pagination/PageHandler";
import GridCards from "../../components/grid/GridCards";
import Search from "../../components/search/Search";

export default function Products() {
  const { user, setUser } = useUser();
  const [viewMode, setViewMode] = useState(localStorage.getItem('productsViewMode') || "card");
  const [breadCrumbs, setBreadCrumbs] = useState([{ label: "Home", link: "/" }, { label: "Products", link: "/products" }]);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(6);

  const navigate = useNavigate();

  const setViewCardIcon = (value) => {
    setViewMode(value);
    localStorage.setItem('productsViewMode', value);
  }

  const fields = [
    {
      name: "name",
      css: "min-w-[200px] sm:min-w-[200px] w-[20%] sm:w-[25%] flex"
    },
    {
      name: "latestVersion",
      fakeName: "latest version",
      css: "min-w-[130px] w-[10%] max-w-[100px] hidden cl:flex"
    },
    {
      name: "description",
      css: "min-w-[200px] w-[25%] max-w-[200px] hidden xl:flex"
    },
    {
      name: "link",
      css: "min-w-[100px] w-[10%] max-w-[100px] hidden lg:flex"
    },

  ];

  const viewProduct = (item) => {
    if (item.link) {
      window.open(item.link, "_blank");
    } else {
      navigate('/files', { state: { product: item } });
    }
  }

  const viewProductAsAdmin = (item) => {
    navigate('/admin/products', { state: { key: "id", value: item.id, itemId: item.id } });
  }

  const moreOptionsDropdown = {
    title: "More options",

    adminItems: [
      { name: "View Product", icon: <InfoOutlinedIcon />, function: viewProductAsAdmin, adminOnly: true, multiFile: true },
    ],
  }

  const productCardComponent = (products) => {
    return (
      <GridCards>
        {products && products.length && products.map((product) => (
          <CardProducts
            key={product.id}
            item={product}
            title={product.name}
            type={"Product"}
            img={product.cardImage ? product.cardImage.data : null }
            description={product.description}
            buttonText={"View"}
            buttonLink={product.link || `/files`}
            productColor={product.color}
            setUser={setUser}
            isAdmin={user.admin}
            moreOptionsDropdown={moreOptionsDropdown}
            viewProduct={viewProduct}
          />
        ))}
      </GridCards>
    );
  }

  const listComponent = (products) => {
    return (
      <div className={`h-full w-full overflow-y-auto pb-16`}>
        <List
          items={products}
          fields={fields}
          type={"product"}
          moreOptionsDropdown={moreOptionsDropdown}
          onItemClick={viewProduct}
        />
      </div>
    );
  }

  const fetchProducts = (page, pageSize, setData, setTotalPages, setFetchState) => {
    globalRequest("products/paginationProducts", `?page=${page}&pageSize=${pageSize}&search=${searchValue}`, "GET", {}, null, "", setUser).then((res) => {
      setData(res.data);
      setTotalPages(res.totalPages);
      if (res.status === 404) setFetchState("No data found");
      else setFetchState(res.state);
    }).catch(err => console.warn(err));
  }

  return (
    <div className="min-h-fit h-auto w-full px-6 flex flex-col items-center justify-center bg-white-lilac-50 border-none font-noto overflow-y-auto ">
      <div className="w-full flex items-center py-3">
        <BreadCrumb breadCrumbs={breadCrumbs} />
      </div>
      <div className="h-auto w-full py-3 px-14 flex justify-center md:justify-between items-center rounded-xl ring-1 ring-slate-300 flex-wrap gap-5 md:space-y-0 z-20 text-slate-600 text-xl font-semibold">
        <div className="flex items-center gap-x-2">
          <WindowIcon className={`cursor-pointer hover:text-blue-700 ${viewMode === "card" && "text-blue-700"} transition-[scale] ease-in-out duration-300 scale-90 hover:scale-100`} onClick={() => setViewCardIcon("card")} />
          <ListIcon fontSize="large" className={`cursor-pointer hover:text-blue-700 ${viewMode === "list" && "text-blue-700"} transition-[scale] ease-in-out duration-300 hover:scale-110`} onClick={() => setViewCardIcon("list")} />
        </div>
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>
      <div className="h-full w-full flex flex-wrap gap-4 py-5 items-center justify-between">
        {
          viewMode === "card" ?
            <PageHandler
              fetchData={fetchProducts}
              component={productCardComponent}
              pageSize={pageSize}
              filters={searchValue}
            />
            :
            <PageHandler
              fetchData={fetchProducts}
              component={listComponent}
              pageSize={pageSize}
              filters={searchValue}
            />
        }
      </div>
    </div>
  );

}
