import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserProvider } from "./users/UserContext";
import ProtectedLayout from "./components/ProtectedLayout";
import UseAuth from "./users/UseAuth";

import Main from "./pages/Main";
import Login from "./pages/Login";

import Files from "./pages/User/Files";
import FileDownload from "./pages/User/FileDownload";
import Products from "./pages/User/Products";

import AdminFiles from "./pages/Admin/AdminFiles";
import AdminProducts from "./pages/Admin/AdminProducts";
import AdminCompanies from "./pages/Admin/AdminCompanies";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminUsers from "./pages/Admin/AdminUsers";
import Actions from "./pages/Admin/AdminActions";
import { AlertsProvider } from "./alerts/AlertContext";
import SelfRegister from "./pages/SelfRegister";

export default function App() {

  return (
    <AlertsProvider>
      <UserProvider>
        <UseAuth>
          <Routes>
            <Route exact path="*" element={<ProtectedLayout><Main /></ProtectedLayout>} />
            <Route exact path="/login" element={<ProtectedLayout><Login /></ProtectedLayout>} />
            <Route exact path="/register" element={<ProtectedLayout><SelfRegister /></ProtectedLayout>} />
            <Route exact path="/products" element={<ProtectedLayout><Products /></ProtectedLayout>} />
            <Route exact path="/files" element={<ProtectedLayout><Files /></ProtectedLayout>} />
            <Route exact path="/files/*" element={<ProtectedLayout><FileDownload /></ProtectedLayout>} />
            <Route exact path="/admin/files" element={<ProtectedLayout><AdminFiles /></ProtectedLayout>} />
            <Route exact path="/admin/products" element={<ProtectedLayout><AdminProducts /></ProtectedLayout>} />
            <Route exact path="/admin/companies" element={<ProtectedLayout><AdminCompanies /></ProtectedLayout>} />
            <Route exact path="/admin/users" element={<ProtectedLayout><AdminUsers /></ProtectedLayout>} />
            <Route exact path="/admin/actions" element={<ProtectedLayout><Actions /></ProtectedLayout>} />
            <Route exact path="/admin/*" element={<ProtectedLayout><AdminDashboard /></ProtectedLayout>} />
          </Routes>
        </UseAuth>
      </UserProvider>
    </AlertsProvider>
  )
}
