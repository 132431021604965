import { Link } from "react-router-dom";

export default function CardIconText({ ...props }) {

  return (
    <Link to={props.link} className="h-48 w-72 flex items-center justify-center bg-[#e4e4f5] rounded-2xl flex-col divide-y ring-1 ring-opacity-10 ring-black divide-slate-200 overflow-hidden hover:ring-2 hover:shadow-xl hover:bg-[#dadaf4] transition ease-in-out duration-150">
      <div className="flex flex-col items-center justify-center text-slate-700 font-semibold text-[22px] mt-4">
        <div className="text-blue-500 text-[40px]">
          {props.icon}
        </div>

        <h1 className='mt-2 max-w-[350px] pb-4 truncate' title={props.title}>{props.title}</h1>
      </div>
    </Link>
  );
}
