export default function CardTitleMultipleButtons({ title, buttons }) {
  return (
    <div className="relative h-fit w-fit flex flex-col justify-center font-semibold text-wrap gap-y-5  px-10 py-5 rounded-3xl border-2 border-zinc-300 shadow-zinc-400 shadow-sm hover:shadow-md hover:shadow-zinc-400 hover:border-zinc-200 transition ease-in-out duration-200 bg-gradient-to-br from-transparent via-zinc-800 to-transparent ">
      <div className="h-full w-full inset-0 absolute bg-slate-700 rounded-3xl opacity-50 z-0"></div>

      <h1 className="text-xl text-white font-medium z-10"> {title} </h1>
      <div className="h-full w-full grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-10 z-10">

        {buttons.map((item, index) => (
          <div key={index} className="h-full w-full flex items-center font-semibold gap-x-3 cursor-pointer rounded-2xl text-zinc-200 bg-zinc-800 px-9 py-3 hover:text-zinc-900 hover:bg-zinc-200 transition ease-in-out duration-200 truncate" onClick={() => item.onClick()} >
            {item.icon}
            <h2 className="text-md truncate">{item.name}</h2>
          </div>
        ))}

      </div>

    </div>
  );
}