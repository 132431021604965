import React, { useEffect, useState } from "react";
import { validateStrict } from "compare-versions";
import CardPage from "../../components/card/cardPage/CardPage";
import PageTable from "../../components/tables/PageTable";
import globalRequest from "../../functions/backendRequests";
import { useAlerts } from "../../alerts/AlertContext";
import { useUser } from "../../users/UserContext";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CardPageHeader from "../../components/card/cardPage/CardPageHeader";
import CardPageBody from "../../components/card/cardPage/CardPageBody";
import CardLabelItem from "../../components/card/cardPage/CardLabelItem";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import { Checkbox } from "@mui/material";
import Form from "../../components/forms/Form";
import UploadFile from "../../components/upload/UploadFile";
import {
  calcPageSizeInitialState,
  formatSortedObjectForQuery,
} from "../../functions/helper";
import FloatingProgressBar from "../../components/progressBar/FloatingProgressBar";
import ModalPage from "../../components/modal/ModalPage";

export default function AdminFiles() {
  const { setUser } = useUser();
  const { addAlert, clearAlerts } = useAlerts();
  const [products, setProducts] = useState(null);
  const [files, setFiles] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const [filters, setFilters] = useState(
    location.state ? [location.state] : []
  );
  const [file, setFile] = useState(null);
  const [sorted, setSorted] = useState({
    enabled: false,
    ascending: true,
    field: "",
  });
  const [fetchState, setFetchState] = useState("Loading...");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  const [pageSize, setPageSize] = useState(calcPageSizeInitialState);

  const [percentage, setPercentage] = useState(null);
  const [subPage, setSubPage] = useState("See all");


  useEffect(() => {
    updateItems();
  }, [page, pageSize, filters, searchValue, sorted]);

  useEffect(() => {
    setFile(null);
  }, [subPage]);

  const updateItems = () => {
    fetchData(
      page,
      pageSize,
      setTotalPages,
      setFetchState,
      searchValue,
      sorted.enabled && formatSortedObjectForQuery(sorted)
    );
  };

  const selectFileFromComputer = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".zip, .rar, .pdf";
    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFile(file);
      }
    };
    fileInput.click();
  };

  const createFileDataObject = (fd) => {
    const formData = new FormData(fd);
    const fileData = {};
    formData.forEach((value, key) => (fileData[key] = value));

    if (!validateStrict(fileData["version"])) {
      addAlert({
        message: "Please enter a valid version number! Like: 1.0.0/1.0.0-rc.1",
        severity: "error",
        timeout: 5,
      });
      return;
    }

    if (!selectedProduct && subPage === "Create") {
      addAlert({
        message: "Please select a product",
        severity: "warning",
        timeout: 5,
      });
      return;
    }
    if (!file && subPage === "Create") {
      addAlert({
        message: "Please select a file",
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    return fileData;
  }

  const uploadFileS3 = async (e) => {
    e.preventDefault();

    const fileData = createFileDataObject(e.target);

    if (!fileData) {
      return;
    }

    setLoading(true);

    const { upload, fileId } = await uploadFileMultipart();

    const res = await upload.json();
    if (upload.status === 200) {
      const addToDB = await addFileDB(fileId, setFileDataParams(fileData));

      const res = await addToDB.json();

      if (addToDB.status === 200) {
        updateItems();
        setFile(null);
        resetFields();
      }

      addAlert({
        message: res.message,
        severity: addToDB.status === 200 ? "success" : "error",
        timeout: 5,
      });
    } else {
      addAlert({
        message: res.error || res.message,
        severity: "error",
        timeout: 5,
      });
    }
    setLoading(false);
    setPercentage(null);
    setSubPage("See all");
  };

  const addFileDB = async (fileId, file) => {
    try {
      const addFileToDB = await globalRequest(
        "files/addFileDB",
        "",
        "POST",
        { "Content-Type": "application/json" },
        JSON.stringify({
          fileId: fileId,
          file: file,
        }),
        "res",
        setUser
      );

      return addFileToDB;
    } catch (error) {
      addAlert({
        message: "An error occurred while uploading the file",
        severity: "error",
        timeout: 5,
      });
      console.error("Error in addFileDB:", error);
      throw error;
    }
  }

  const uploadFileMultipart = async (id) => {
    try {
      const CHUNK_SIZE = 10 * 1024 * 1024;
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

      const startResponse = await globalRequest(
        "files/start-upload",
        "",
        "POST",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          id: id,
        }),
        "res",
        setUser
      );

      if (startResponse.status !== 200) {
        addAlert({
          message: "Failed to start upload",
          severity: "error",
          timeout: 5,
        });
        return;
      }
      const { uploadId, fileId } = await startResponse.json();

      const getUrlsResponse = await globalRequest(
        "files/get-upload-urls",
        `?fileId=${fileId}&uploadId=${uploadId}&totalChunks=${totalChunks}`,
        "GET",
        {},
        null,
        "res",
        setUser
      );

      if (getUrlsResponse.status !== 200) {
        addAlert({
          message: "Failed to get upload URLs",
          severity: "error",
          timeout: 5,
        });
        return;
      }

      const { urls } = await getUrlsResponse.json();
      const uploadedParts = [];
      const totalParts = urls.length;
      let finishedParts = 0;

      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const { partNumber, url } = urls[i];

        const uploadResponse = await fetch(url, {
          method: "PUT",
          headers: { "Content-Type": file.type },
          body: chunk,
        });

        if (uploadResponse.ok) {
          uploadedParts.push({
            PartNumber: partNumber,
            ETag: uploadResponse.headers.get("ETag").replace(/"/g, ""),
          });
          finishedParts++;
          setPercentage(((finishedParts / totalParts) * 100).toFixed(0));
        } else {
          addAlert({
            message: `Failed to upload part ${partNumber}`,
            severity: "error",
            timeout: 5,
          });
          return;
        }
      }

      const completeResponse = await globalRequest(
        "files/complete-upload",
        "",
        "POST",
        { "Content-Type": "application/json" },
        JSON.stringify({ fileId, uploadId, parts: uploadedParts }),
        "res",
        setUser
      );
      return { upload: completeResponse, fileId };
    } catch (error) {
      console.error("Error in uploadFileMultipart:", error);
      addAlert({
        message: error.message || "An error occurred while uploading the file",
        severity: "error",
        timeout: 5,
      });
    }
  };

  const deleteFile = async (fileIds) => {
    try {
      const res = await globalRequest(
        "files/deleteFile",
        "",
        "DELETE",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          fileIds: fileIds,
        }),
        "res",
        setUser
      );

      if (res.status !== 200) {
        addAlert({
          message: res.message || "Failed to delete files from s3",
          severity: "error",
          timeout: 5,
        });
      }

      return res;
    } catch (error) {
      console.error("Error in deleteFile:", error);
      addAlert({
        message: "An error occurred while deleting files",
        severity: "error",
        timeout: 5,
      });
      throw error;
    }
  };

  const deleteFilesFromDB = async (fileIds) => {
    try {
      const res = await globalRequest(
        "files/deleteFilesFromDB",
        "",
        "DELETE",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          ids: fileIds
        }),
        "res",
        setUser
      );

      if (res.status !== 200) {
        addAlert({
          message: res.message || "Failed to delete files from db",
          severity: "error",
          timeout: 5,
        });
      }

      return res;
    } catch (error) {
      console.error("Error in deleteFilesFromDB:", error);
      addAlert({
        message: "An error occurred while deleting files",
        severity: "error",
        timeout: 5,
      });
      throw error;
    }
  };

  const updateFileDB = async (fileId, file) => {
    try {
      const res = await globalRequest(
        "files/updateFileDB",
        "",
        "PUT",
        { "Content-Type": "application/json" },
        JSON.stringify({
          fileId: fileId,
          file: file,
        }),
        "res",
        setUser
      );

      return res;
    } catch (error) {
      console.error("Error in updateFileDB:", error);
      addAlert({
        message: "An error occurred while updating the file",
        severity: "error",
        timeout: 5,
      });
      throw error;
    }
  };

  const submitEditFile = async (e) => {
    e.preventDefault();

    const fileData = createFileDataObject(e.target);

    if (!fileData) {
      return;
    }

    setLoading(true);

    const deletedFile = file ? await deleteFile([selectedFile.id]) : { status: 200 };

    if (deletedFile.status === 200) {
      const { upload, fileId } = file ? await uploadFileMultipart(selectedFile.id) : { upload: { status: 200 }, fileId: selectedFile.id };


      if (upload.status === 200) {
        const updatedFileDB = await updateFileDB(fileId, setFileDataParams(fileData));
        const res = await updatedFileDB.json();

        if (updatedFileDB.status === 200) {
          updateItems();
          resetFields();
          setSubPage("See all");
        }

        addAlert({
          message: res.message,
          severity: updatedFileDB.status === 200 ? "success" : "error",
          timeout: 5,
        })
      } else {
        const uploadRes = await upload.json();
        addAlert({
          message: uploadRes.message,
          severity: "error",
          timeout: 5,
        });
      }
    } else {
      const response = await deleteFile.json();

      addAlert({
        message: response.message,
        severity: "error",
        timeout: 5,
      });
    }
    setLoading(false);
    setSubPage("See all");
  };



  const setFileDataParams = (fileData) => {
    const platformChosen = selectedPlatform || "All";

    if (!fileData["productId"] && !fileData["platform"] && subPage === "Create") {
      fileData["productId"] = products.find(
        (product) => product.name === selectedProduct
      ).id;
      fileData["platform"] = platformChosen;
    }

    fileData["addFileToPreviousOwners"] = checked;

    fileData["selectedCompanies"] = selectedItems.map((item) => item.id);
    fileData["sizeKB"] = (file && (file.size / 1024).toFixed(2)) || null;

    const newFileName = setNewFileName(fileData["version"]);
    fileData["name"] = newFileName;

    return fileData;
  };

  const setNewFileName = (version) => {
    if (subPage === 'Create') return `${selectedProduct}_${selectedPlatform}_${version}.${(files && file.name.split(".").pop()) || selectedFile.path.split(".").pop()}`;
    else {
      const newName = selectedFile.path.split("_").slice(0, -1).join("_");
      return `${newName}_${version}.${(file && file.name.split(".").pop()) || selectedFile.path.split(".").pop()}`
    }
  };

  const resetFields = () => {
    setFile(null);
    setSelectedFile(null);
    setSelectedItems([]);
    setSelectedProduct(null);
    setSelectedPlatform(null);
    setChecked(false);
  };

  useEffect(() => {
    if (products) return;

    globalRequest("products", "", "GET", {}, null, "", setUser)
      .then((res) => {
        setProducts(res);
      })
      .catch((err) => console.warn(err));
  });

  const platforms = ["Android", "iOS", "Linux", "Mac", "Other", "PDF", "Windows"];

  const dismissFile = () => {
    setFile(null);
  };

  const setCompaniesThatHaveProduct = (productName) => {
    globalRequest("companies/checkCompanies", "", "GET", {}, null, "", setUser)
      .then((res) => {
        setCompanies(res);
      })
      .catch((err) => console.warn(err));

    globalRequest("companies/companiesWithProduct", `?name=${productName}`, "GET", {}, null, "", setUser)
      .then((res) => {
        setSelectedItems(res);
      })
      .catch((err) => console.warn(err));
  };

  const setSelectedProductInfo = (productName) => {
    setSelectedProduct(productName);
    setCompaniesThatHaveProduct(productName);
  };

  const productNames =
    products && products.length > 0 && products?.map((product) => product.name);

  const fields = [
    {
      type: "text",
      id: "version",
      label: "Version",
      name: "version",
      placeholder: "Version ex: 1.0.0 or 1.0.0-rc.1",
      autocomplete: "off",
    },
  ];

  const uploadFile = {
    title: "Select a file to upload:",
    selectFileFromComputer: selectFileFromComputer,
    dismissFile: dismissFile,
    selectedFile: file,
  };

  const checkbox = {
    name: "addFileToPreviousOwners",
    id: "addFileToPreviousOwners",
    text: "Give access to previous version owners",
    checked: checked,
    onChange: setChecked,
  };

  const selectItems = (item) => {
    if (selectedItems.some((e) => e.id === item.id)) {
      setSelectedItems(selectedItems.filter((e) => e.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const selectAllItems = () => {
    if (selectedItems.length === companies.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems([...companies]);
    }
  };

  const selectPageOption = (option) => {
    setSubPage(option);
  };

  const pageOptions = ["See all", "Create"];

  const pageHeader = {
    title: "Files",
    options: pageOptions,
    selectPageOption: selectPageOption,
    subPage: subPage,
  };

  const deleteFiles = async (e, files, resetItems, handleClose) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const deleteConfirmation = formJson.deleteConfirmation;

    if (deleteConfirmation !== "delete") {
      addAlert({
        message: 'Please type "delete" to confirm action!',
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    const fileIds = files.map((file) => file.id)

    const deleteFromS3 = await globalRequest(
      "files/deleteFilesFromS3",
      "",
      "DELETE",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify({ ids: fileIds }),
      "res",
      setUser
    )

    const deleteFromS3Res = await deleteFromS3.json();

    if (deleteFromS3.status === 200) {
      // addAlert({ message: deleteFromS3Res.message, severity: "success", timeout: 5 });
      const res = await deleteFilesFromDB(fileIds);

      const resJSON = await res.json();
      if (res.status === 200) {
        addAlert({ message: resJSON.message, severity: "success", timeout: 5 });
        updateItems();
        resetFields();
        setSelectedItems([]);
        resetItems();
      } else {
        addAlert({ message: resJSON.message, severity: "error", timeout: 5 });
      }
    } else {
      addAlert({ message: deleteFromS3Res.message, severity: "error", timeout: 5 });
    }

    handleClose();
  };

  const functions = [
    {
      name: "Delete File",
      func: deleteFiles,
      dialogTitle: "Delete files",
      dialogContentText: "Are you sure you want to delete these files?",
      textField: {
        name: "deleteConfirmation",
        label: 'Confirm delete by typing "delete"',
      },
    },
  ];

  const editFile = (file) => {
    globalRequest(
      "companies/checkCompanies",
      "",
      "GET",
      {},
      null,
      "",
      setUser
    ).then((res) => {
      setCompanies(res);
    });
    globalRequest(
      "companies/companiesWithFile",
      `?id=${file.id}`,
      "GET",
      {},
      null,
      "",
      setUser
    ).then((res) => {
      setSelectedFile(file);
      setSelectedItems(res);
      setSubPage("Edit");
    });
  };



  const formatFiltersForQuery = (filters) => {
    return filters.map((filter) => `${filter.key}=${filter.itemId}`).join("&");
  };

  const fetchData = (
    page,
    pageSize,
    setTotalPages,
    setFetchState,
    searchValue,
    sorted
  ) => {
    globalRequest(
      "files/adminTableFiles",
      `?page=${page}&pageSize=${pageSize}&filters=${formatFiltersForQuery(
        filters
      )}&search=${searchValue || ""}&sort=${sorted || ""}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        res.data.forEach((file) => {
          file.createdAt = new Date(file.createdAt).toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          file.updatedAt = new Date(file.updatedAt).toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          file.sizeKB = formatFileSize(file.sizeKB);
        });

        setFiles(res.data);
        setTotalPages(res.totalPages);
        if (res.status === 404) setFetchState("No data found");
        else setFetchState(res.state);
      })
      .catch((err) => console.warn(err));
  };

  const formatFileSize = (kbytes) => {
    if (kbytes < 1024) {
      return kbytes.toFixed(2) + " KB";
    } else if (kbytes < 1048576) {
      return (kbytes / 1024).toFixed(2) + " MB";
    } else {
      return (kbytes / 1048576).toFixed(2) + " GB";
    }
  };

  const dropdownMenus = [
    {
      spanTitle: "Select a product:",
      title: "Product",
      items: productNames,
      setSelected: setSelectedProductInfo,
      selected: selectedProduct,
      background: true,
    },

    {
      spanTitle: "Select a platform:",
      title: "Platform",
      items: platforms,
      setSelected: setSelectedPlatform,
      selected: selectedPlatform,
      background: true,
    },

    {
      spanTitle: "Select companies that can access this file:",
      title: "Companies",
      items: companies,
      selected: selectedCompany,
      background: true,
      checkbox: true,
      selectItem: selectItems,
      selectAllItems: selectAllItems,
      selectedItems: selectedItems,
    },
  ];

  const dropdownMenusEdit = [
    {
      spanTitle: "Select companies that can access this file:",
      title: "Companies",
      items: companies,
      selected: selectedCompany,
      background: true,
      checkbox: true,
      selectItem: selectItems,
      selectAllItems: selectAllItems,
      selectedItems: selectedItems,
    },
  ];

  const form = {
    fields: fields,
    textarea: {
      id: "releaseNotes",
      name: "releaseNotes",
      placeholder: "Release Notes",
    },
    checkbox: null,
    submit: subPage === "Create" ? uploadFileS3 : submitEditFile,
    submitButtonValue: subPage === "Create" ? "Upload File" : "Save Edit",
    loading: loading,
  };

  const handlePageChange = (e, pageNumber) => {
    if (page === pageNumber) return;
    setPage(pageNumber);
  };

  const tablePagination = {
    page: page,
    pageSize: pageSize,
    totalPages: totalPages,
    data: products,
    fetchState: fetchState,
    setPage: setPage,
    setPageSize: setPageSize,
    setTotalPages: setTotalPages,
    handlePageChange: handlePageChange,
  };

  const table = {
    cols: [
      { name: "Id", key: "id" },
      { name: "Name", key: "path" },
      // { name: "Path", key: "path" },
      { name: "Release Notes", key: "releaseNotes" },
      { name: "Version", key: "version" },
      { name: "Platform", key: "platform" },
      { name: "Size", key: "sizeKB" },
      { name: "Product Name", key: "productName" },
      { name: "Created At", key: "createdAt" },
      { name: "Updated At", key: "updatedAt" },
      {
        name: "Edit File",
        key: "Edit File",
        render: (item) => (
          <div
            className="max-h-40 max-w-64 flex items-center gap-x-2 hover:underline text-blue-600 cursor-pointer truncate"
            onClick={() => editFile(item)}>
            <span>Edit File</span> <EditIcon />{" "}
          </div>
        ),
      },
    ],
    functions: functions,
    fetchData: fetchData,
    filters: filters,
    pagination: tablePagination,
  };

  const addOrRemoveFilter = (key, value, itemId) => {
    if (
      filters.some(
        (filter) =>
          filter.key === key &&
          filter.value === value &&
          filter.itemId === itemId
      )
    ) {
      setFilters(
        filters.filter(
          (filter) =>
            !(
              filter.key === key &&
              filter.value === value &&
              filter.itemId === itemId
            )
        )
      );
    } else {
      setFilters([...filters, { key: key, value: value, itemId: itemId }]);
    }
  };

  if (form && selectedFile) {
    form.fields.forEach((item) => {
      Object.keys(selectedFile).forEach((select) => {
        if (item["id"].includes(select)) {
          item["defaultValue"] = selectedFile[select];
        }
      });
    });

    if (form && form.textarea) {
      Object.keys(selectedFile).forEach((select) => {
        if (form.textarea["id"].includes(select)) {
          form.textarea["defaultValue"] = selectedFile[select];
        }
      });
    }
  }

  const closeButtonOnClick = () => {
    setSelectedFile(null);
    setSubPage("See all");
  };

  const modal = {
    title: "Create File",
    open: subPage === "Create" || subPage === "Edit",
    onClose: closeButtonOnClick,
  };

  return (
    <div className="w-full flex flex-col py-6 px-8 items-center bg-[#f8f8ff] font-semibold gap-6 relative">

      <PageTable
        title="All files"
        table={table}
        pageHeader={pageHeader}
        adminPage={true}
        fields={files}
        addOrRemoveFilter={addOrRemoveFilter}
        filters={filters}
        setFilters={setFilters}
        sorted={sorted}
        setSorted={setSorted}
        subPage={subPage}
        setSubPage={setSubPage}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        modal={modal}
      />
      <ModalPage open={modal.open} onClose={modal.onClose}>
        <CardPageHeader
          closeButton={true}
          closeButtonOnClick={modal.onClose}>
          <h1 className="font-semibold text-slate-700 text-2xl">
            {subPage === "Create" ? "Upload File" : "Edit File"}
          </h1>
          {subPage === "Edit" && selectedFile && (
            <h1 className="font-semibold text-slate-700 text-2xl">
              "{selectedFile && selectedFile.name}"
            </h1>
          )}


        </CardPageHeader>

        <CardPageBody>
          {(subPage === "Create" ? dropdownMenus : dropdownMenusEdit).map(
            (item, index) => (
              <CardLabelItem key={index} text={item.spanTitle}>
                <DropdownMenu
                  selected={item.selected}
                  title={item.title}
                  items={item.items}
                  setSelected={item.setSelected}
                  selectItem={item.selectItem}
                  background={item.background}
                  checkbox={item.checkbox}
                  selectAllItems={item.selectAllItems}
                  selectedItems={item.selectedItems}
                  size={"w-[150px]"}
                />
              </CardLabelItem>
            )
          )}

          <CardLabelItem text="Give access to previous version owners">
            <Checkbox
              id={checkbox.id}
              className="text-slate-600 bg-white"
              checked={checkbox.checked}
              onChange={(e) => checkbox.onChange(e.target.checked)}
            />
          </CardLabelItem>

          <UploadFile
            title={uploadFile.title}
            selectedFile={uploadFile.selectedFile}
            dismissFile={uploadFile.dismissFile}
            selectFileFromComputer={uploadFile.selectFileFromComputer}
          />

          <Form
            fields={form.fields}
            textarea={form.textarea}
            checkbox={form.checkbox}
            onSubmit={form.submit}
            submitButtonValue={form.submitButtonValue}
            loading={form.loading}
            type={form.type}
            signUp={form.signUp}
          />

          {percentage && <FloatingProgressBar
            title={"Uploading"}
            percentage={percentage}
          />}
        </CardPageBody>
      </ModalPage>

    </div>
  );

}
