import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MoreOptionsDropdown from "../dropdown/MoreOptionsDropdown";
import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import LogoPNG from "../../assets/logo_pi.png";

export default function ListItem(props) {
  const givenDate = new Date(props.item.updatedAt);
  const currentDate = new Date();

  const timeDifference = currentDate - givenDate;
  const timeDifferenceInDays = Math.floor(
    timeDifference / (1000 * 60 * 60 * 24)
  );

  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (props.item.cardImage && props.item.cardImage.data) {
      try {
        const blob = new Blob([new Uint8Array(props.item.cardImage.data)], {
          type: "image/png",
        });
        const url = URL.createObjectURL(blob);
        setImgSrc(url);
      } catch (error) {}
    }
  }, [props.item.cardImage]);

  return (
    <div
      className={`h-auto w-full pb-5 pt-6 px-5 flex items-center justify-between border-b-2 ${
        props.index === 0 ? "border-t-2" : ""
      } ${
        props.selectedItems && props.selectedItems.includes(props.item.id)
          ? "bg-mischka-200"
          : "hover:ring-2 hover:ring-mischka-300"
      } border-slate-200 group space-x-5  cursor-pointer`}
      onClick={(e) => props.onItemClick(props.item)}>
      {props.fields &&
        props.fields.map((field, index) => (
          <div key={index} className={`${field.css} items-center relative`}>
            {timeDifferenceInDays < 1 &&
              !props.item.wasFileDownloadedByTheUser &&
              field.name === "name" && (
                <span className="absolute flex h-3 w-3 left-[-11px]">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-300 "></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-400"></span>
                </span>
              )}

            <div className="flex items-center justify-start truncate space-x-2">
              {field.name === "name" && (
                <>
                  {props.type !== "product" && (
                    <Checkbox
                      className="group-hover:brightness-75 transition ease-in-out duration-150"
                      checked={
                        props.selectedItems &&
                        props.selectedItems.includes(props.item.id)
                      }
                    />
                  )}
                  <InsertDriveFileIcon
                    className={`group-hover:brightness-75 transition ease-in-out duration-150 `}
                    style={{
                      color:
                        props.item.latestVersion || props.type === "product"
                          ? props.productColor
                          : "#656970",
                    }}
                  />
                </>
              )}

              {props.type === "product" && field.name === "name" && (

                props.item.cardImage ?
                <div className="flex h-12 w-12 items-center justify-center px-2">
                  <img
                    src={imgSrc}
                    className="max-h-12 min-w-12"
                    alt="productImg"
                  />
                </div>
                :
                <div className="flex h-12 w-12 items-center justify-center px-2">
                  <img
                    src={LogoPNG}
                    className="max-h-12 min-w-12"
                    alt="productImg"
                  />
                </div>
              )}
              <span
                className="truncate text-lg font-medium max-w-[200px]"
                title={props.item[field.name] || ""}>
                {props.item[field.name]}
              </span>
              {timeDifferenceInDays < 1 &&
                !props.item.wasFileDownloadedByTheUser &&
                field.name === "name" && (
                  <div className="flex bg-sky-500 bg-opacity-55  rounded-lg items-center justify-center">
                    <span className="px-2 text-white">new</span>
                  </div>
                )}
            </div>
          </div>
        ))}

      <div
        id="moreOptions"
        className="w-auto flex items-center z-10"
        onClick={(e) => e.stopPropagation()}>
        <MoreOptionsDropdown
          item={props.item}
          moreOptionsDropdown={props.moreOptionsDropdown}
          isAdmin={props.isAdmin}
        />
      </div>
    </div>
  );
}
