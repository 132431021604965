import { Checkbox } from "@mui/material";
import TableCell from "./TableCell";

export default function TableRows(props) {
  const item = props.item;
  const filter = props.filter;
  const cols = props.cols;

  return (
    <tr className={`odd:bg-white-lilac-50 odd:hover:bg-[#e4e4f5] even:bg-[#eaeaf7] even:hover:bg-[#dadaf4] font-normal cursor-pointer`}>
      <td className="w-10 px-1 py-2 border-r-2 border-t-2 border-slate-300">
        {item &&
          <Checkbox className="text-blue-600 hover:underline hover:text-blue-600 cursor-pointer transition ease-in-out duration-150" onChange={() => props.selectItem(item)} checked={props.selectedItems.includes(item)} />
        }
      </td>

      {item ?
        cols.map((col, index) => (
          <TableCell key={index} col={col} item={item} filter={filter} />
        ))

        :

        cols.map((col, index) => (
          <td key={index} className="h-[59.6px] px-6 py-4 border-r-2 text-left border-t-2 border-slate-300">
            <div className="h-6 flex items-center space-x-2">
            </div>
          </td>
        ))

      }

      {item &&
        props.functions && props.functions.map((func, index) => (
          <td key={index} className="px-6 py-4 border-r-2 text-left border-t-2 border-slate-300">
            <span onClick={() => func.func(item)} className="hover:underline text-blue-600 cursor-pointer font-medium">{func.name}</span>
          </td>
        ))
      }

    </tr>
  );
}