import { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import Pagination from "@mui/material/Pagination";
import { calcPageSizeInitialState } from "../../functions/helper";
import { CircularProgress } from "@mui/material";

export default function PageHandler(props) {
  const fetchData = props.fetchData;

  const DEFAULT_PAGE = 1;
  const DEFAULT_PAGE_SIZE = 5;

  const [currentPage, setCurrentPage] = useState(props.page || DEFAULT_PAGE);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [fetchState, setFetchState] = useState("Loading...");

  const [pageSize, setPageSize] = useState(calcPageSizeInitialState());

  const handlePageChange = (e, pageNumber) => {
    if (currentPage === pageNumber) return;
    setCurrentPage(pageNumber);
    setFetchState("Loading...");
  };

  useEffect(() => {
    fetchData(
      currentPage,
      pageSize,
      setData,
      setTotalPages,
      setFetchState,
      props.searchValue,
      props.sorted
    );
  }, [currentPage, pageSize, props.filters, props.searchValue, props.sorted]);

  if (fetchState === "Success") {
    return (
      <div className="w-full h-full flex flex-col">
        {data && data.length > 0 && props.component(data)}

        <div className="h-12 p-2 flex items-center justify-center fixed bottom-14 left-1/2 -translate-x-1/2 bg-white rounded-3xl ring-1 ring-slate-400 z-[90] shadow-lg opacity-70 hover:opacity-100 transition-[opacity] ease-in-out duration-200">
          <Pagination
            page={currentPage}
            count={totalPages}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            siblingCount={0}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full flex justify-center font-semibold text-xl gap-x-4 items-center text-slate-700">
        <span>{fetchState}</span>
        {fetchState === "Loading..." && <CircularProgress size={20} />}
      </div>
    );
  }
}
