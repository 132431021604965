import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { useState } from 'react';
import SupportModal from './SupportModal';

export default function SupportPopup() {
  const [popUp, setPopUp] = useState(false);
  return (
    <>
        <button className="size-20 flex flex-col items-center justify-center text-white focus:ring-4 focus:outline-none font-medium rounded-full fixed z-30 bottom-16 right-4 text-center bg-blue-600 hover:bg-blue-800 focus:ring-blue-900 transition ease-in-out duration-75 hover:scale-105" onClick={() => setPopUp(!popUp)}>
          <SupportAgentOutlinedIcon fontSize='large'/>
        </button>
      
      
        <SupportModal open={popUp} setPopUp={setPopUp} />
        </>)
}