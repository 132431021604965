import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTable from "../../components/tables/PageTable";
import globalRequest from "../../functions/backendRequests";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import { useAlerts } from "../../alerts/AlertContext";
import { useUser } from "../../users/UserContext";
import EditIcon from "@mui/icons-material/Edit";
import CardPageHeader from "../../components/card/cardPage/CardPageHeader";
import CardPageBody from "../../components/card/cardPage/CardPageBody";
import CardLabelItem from "../../components/card/cardPage/CardLabelItem";
import Form from "../../components/forms/Form";
import {
  calcPageSizeInitialState,
  formatSortedObjectForQuery,
} from "../../functions/helper";
import ModalPage from "../../components/modal/ModalPage";

export default function AdminUsers() {
  const { setUser } = useUser();
  const { addAlert, clearAlerts } = useAlerts();
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [email, setEmail] = useState(null);
  const [domains, setDomains] = useState(null);
  const [allowed, setAllowed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState([]);

  const [sorted, setSorted] = useState({
    enabled: false,
    ascending: true,
    field: "",
  });
  const [fetchState, setFetchState] = useState("Loading...");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  const [pageSize, setPageSize] = useState(calcPageSizeInitialState);

  const [subPage, setSubPage] = useState("See all");

  useEffect(() => {
    updateItems();
  }, [page, pageSize, filters, searchValue, sorted]);

  const updateItems = () => {
    fetchData(
      page,
      pageSize,
      setTotalPages,
      setFetchState,
      searchValue,
      sorted.enabled && formatSortedObjectForQuery(sorted)
    );
  };

  useEffect(() => {
    if (companies) return;

    globalRequest("companies/checkCompanies", "", "GET", {}, null, "", setUser)
      .then((res) => {
        setCompanies(res);
      })
      .catch((err) => console.warn(err));
  });

  useEffect(() => {
    if(selectedCompany !== null){
      setSelectedDomain(null);

      globalRequest(
        "users/companyInfo",
        `?companyName=${selectedCompany}`,
        "GET",
        {},
        null,
        "",
        setUser
      ).then((res) => {
        if (res) {
          console.log(res);
          setDomains(JSON.parse(res.domains) || null);
          setAllowed(res.allowAdmin);
        }
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (subPage !== "Edit") {
      setSelectedUser(null);
      setSelectedDomain(null);
      setSelectedCompany(null);
      setEmail(null);
    }
  }, [subPage]);

  const fixEmail = (email) => {
    if (email.includes("@")) {
      email = email.split("@")[0];
    }
    console.log("selectedDomain", selectedDomain);
    if(selectedDomain){
      console.log("passa aqui")
      setEmail(email + selectedDomain);
      console.log("email", email+selectedDomain);
    }
    setEmail(email);
  };

  function submitForm(e) {
    e.preventDefault();
    if (!selectedCompany) {
      addAlert({
        message: "Please select a company!",
        severity: "warning",
        timeout: 5,
      })
      return;
    }
    if(!selectedDomain){
      addAlert({
        message: "Please select a domain!",
        severity: "warning",
        timeout: 5,
      })
      return;
    }

    const formData = new FormData(e.target);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    if (domains && selectedDomain) {
      if (email.includes("@")) {
        addAlert({
          message:
            "Since you selected the domain, please remove the @ symbol from the email address",
          severity: "warning",
          timeout: 5,
        });
        return;
      }
      data["email"] = data["email"] + selectedDomain;
    }
    data["admin"] = e.target["admin"]?.checked || false;
    data["companyId"] = companies.find(
      (company) => company.name === selectedCompany
    ).id;

    setLoading(true);

    globalRequest(
      "auth/register",
      "",
      "POST",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify(data),
      "",
      setUser
    )
      .then((res) => {
        setLoading(false);

        if (res && res.message) {
          addAlert({ message: res.message, severity: "success", timeout: 5 });
          e.target.reset();
          setSelectedCompany(null);
          setEmail(null);
          updateItems();
        } else {
          res.json().then((res) => {
            addAlert({ message: res.message, severity: "error", timeout: 5 });
            return;
          });
          return;
        }
      })
      .catch((err) => console.warn(err));
    setSubPage("See all");
  }

  const EmailField = (defaultValue) => {
    return (
      <>
        {domains && (
          <div className="w-full h-full flex space-x-3 border-2 bg-white border-gray-200 rounded-lg px-3">
            <input
              type="text"
              name="email"
              value={email || ""}
              onChange={(e) => fixEmail(e.target.value)}
              className="w-[55%] outline-none border-r border-gray-200"
              placeholder="Email"
              defaultValue={defaultValue}
            />
            <div className="w-[45%]">
              <DropdownMenu
                items={domains}
                title={"@ Select Domain"}
                selected={selectedDomain}
                setSelected={setSelectedDomain}
                email={true}
                size={"w-full"}
              />
            </div>
          </div>
        )} 
      </>
    );
  };

  const fields = [
    {
      type: "name",
      id: "name",
      label: "Name",
      name: "name",
      placeholder: "Name",
      autocomplete: "off",
    },
    { type: "Custom", id: "email", label: "Email", content: EmailField },
  ];

  const formCheckbox = {
    name: "admin",
    id: "admin",
    text: "Admin",
    checked:
      subPage === "Edit" && selectedUser ? selectedUser.admin === 1 : false,
  };

  const form = {
    fields: fields,
    checkbox: allowed ? formCheckbox : null,
    submit: subPage === "Create" ? submitForm : submitEditForm,
    submitButtonValue: subPage === "Create" ? "Register User" : "Save Edit",
    loading: loading,
  };

  const companyName =
    companies &&
    companies.length > 0 &&
    companies?.map((company) => company.name);

  const dropdownMenus = [
    {
      spanTitle: "Select a Company:",
      title: "Company",
      items: companyName && companyName,
      setSelected: setSelectedCompany,
      selected: selectedCompany,
      background: true,
    },
  ];

  function UserActions(e, items, resetItems, handleClose) {
    handleClose();
    resetItems();
    navigate("/admin/actions", {
      state: items.map((item) => {
        return { key: "UserId", value: item.email, itemId: item.email };
      }),
    });
  }

  function DeleteUser(e, items, resetItems, handleClose) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const deleteConfirmation = formJson.deleteConfirmation;

    if (deleteConfirmation !== "delete") {
      addAlert({
        message: 'Please type "delete" to confirm action!',
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    globalRequest(
      "users/delete",
      "",
      "DELETE",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify({ ids: items.map((item) => item.id) }),
      "",
      setUser
    )
      .then((res) => {
        if (res && res.message) {
          addAlert({ message: res.message, severity: "success", timeout: 5 });
          updateItems();
          resetItems();
        } else {
          res
            .json()
            .then((res) =>
              addAlert({ message: res.message, severity: "error", timeout: 5 })
            );
        }
        handleClose();
        resetItems();
      })
      .catch((err) => console.warn(err));
  }

  function ResetPassword(e, items, resetItems, handleClose) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const resetConfirmation = formJson.resetConfirmation;

    if (resetConfirmation !== "reset") {
      addAlert({
        message: 'Please type "reset" to confirm action!',
        severity: "warning",
        timeout: 5,
      });
      return;
    }

    globalRequest(
      "users/resetpassword",
      "",
      "PUT",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify({ ids: items.map((item) => item.id) }),
      "",
      setUser
    )
      .then((res) => {
        if (res && res.message) {
          addAlert({ message: res.message, severity: "success", timeout: 5 });
          resetItems();
        } else {
          res
            .json()
            .then((res) =>
              addAlert({ message: res.message || res.error, severity: "error", timeout: 5 })
            );
        }
        handleClose();
      })
      .catch((err) => console.warn(err));
  }

  function submitEditForm(e) {
    e.preventDefault();
    if (!selectedCompany) {
      addAlert({
        message: "Please select a company!",
        severity: "warning",
        timeout: 5,
      })
      return;
    }
    if(!selectedDomain){
      addAlert({
        message: "Please select a domain!",
        severity: "warning",
        timeout: 5,
      })
      return;
    }
    const formData = new FormData(e.target);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    if (domains && selectedDomain) {
      if (email.includes("@")) {
        addAlert({
          message:
            "Since you selected the domain, please remove the @ symbol from the email address",
          severity: "warning",
          timeout: 5,
        });
        return;
      }
      data["email"] = data["email"] + selectedDomain;
    }
    data["admin"] = e.target["admin"]?.checked || false;
    data["companyId"] = companies.find(
      (company) => company.name === selectedCompany
    ).id;
    data["userId"] = selectedUser.id;

    setLoading(true);

    globalRequest(
      "users/update",
      "",
      "PUT",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify(data),
      "",
      setUser
    )
      .then((res) => {
        setLoading(false);

        if (res && res.message) {
          addAlert({ message: res.message, severity: "success", timeout: 5 });
          e.target.reset();
          setSelectedCompany(null);
          setEmail(null);
          updateItems();
        } else {
          res.json().then((res) => {
            addAlert({ message: res.message, severity: "error", timeout: 5 });
            return;
          });
          return;
        }
      })
      .catch((err) => console.warn(err));
    setSubPage("See all");
  }

  const editUser = (user) => {
    globalRequest(
      "users/checkUser",
      `?userId=${user.id}`,
      "GET",
      {},
      null,
      "",
      setUser
    ).then((res) => {
      setSelectedUser(res.user);
      setSelectedCompany(res.user["company.name"]);
      setSubPage("Edit");
    });
  };

  const formatFiltersForQuery = (filters) => {
    return filters.map((filter) => `${filter.key}=${filter.itemId}`).join("&");
  };

  const fetchData = (
    page,
    pageSize,
    setTotalPages,
    setFetchState,
    searchValue,
    sorted
  ) => {
    globalRequest(
      "users/adminTableUsers",
      `?page=${page}&pageSize=${pageSize}&filters=${formatFiltersForQuery(
        filters
      )}&search=${searchValue || ""}&sort=${sorted || ""}`,
      "GET",
      {},
      null,
      "",
      setUser
    )
      .then((res) => {
        if (res && res.message) { setFetchState('No Data Found'); return; };
        setUsers(res.data);
        setTotalPages(res.totalPages);
        setFetchState('Success');
      })
      .catch((err) => console.warn(err));
  };

  const addOrRemoveFilter = (key, value, itemId) => {
    if (
      filters.some(
        (filter) =>
          filter.key === key &&
          filter.value === value &&
          filter.itemId === itemId
      )
    ) {
      setFilters(
        filters.filter(
          (filter) =>
            !(
              filter.key === key &&
              filter.value === value &&
              filter.itemId === itemId
            )
        )
      );
    } else {
      setFilters([...filters, { key: key, value: value, itemId: itemId }]);
    }
  };

  const handlePageChange = (e, pageNumber) => {
    if (page === pageNumber) return;
    setPage(pageNumber);
  };

  const tablePagination = {
    page: page,
    pageSize: pageSize,
    totalPages: totalPages,
    data: users,
    fetchState: fetchState,
    setPage: setPage,
    setPageSize: setPageSize,
    setTotalPages: setTotalPages,
    handlePageChange: handlePageChange,
  };

  const table = {
    cols: [
      { name: "Id", key: "id" },
      { name: "Name", key: "name" },
      { name: "Email", key: "email" },
      { name: "Company Name", key: "company.name" },
      {
        name: "Edit User",
        key: "editUser",
        render: (item) => (
          <div
            className="max-h-40 max-w-64 flex items-center gap-x-2 hover:underline text-blue-600 cursor-pointer truncate"
            onClick={() => editUser(item)}>
            <span>Edit User</span> <EditIcon />{" "}
          </div>
        ),
      },
    ],
    rows: users && users,
    functions: [
      {
        name: "Reset Password",
        func: ResetPassword,
        dialogTitle: "Reset Password",
        dialogContentText:
          "Are you sure you want to reset the password for these users?",
        textField: {
          name: "resetConfirmation",
          label: 'Confirm action by typing "reset"',
        },
      },
      {
        name: "User Actions",
        func: UserActions,
        dialogTitle: "User Actions",
        dialogContentText: "See actions for these users",
        color: "success",
      },
      {
        name: "Delete User",
        func: DeleteUser,
        dialogTitle: "Delete User",
        dialogContentText: "Are you sure you want to delete these users?",
        textField: {
          name: "deleteConfirmation",
          label: 'Confirm action by typing "delete"',
        },
      },
    ],
    filters: filters,
    fetchData: fetchData,
    pagination: tablePagination,
  };

  const pageOptions = ["See all", "Create"];

  const pageHeader = {
    title: "Users",
    options: pageOptions,
    selectPageOption: setSubPage,
    subPage: subPage,
  };

  const closeButtonOnClick = () => {
    setSelectedUser(null);
    setSubPage("See all");
  };

  if (form && selectedUser) {
    form.fields.forEach((item) => {
      Object.keys(selectedUser).forEach((select) => {
        if (item["id"].includes(select)) {
          item["defaultValue"] = selectedUser[select];
        }
      });
    });

    if (form && form.textarea) {
      Object.keys(selectedUser).forEach((select) => {
        if (form.textarea["id"].includes(select)) {
          form.textarea["defaultValue"] = selectedUser[select];
        }
      });
    }
  }

  const modal = {
    title: "Create User",
    open: subPage === "Create" || subPage === "Edit",
    onClose: closeButtonOnClick,
  };

  return (
    <div className="w-full flex flex-col py-6 px-8 items-center bg-[#f8f8ff] font-semibold gap-6 relative">

      <PageTable
        title="All users"
        table={table}
        adminPage={true}
        pageHeader={pageHeader}
        fields={users}
        addOrRemoveFilter={addOrRemoveFilter}
        filters={filters}
        setFilters={setFilters}
        sorted={sorted}
        setSorted={setSorted}
        subPage={subPage}
        setSubPage={setSubPage}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        modal={modal}
      />

      {modal.open &&
        <ModalPage open={subPage === "Create" || subPage === "Edit"} onClose={modal.onClose}>
          <CardPageHeader closeButton={true} closeButtonOnClick={modal.onClose}>
            <h1 className="font-semibold text-slate-700 text-2xl">
              {subPage === "Create" ? "Register User" : "Edit User"}
            </h1>
            {subPage === "Edit" && selectedUser && (
              <h1 className="font-semibold text-slate-700 text-2xl">
                "{selectedUser && selectedUser.name}"
              </h1>
            )}
          </CardPageHeader>

          <CardPageBody>
            {dropdownMenus.map((item, index) => (
              <CardLabelItem key={index} text="Select a Company:">
                <DropdownMenu
                  selected={item.selected}
                  title={item.title}
                  items={item.items}
                  setSelected={item.setSelected}
                  background={item.background}
                  checkbox={item.checkbox}
                  selectAllItems={item.selectAllItems}
                  selectedItems={item.selectedItems}
                  size={"w-[150px]"}
                />
              </CardLabelItem>
            ))}

            {selectedCompany ?
              <Form
                fields={form.fields}
                textarea={form.textarea}
                checkbox={form.checkbox}
                onSubmit={form.submit}
                submitButtonValue={form.submitButtonValue}
                loading={form.loading}
                type={form.type}
                signUp={form.signUp}
              />
              :
              <div className="flex flex-1 justify-center items-center">
                <p className="text-2xl">Please select a company</p>
              </div>
            }
          </CardPageBody>
        </ModalPage>
      }
    </div>
  );

}
