import { ReactComponent as LoadingSvg } from "../../assets/loading-svgrepo-com.svg";

export default function ButtonSubmit(props) {
  return (
    <button type="submit" disabled={props.loading} onClick={props.onClick} className={`min-w-[160px] w-auto max-w-[300px] truncate h-12 ${props.loading && 'bg-blue-800 border-blue-800 text-slate-50'} border-black border-2 text-black px-4 py-3 flex justify-center items-center tracking-wide rounded-3xl font-medium transition ease-in-out duration-150 hover:bg-blue-800 hover:border-blue-800 hover:text-white cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed`}>

      {props.loading ?

        <div className="flex items-center gap-3">
          <LoadingSvg className="w-6 h-6 animate-spin" />
          Loading...
        </div>

        :
        props.value
      }
    </button>
  )
}