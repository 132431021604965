import CircularProgressWithLabel from "./CircularProgressWithLabel";

export default function FloatingProgressBar({ title, percentage }) {
  return (
    <div className="w-fit flex items-center justify-center gap-4 p-1 px-4 rounded-full ring-2 ring-black ring-opacity-15 bg-white-lilac-50  z-50">
      <span className="font-semibold">{title}</span>
      <CircularProgressWithLabel value={percentage}  />
    </div>
  );
}
