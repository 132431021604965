import { Box, Modal } from "@mui/material";
import logo from '../../../assets/logo_pi.png'
import CloseIcon from '@mui/icons-material/Close';
import DropdownMenu from "../../dropdown/DropdownMenu";
import ButtonSubmit from "../../buttons/ButtonSubmit";
import BoxSidebar from "../../box/BoxSidebar";

export default function ActionPopUp(props) {

  const { open } = props;
  const { selectedProducts, func } = props.parms;

  function ClosePopup() {
    props.setPopUp(false)
  }

  return (
    <Modal open={open} onClose={ClosePopup} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box className='absolute h-[70%] max-h-[660px] w-[50%] max-w-[950px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white-lilac-50 rounded-lg outline-none'>
        <div className="flex flex-col w-full h-full bg-white-lilac-50 z-10 divide-y divide-slate-200 overflow-auto rounded-lg">
          <div className="w-full h-[55px] pl-4 border-b border-black flex justify-between items-center">
            <span>{func.name}</span>
            <button className='h-[55px] w-16 hover:bg-red-500 hover:text-slate-100 transition duration-200 ease-in-out' onClick={ClosePopup}>
              <CloseIcon />
            </button>
          </div>
          <div className="w-full flex flex-1 p-3 flex-col justify-center items-center space-y-10">
            <div className="w-full flex flex-col items-start space-y-6 px-14">
              <span className="text-xl font-semibold text-slate-600">Select companies you want to add the following products:</span>

              <BoxSidebar items={selectedProducts} />

            </div>
            <div>
              {props.dropdownMenus && props.dropdownMenus.map((item, index) => (
                <div key={index} className="space-x-5">
                  <span className="text-xl font-semibold text-slate-600">{func.name}</span>
                  <DropdownMenu
                    selected={item.selected}
                    title={item.title}
                    items={item.items}
                    setSelected={item.setSelected}
                    background={item.background}
                    checkbox={item.checkbox}
                    selectItem={item.selectItem}
                    selectAllItems={item.selectAllItems}
                    selectedItems={item.selectedItems}
                  />
                </div>
              )
              )
              }

            </div>


            <ButtonSubmit value="Add products to companies" loading={func.loading} onClick={() => func.func(selectedProducts, props.dropdownMenus[0].selectedItems)} />

          </div>
        </div>
      </Box>
    </Modal>
  );
}