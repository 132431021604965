import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';



export default function CompanyDropdown(props) {
  const [domain, setDomain] = useState('');

  useEffect(() => {
    if (domain !== null && domain && !domain.startsWith("@")) {
      setDomain("@" + domain.replace(/^@/, ""))
    }
  }, [domain])

  const addNewDomain = () => {
    props.addNew((prevItems) => {
      if (!prevItems.includes(domain)) {
        return [...prevItems, domain];
      }
      return prevItems;
    });
    setDomain('');
  };

  return (
    <Menu as={"div"} className="h-full max-w-[250px] w-full min-w-[150px] text-left relative z-10">
      <div className="inline-flex items-center h-full w-full min-w-10 rounded-lg bg-white text-sm font-semibold text-slate-600 shadow-sm ring-2 p-1 ring-inset ring-gray-200 group data-[active]:ring-gray-400 transition ease-in-out duration-150">
        <input type="text" value={domain} placeholder='Add Domain' className='w-full h-full text-center border-r border-gray-200 outline-none' onChange={(e) => setDomain(e.target.value.trim())} />
        {domain ?
          <button className='flex items-center justify-center h-full w-8 py-2 text-sm font-semibold text-slate-600'>
            <AddIcon className="h-full w-5 text-blue-500 self-end group-data-[active]:rotate-180 group-data-[active]:text-blue-700 group-hover:text-blue-700"
              onClick={() => addNewDomain()} />
          </button>
          :
          <MenuButton className="flex items-center justify-center h-full w-8 py-2 text-sm font-semibold text-slate-600">
            <ArrowDropDownIcon aria-hidden="true" className="h-full w-5 text-gray-400 self-end group-data-[active]:rotate-180 group-data-[active]:text-blue-700 group-hover:text-blue-700" />
          </MenuButton>}
      </div>
      <MenuItems transition className="absolute h-full w-full rounded-lg mt-1">
        <div className='w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none'>
          {props.items && Array.isArray(props.items) && props.items.length > 0 ?
            props.items.map((item) => (
              <MenuItem key={item.id} className="w-full h-10 flex items-center justify-between text-sm text-gray-700 border-b border-gray-200">
                <div className="w-full flex items-center">
                  <div className="w-full h-full flex items-center justify-start text-[16px] text-slate-600">
                    <div className="h-full w-full px-3 flex items-center truncate border-r border-gray-200">
                      <span className='truncate'>{item}</span>
                    </div>
                    <button className='cursor-pointer w-[50px] h-full hover:bg-gray-100'>
                      <CancelOutlinedIcon className="h-5 w-5 transition ease-in-out  text-red-500" onClick={() => props.remove(item)} />
                    </button>
                  </div>

                </div>
              </MenuItem>
            ))
            :
            <MenuItem as='div' className="w-full px-12 h-10 flex items-center justify-between mt-2 text-sm text-gray-700">Has no Domains</MenuItem>
          }
        </div>
      </MenuItems >
    </Menu>
  );


}